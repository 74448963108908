import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueProgressBar from "@aacassandra/vue3-progressbar";
import Vue3SmoothScroll from "vue3-smooth-scroll";

import "./assets/css/style.scss";

axios.defaults.baseURL = 'https://api.directual.com/good/api/v5/data';

window.$ = window.jQuery = require("jquery");
createApp(App)
  .use(store)
  .use(VueAxios, axios)
  .use(Vue3SmoothScroll)
  .use(router)
  .use(VueProgressBar, {
    color: "#094DF1",
    failedColor: "#874b4b",
    thickness: "5px",
    transition: {
      speed: "0.2s",
      opacity: "0.6s",
      termination: 300,
    },
    autoRevert: true,
    location: "top",
    inverse: false,
  })
  .mount('#app')
