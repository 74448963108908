import {createRouter, createWebHistory} from 'vue-router'
import mainPage from '@/views/mainPage';
import loginPage from "@/views/loginPage";
import applicationsList from "@/views/applicationsList";
import applicationEdit from "@/views/applicationEdit";
import applicationCreate from "@/views/applicationCreate";
import admApplicationsList from "@/views/admApplicationsList";
import admApplicationView from "@/views/admApplicationView";
import policyView from "@/views/policyView";
import notFound from "@/views/notFound";
import admLogin from "@/views/admLogin";
import userProfile from "@/views/userProfile";


const routes = [
  {
    path: '/',
    name: 'home',
    component: mainPage,
    meta: {
      title: 'Главная',
      hideNav: false,
      hideFooter: false,
      needAuth: false,
      needAdm: false,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: userProfile,
    meta: {
      title: 'Профиль',
      hideNav: false,
      hideFooter: true,
      needAuth: true,
      needAdm: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: loginPage,
    meta: {
      title: 'Вход',
      hideNav: true,
      hideFooter: true,
      needAuth: false,
      needAdm: false,
    },
  },
  {
    path: '/logout',
  },
  {
    path: '/login/ok',
    name: 'login-ok',
    component: loginPage,
    meta: {
      title: 'Успешный вход',
      hideNav: true,
      hideFooter: true,
      needAuth: false,
      needAdm: false,
    },
  },
  {
    path: '/applications',
    name: 'applications',
    component: applicationsList,
    meta: {
      title: 'Заявки',
      hideNav: false,
      hideFooter: true,
      needAuth: false,
      needAdm: false,
    },
  },
  {
    path: '/applications/create',
    name: 'application-create',
    component: applicationCreate,
    meta: {
      title: 'Подать заявку',
      hideNav: false,
      hideFooter: true,
      needAuth: true,
      needAdm: false,
    },
  },
  {
    path: '/applications/:id',
    name: 'applications-edit',
    component: applicationEdit,
    meta: {
      title: 'Просмотр заявки',
      hideNav: false,
      hideFooter: true,
      needAuth: true,
      needAdm: false,
    },
  },
  {
    path: '/admin/login',
    name: 'admLogin',
    component: admLogin,
    meta: {
      title: 'Вход',
      hideNav: true,
      hideFooter: true,
      needAuth: false,
      needAdm: false,
    },
  },
  {
    path: '/loginBasic',
    name: 'loginBasic',
    component: admLogin,
    meta: {
      title: 'Вход',
      hideNav: true,
      hideFooter: true,
      needAuth: false,
      needAdm: false,
    },
  },
  {
    path: '/admin/applications',
    name: 'admApplicationsList',
    component: admApplicationsList,
    meta: {
      title: 'Все заявки',
      hideNav: false,
      hideFooter: true,
      needAuth: true,
      needAdm: true,
    },
  },
  {
    path: '/admin/applications/:id',
    name: 'admin-applications-view',
    component: admApplicationView,
    meta: {
      title: 'Просмотр заявки',
      hideNav: false,
      hideFooter: true,
      needAuth: true,
      needAdm: true,
    },
  },
  {
    path: '/policy',
    name: 'policy',
    component: policyView,
    meta: {
      title: 'Политика конфиденциальности',
      hideNav: true,
      hideFooter: true,
      needAuth: false,
      mainClass: 'main-white',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: notFound,
    meta: {
      title: '404',
      hideNav: true,
      hideFooter: true,
      needAuth: false,
      needAdm: false,
    },
  },
  /*  {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/!* webpackChunkName: "about" *!/ '../views/AboutView.vue')
    }*/
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

import $store from "@/store/index"

router.beforeEach(async (to) => {
  document.title = to.meta.title;
  if (to.path === '/logout' || to.path === '/logout/'){
    $store.commit('logout');
    return {name: 'home'}
  }
  if (to.path === '/'){
    document.body.classList.add('main');
  } else {
    document.body.classList.remove('main');
  }
  if (to.meta.needAuth && !$store.getters.isAuthorized){
    return {name: 'login'}
  }
})

router.afterEach(async (to) => {
  window.ym(90485297, 'hit', to.path, {});
});

export default router
