export const therapeuticArea = [
  'Акушерство и гинекология',
  'Аллергология и иммунология',
  'Анестезиология-реаниматология',
  'Бактериология',
  'Вирусология',
  'Гастроэнтерология',
  'Гематология',
  'Генетика',
  'Гериатрия',
  'Дерматовенерология',
  'Детская кардиология',
  'Детская онкология',
  'Детская урология-андрология',
  'Детская хирургия',
  'Детская эндокринология',
  'Диетология',
  'Инфекционные болезни',
  'Кардиология',
  'Клиническая лабораторная диагностика',
  'Клиническая фармакология',
  'Колопроктология',
  'Косметология',
  'Лабораторная генетика',
  'Лечебная физкультура и спортивная медицина',
  'Мануальная терапия',
  'Неврология',
  'Нейрохирургия',
  'Неонатология',
  'Нефрология',
  'Общая врачебная практика (семейная медицина)',
  'Онкология',
  'Ортодонтия',
  'Остеопатия',
  'Оториноларингология',
  'Офтальмология',
  'Паразитология',
  'Педиатрия',
  'Пластическая хирургия',
  'Профпатология',
  'Психиатрия',
  'Психиатрия-наркология',
  'Пульмонология',
  'Радиология',
  'Радиотерапия',
  'Ревматология',
  'Рентгенология',
  'Рентгенэндоваскулярные диагностика и лечение',
  'Рефлексотерапия',
  'Санитарно-гигиенические лабораторные исследования',
  'Сексология',
  'Сердечно-сосудистая хирургия',
  'Стоматология детская',
  'Стоматология общей практики',
  'Стоматология ортопедическая',
  'Стоматология терапевтическая',
  'Стоматология хирургическая',
  'Сурдология-оториноларингология',
  'Терапия',
  'Токсикология',
  'Торакальная хирургия',
  'Травматология и ортопедия',
  'Трансфузиология',
  'Ультразвуковая диагностика',
  'Урология',
  'Фармацевтическая технология',
  'Фармацевтическая химия и фармакогнозия',
  'Физиотерапия',
  'Фтизиатрия',
  'Функциональная диагностика',
  'Хирургия',
  'Челюстно-лицевая хирургия',
  'Эндокринология',
  'Эндоскопия',
  'Эпидемиология',
  'Лечебное дело',
  'Медико-профилактическое дело',
  'Медицинская биохимия',
  'Медицинская биофизика',
  'Медицинская кибернетика',
  'Фармация',
  'Детская онкология-гематология',
  'Медицинская микробиология',
  'Физическая и реабилитационная медицина',
  'Другое',
]

export const researchPhase = [
  "I",
  "I-II",
  "I-II, III",
  "Ia",
  "Ib",
  "II",
  "II-III",
  "IIa",
  "IIb",
  "III",
  "III-IV",
  "IIIa",
  "IIIb",
  "IV",
  "Биоэквивалентность"
];

export const financialSupportMeasures = [
  'Расходы связанные с адаптацией городской медицинской организации для проведения клинического исследования.',
  'Затраты на расходные материалы, приобретение оборудования и (или) комплектующих к нему, приобретение или аренда вспомогательного оборудования, необходимых при проведении клинического исследования.',
  'Расходы на печать и доставку полиграфической продукции, необходимой при проведении клинического исследования.',
  'Расходы на выполнение городскими лабораториями клинических лабораторных исследований в рамках проведения скрининга и (или) в процессе проведения клинического исследования в отношении здоровых добровольцев/пациентов (согласно перечню исследований, выполняемых лабораториями)',
  'Расходы на транспортировку биоматериала в городские лаборатории.',
  'Расходы на выполнение инструментальных исследований пациентов в период проведения их скрининга или в процессе наблюдения за добровольцами (с учетом возможностей городских медицинских организаций).',
  'Расходы на транспортировку лекарственных препаратов для проведения клинического исследования в городские медицинские организации',
  'Расходы на фонд оплаты труда членов исследовательских команд городских медицинских организаций (за исключение выплат главным исследователям).',
  'Расходы на оплату услуг этических комитетов по организации экспертизы и оформлению заключений документов клинического исследования.',
]

export const organizationalSupportMeasures = [
  'Помощь в подборе городских медицинских организаций для проведения клинического исследования.',
  'Помощь в выборе исследователей.',
  'Предварительная оценка выполнимости клинического исследования.',
  'Содействие в наборе здоровых добровольцев/пациентов для клинического исследования в городских медицинских организациях.',
  'Организация оснащения городских медицинских организаций необходимым для проведения клинического исследования оборудованием (в том числе вспомогательным), расходными материалами.',
  'Взаимодействие с локальными этическими комитетами и Московским городским независимым этическим комитетом в части проведения клинических исследований в городских медицинских организациях.',
  'Оказание юридической помощи при заключении договоров (соглашений) в рамках клинического исследования.',
  'Мониторинг готовности городских медицинских организаций для проведения клинического исследования.',
  'Организация проведения тренингов исследовательских команд.',
  'Содействие в сопровождении клинического исследования (помощь в подготовке плана-графика клинического исследования и помощь в обеспечении его соблюдения).',
  'Помощь в подборе лаборатории для выполнения клинических лабораторных исследований в медицинских организациях, подведомственных Департаменту здравоохранения города Москвы (далее – городские лаборатории).',
  'Содействие в проведении клинического исследования с использованием функциональных возможностей информационных систем города Москвы.',
  'Организация хранения документов клинического исследования в отношении городских медицинских организаций.',
];

export const medOrgs = ['ГКБ № 1 им. Н.И.Пирогова',
  'ГБУЗ "ГКБ № 4 ДЗМ"',
  'ГБУЗ "ГКБ № 13 ДЗМ"',
  'ГБУЗ "ГКБ № 15 ДЗМ"',
  'ГБУЗ "ГКБ № 17 ДЗМ"',
  'ГБУЗ "ГКБ № 24 ДЗМ"',
  'ГБУЗ "ГКБ № 29 им. Н.Э. Баумана"',
  'ГБУЗ "ГКБ № 31 ДЗМ"',
  'ГБУЗ "ММКЦ "Коммунарка" ДЗМ"',
  'ГБУЗ "ГКБ № 51 ДЗМ"',
  'ГБУЗ "ГКБ № 52 ДЗМ"',
  'ГКБ № 67',
  'ГБУЗ "ГКБ им. братьев Бахрушиных ДЗМ"',
  'ГБУЗ ГКБ им. С.П. Боткина ДЗМ',
  'ГБУЗ "ГКБ имени В.М. Буянова ДЗМ"',
  'ГБУЗ "ГКБ им. В.В. Вересаева ДЗМ"',
  'ГБУЗ "ГКБ им. В.В. Виноградова ДЗМ"',
  'ГБУЗ "Вороновская больница ДЗМ"',
  'ГБУЗ "ГКБ им. И.В. Давыдовского ДЗМ"',
  'ГБУЗ "ГКБ имени В.П. Демихова ДЗМ"',
  'ГБУЗ "ГКБ им. А.К. Ерамишанцева ДЗМ"',
  'ГБУЗ "ГКБ им. М.Е. Жадкевича ДЗМ"',
  'ГБУЗ "ГКБ им. Ф.И. Иноземцева ДЗМ"',
  'ГБУЗ "ГКБ им. М.П. Кончаловского ДЗМ"',
  'ГБУЗ "Больница "Кузнечики" ДЗМ"',
  'ГБУЗ "ГКБ им. Е.О. Мухина ДЗМ"',
  'ГБУЗ "ГКБ им. Д.Д. Плетнёва ДЗМ"',
  'ГБУЗ НИКИО им. Л.И. Свержевского ДЗМ',
  'ГБУЗ "ГКБ им. С.И. Спасокукоцкого ДЗМ"',
  'ГБУЗ "ГКБ им. С.С. Юдина ДЗМ"',
  'НИИ НДХиТ',
  'ГБУЗ "НИИ СП им. Н.В. Склифосовского ДЗМ"',
  'ГБУЗ "ТГБ ДЗМ"',
  'ГБУЗ "ЩГБ ДЗМ"',
  'ГБУЗ "Эндокринологический диспансер ДЗМ"',
  'ГБУЗ "Центр паллиативной помощи ДЗМ"',
  'ГБУЗ "Городская больница г.Московский ДЗМ"',
  'ГБУЗ "ГВВ № 1 ДЗМ"',
  'ГБУЗ "ГВВ № 2 ДЗМ"',
  'ГБУЗ "ГВВ № 3 ДЗМ"',
  'ГБУЗ "ИКБ № 1 ДЗМ"',
  'ГБУЗ ИКБ № 2 ДЗМ',
  'ГБУЗ "ТБ им. А.Е.Рабухина ДЗМ"',
  'ГБУЗ "ТКБ № 3 ДЗМ"',
  'ГБУЗ "ДГКБ им.З.А. Башляевой ДЗМ"',
  'ГБУЗ "Морозовская ДГКБ ДЗМ"',
  'ГБУЗ "ДГКБ св. Владимира ДЗМ"',
  'ГБУЗ "ДГКБ им. Н.Ф. Филатова ДЗМ"',
  'ГБУЗ "ДИКБ № 6 ДЗМ"',
  'ГБУЗ "ДГКБ № 9 им. Г.Н. Сперанского ДЗМ"',
  'ГБУЗ "ГКОБ № 1 ДЗМ"',
  'ГБУЗ "МГОБ № 62 ДЗМ"',
  'ГБУЗ "ПКБ № 1 ДЗМ"',
  'ГБУЗ "ПКБ № 4 ДЗМ"',
  'ГБУЗ "ПКБ № 5 ДЗМ"',
  'ГБУЗ "ПКБ № 13 ДЗМ"',
  'ГБУЗ "ПНД № 22 ДЗМ"',
  'ГАУЗ МНПЦ МРВСМ ДЗМ',
  'ГАУЗ "ЦВРТ ДЗМ"',
  'ГБУЗ "МНПЦ борьбы с туберкулезом ДЗМ"',
  'ГБУЗ "МГЦРБ ДЗМ"',
  'ГБУЗ МКНЦ имени А.С. Логинова ДЗМ',
  'ГБУЗ "Московский Центр дерматовенерологии и косметологии"',
  'ГБУЗ "МНПЦ наркологии ДЗМ"',
  'ГБУЗ НПЦ им. Соловьева ДЗМ',
  'ГБУЗ "НПЦ ДП ДЗМ"',
  'ГБУЗ "НПЦ ПЗДП им. Г.Е. Сухаревой ДЗМ"',
  'ГБУЗ "НПЦ спец.мед.помощи детям ДЗМ"',
  'ГБУЗ "ЦМП ДЗМ"',
  'ГБУЗ ЦПСиР ДЗМ',
  'ГБУЗ "ЧЛГ для ВВ ДЗМ"',
  'ГБУЗ особого типа "МТНПЦМК (ЦЭМП) ДЗМ"',
  'ГБУЗ "ДКЦ № 1 ДЗМ"',
  'ГБУЗ "КДЦ № 2 ДЗМ"',
  'ГБУЗ "ДЦ № 3 ДЗМ"',
  'ГБУЗ "КДЦ № 4 ДЗМ"',
  'ГБУЗ "ДЦ № 5 ДЗМ"',
  'ГБУЗ "КДЦ № 6 ДЗМ"',
  'ГБУЗ "ДЦЛИ ДЗМ"',
  'ГБУЗ "КДП № 121 ДЗМ"',
  'ГБУЗ "ГП № 2 ДЗМ"',
  'ГБУЗ "ГП № 3 ДЗМ"',
  'ГБУЗ "ГП № 5 ДЗМ"',
  'ГБУЗ "ГП № 6 ДЗМ"',
  'ГБУЗ "ГП № 8 ДЗМ"',
  'ГБУЗ "ГП № 9 ДЗМ"',
  'ГБУЗ "ГП № 11 ДЗМ"',
  'ГБУЗ "ГП № 12 ДЗМ"',
  'ГБУЗ "ГП № 19 ДЗМ"',
  'ГБУЗ "ГП № 22 ДЗМ"',
  'ГБУЗ "ГП № 23 ДЗМ"',
  'ГБУЗ "ГП № 36 ДЗМ"',
  'ГБУЗ "ГП № 45 ДЗМ"',
  'ГБУЗ "ГП № 46 ДЗМ"',
  'ГБУЗ "ГП № 52 ДЗМ"',
  'ГБУЗ "ГП № 62 ДЗМ"',
  'ГБУЗ "ГП № 64 ДЗМ"',
  'ГБУЗ "ГП № 66 ДЗМ"',
  'ГБУЗ "ГП № 67 ДЗМ"',
  'ГБУЗ "ГП № 68 ДЗМ"',
  'ГБУЗ "ГП № 69 ДЗМ"',
  'ГБУЗ "ГП № 107 ДЗМ"',
  'ГБУЗ "ГП № 109 ДЗМ"',
  'ГБУЗ "ГП № 115 ДЗМ"',
  'ГБУЗ "ГП № 134 ДЗМ"',
  'ГБУЗ "ГП № 166 ДЗМ"',
  'ГБУЗ г. Москвы ГП № 170 ДЗМ',
  'ГБУЗ "ГП № 175 ДЗМ"',
  'ГБУ ГП № 180 ДЗМ',
  'ГБУЗ "ГП № 191 ДЗМ"',
  'ГБУЗ "ГП № 195 ДЗМ"',
  'ГБУЗ "ГП № 209 ДЗМ"',
  'ГБУЗ "ГП № 210 ДЗМ"',
  'ГБУЗ "ГП № 212 ДЗМ"',
  'ГБУЗ "ГП № 214 ДЗМ"',
  'ГБУЗ "ГП № 218 ДЗМ"',
  'ГБУЗ "ГП № 219 ДЗМ"',
  'ГБУЗ "ГП № 220 ДЗМ"',
  'ГБУЗ "ДГП № 7 ДЗМ"',
  'ГБУЗ "ДГП № 10 ДЗМ"',
  'ГБУЗ "ДГП № 11 ДЗМ"',
  'ГБУЗ "ДГП № 12 ДЗМ"',
  'ГБУЗ "ДГП № 15 ДЗМ"',
  'ГБУЗ "ДГП № 23 ДЗМ"',
  'ГБУЗ "ДГП № 28 ДЗМ"',
  'ГБУЗ "ДГП № 30 ДЗМ"',
  'ГБУЗ "ДГП № 32 ДЗМ"',
  'ГБУЗ "ДГП № 38 ДЗМ"',
  'ГБУЗ "ДГП № 39 ДЗМ"',
  'ГБУЗ "ДГП № 42 ДЗМ"',
  'ГБУЗ "ДГП № 48 ДЗМ"',
  'ГБУЗ "ДГП № 52 ДЗМ"',
  'ГБУЗ "ДГП № 58 ДЗМ"',
  'ГБУЗ "ДГП № 61 ДЗМ"',
  'ГБУЗ "ДГП № 69 ДЗМ"',
  'ГБУЗ "ДГП № 81 ДЗМ"',
  'ГБУЗ "ДГП № 86 ДЗМ"',
  'ГБУЗ "ДГП № 91 ДЗМ"',
  'ГБУЗ "ДГП № 94 ДЗМ"',
  'ГБУЗ "ДГП № 98 ДЗМ"',
  'ГБУЗ "ДГП № 99 ДЗМ"',
  'ГБУЗ "ДГП № 104 ДЗМ"',
  'ГБУЗ "ДГП № 105 ДЗМ"',
  'ГБУЗ "ДГП № 110 ДЗМ"',
  'ГБУЗ "ДГП № 118 ДЗМ"',
  'ГБУЗ "ДГП № 120 ДЗМ"',
  'ГБУЗ "ДГП № 122 ДЗМ"',
  'ГБУЗ "ДГП № 125 ДЗМ"',
  'ГБУЗ "ДГП № 129 ДЗМ"',
  'ГБУЗ "ДГП № 130 ДЗМ"',
  'ГБУЗ "ДГП № 131 ДЗМ"',
  'ГБУЗ "ДГП № 132 ДЗМ"',
  'ГБУЗ "ДГП № 133 ДЗМ"',
  'ГБУЗ "ДГП № 140 ДЗМ"',
  'ГБУЗ "ДГП № 143 ДЗМ"',
  'ГБУЗ "ДГП № 145 ДЗМ"',
  'ГБУЗ "ДГП № 148 ДЗМ"',
  'ГБУЗ "ДГП № 150 ДЗМ"',
  'ГАУЗ "СП № 2 ДЗМ"',
  'ГАУЗ "СП № 3 ДЗМ"',
  'ГАУЗ "СП № 4 ДЗМ"',
  'ГАУЗ "СП № 5 ДЗМ"',
  'ГАУЗ "СП № 7 ДЗМ"',
  'ГАУЗ "СП № 8 ДЗМ"',
  'ГАУЗ "СП № 9 ДЗМ"',
  'ГАУЗ "СП № 11 ДЗМ"',
  'ГАУЗ "СП № 12 ДЗМ"',
  'ГАУЗ "СП № 13 ДЗМ"',
  'ГАУЗ "СП № 14 ДЗМ"',
  'ГАУЗ "СП № 15 ДЗМ"',
  'ГАУЗ "СП № 19 ДЗМ"',
  'ГАУЗ "СП № 22 ДЗМ"',
  'ГАУЗ "СП № 23 ДЗМ"',
  'ГАУЗ "СП № 24 ДЗМ"',
  'ГАУЗ "СП № 27 ДЗМ"',
  'ГАУЗ "СП № 32 ДЗМ"',
  'ГАУЗ "СП № 33 ДЗМ"',
  'ГАУЗ "СП № 34 ДЗМ"',
  'ГАУЗ "СП № 35 ДЗМ"',
  'ГАУЗ "СП № 48 ДЗМ"',
  'ГАУЗ "СП № 49 ДЗМ"',
  'ГАУЗ "СП № 50 ДЗМ"',
  'ГАУЗ "СП № 51 ДЗМ"',
  'ГАУЗ "СП № 53 ДЗМ"',
  'ГАУЗ "СП № 56 ДЗМ"',
  'ГАУЗ "СП № 60 ДЗМ"',
  'ГАУЗ "СП № 61 ДЗМ"',
  'ГАУЗ "СП № 62 ДЗМ"',
  'ГАУЗ "СП № 64 ДЗМ"',
  'ГАУЗ "СП № 65 ДЗМ"',
  'ГАУЗ "СП № 66 ДЗМ"',
  'ГАУЗ "СП № 67 ДЗМ"',
  'ГБУЗ "ДСП № 1 ДЗМ"',
  'ГБУЗ "ДСП № 6 ДЗМ"',
  'ГБУЗ "ДСП № 10 ДЗМ"',
  'ГБУЗ "ДСП № 16 ДЗМ"',
  'ГБУЗ "ДСП № 21 ДЗМ"',
  'ГБУЗ "ДСП № 25 ДЗМ"',
  'ГБУЗ "ДСП № 26 ДЗМ"',
  'ГБУЗ "ДСП № 28 ДЗМ"',
  'ГБУЗ "ДСП № 29 ДЗМ"',
  'ГБУЗ "ДСП № 30 ДЗМ"',
  'ГБУЗ "ДСП № 37 ДЗМ"',
  'ГБУЗ "ДСП № 38 ДЗМ"',
  'ГБУЗ "ДСП № 39 ДЗМ"',
  'ГБУЗ "ДСП № 41 ДЗМ"',
  'ГБУЗ "ДСП № 43 ДЗМ"',
  'ГБУЗ "ДСП № 44 ДЗМ"',
  'ГБУЗ "ДСП № 45 ДЗМ"',
  'ГБУЗ "ДСП № 46 ДЗМ"',
  'ГБУЗ "ДСП № 47 ДЗМ"',
  'ГБУЗ "ДСП № 52 ДЗМ"',
  'ГБУЗ "ДСП № 54 ДЗМ"',
  'ГБУЗ "ДСП № 58 ДЗМ"',
  'ГБУЗ "ДСП № 59 ДЗМ"',
  'ГБУЗ "ДСП № 63 ДЗМ"',
  'ГБУЗ "НПКЦ ДиТ ДЗМЦ"',
];


