import { createStore } from 'vuex'
import user from "@/store/modules/user";
import filters from "@/store/modules/filters";
import applications from "@/store/modules/applications";
import axios from "axios";

const APP_ID      = '912e2e3a-e4e3-45a6-a23f-12a2f63a2989';

export default createStore({
  modules: {
    user,
    applications,
    filters
  },
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async init({commit, getters}){
      const sessionID = getters.getSessionId;
      const userId = getters.getUserId;
      if (!sessionID || !userId) return false;
      try {
        const URL_PARAMS  = new URLSearchParams({
          appID: APP_ID,
          sessionID,
          userId,
        });
        const searchParams = URL_PARAMS.toString();
        const response = await axios.get(`trialsInit/trialsInit?${searchParams}`);
        if (response?.data?.payload[0]) {
          const data = response?.data?.payload[0];
          const {user, userOrganisations} = data;
          commit('setUser', user);
          commit('setUserOrganisations', userOrganisations);
          return true;
        }
        return false;
      } catch (e) {
        console.log(e);
      }

    },

  },
})

