export default {
  state: {
    filterName: undefined,
    filterStatus: undefined,
    filterPhase: undefined,
    filterArea: undefined,
  },
  getters: {
    getFilterName(state) {
      return state.filterName;
    },
    getFilterStatus(state) {
      return state.filterStatus;
    },
    getFilterPhase(state) {
      return state.filterPhase;
    },
    getFilterArea(state) {
      return state.filterArea;
    },
  },
  mutations: {
    setFilterName(state, filterName) {
      state.filterName = filterName;
    },
    setFilterStatus(state, filterStatus) {
      state.filterStatus = filterStatus;
    },
    setFilterPhase(state, filterPhase) {
      state.filterStatus = filterPhase;
    },
    setFilterArea(state, filterArea) {
      state.filterStatus = filterArea;
    },
  },
};
