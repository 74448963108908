<template>
  <applications-list :applications="$store.getters.getApplications"/>
</template>
<script>
import ApplicationsList from "@/components/applicationsList";
export default {
  components: {ApplicationsList},
  created() {
    if (!this.$store.getters.isAdmin && !this.$store.getters.isViewAdmin) {
      this.$router.push('/applications');
      return;
    }
    this.$store.dispatch('fetchAllApplicationsForAdmin');
  },
  unmounted() {
    this.$store.commit('clearApplications');
  },
}
</script>