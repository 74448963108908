import axios from 'axios';
const APP_ID = '912e2e3a-e4e3-45a6-a23f-12a2f63a2989';

export default {
  state: {
    applications: [],
    currentApplication: false,
  },
  getters: {
    getApplications(state) {
      return state.applications;
    },
    getCurrentApplication(state) {
      return state.currentApplication;
    },
  },
  mutations: {
    setApplications(state, data) {
      state.applications = data || [];
    },
    setCurrentApplication(state, data) {
      state.currentApplication = data || false;
    },
    clearApplications(state) {
      state.applications = [];
    },
    clearCurrentApplication(state) {
      state.currentApplication = false;
    },
  },
  actions: {
    /* USER METHODS  */
    async createApplication({ state, getters }, params) {
      state;
      const formData = await prepareFormData(
        params.fieldsObject,
        getters.getSessionId
      );
      if (!formData) alert('Объект formData имеет неверный вид');
      try {
        const URL_PARAMS = new URLSearchParams({
          appID: APP_ID,
          sessionID: getters.getSessionId,
        });
        const searchParams = URL_PARAMS.toString();
        const options = { headers: { 'Content-Type': 'multipart/form-data' } };
        const response = await axios.post(
          `/trialsApplication/trialsApplicationCreate?${searchParams}`,
          formData,
          options
        );
        if (response && response.data) {
          return true;
        }
      } catch (e) {
        alert(e);
        console.log(e);
      }
    },
    async editApplication({ state, getters }, params) {
      state;
      const formData = await prepareFormData(
        params.fieldsObject,
        getters.getSessionId
      );
      if (!formData) alert('Объект formData имеет неверный вид');
      try {
        const URL_PARAMS = new URLSearchParams({
          appID: APP_ID,
          sessionID: getters.getSessionId,
        });
        const searchParams = URL_PARAMS.toString();
        const options = { headers: { 'Content-Type': 'multipart/form-data' } };
        const response = await axios.post(
          `/trialsApplication/trialsApplicationEdit?${searchParams}`,
          formData,
          options
        );
        if (response && response.data) {
          return true;
        }
      } catch (e) {
        alert(e);
        console.log(e);
      }
    },
    async fetchAllApplicationsForUser({ getters, commit }) {
      try {
        const URL_PARAMS = new URLSearchParams({
          appID: APP_ID,
          sessionID: getters.getSessionId,
          userId: getters.getUserId,
          pageSize: 300,
        });
        const searchParams = URL_PARAMS.toString();
        const response = await axios.get(
          `/trialsApplication/trialsApplicationGetAll?${searchParams}`
        );
        if (response && response.data && response.data.payload) {
          const data = response.data.payload;
          commit('setApplications', data);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async deleteApplication({ state, getters }, applicationId) {
      state;
      const formData = new FormData();
      formData.append('id', applicationId);
      formData.append('archive', true);
      formData.append('userId ', getters.getUserId);
      try {
        const URL_PARAMS = new URLSearchParams({
          appID: APP_ID,
          sessionID: getters.getSessionId,
        });
        const searchParams = URL_PARAMS.toString();
        const options = { headers: { 'Content-Type': 'multipart/form-data' } };
        const response = await axios.post(
          `/trialsApplication/trialsApplicationEdit?${searchParams}`,
          formData,
          options
        );
        if (response && response.data) {
          return true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async fetchAllApplicationById({ commit, getters }, applicationId) {
      try {
        const URL_PARAMS = new URLSearchParams({
          appID: APP_ID,
          sessionID: getters.getSessionId,
          applicationid: applicationId,
        });
        const searchParams = URL_PARAMS.toString();
        const response = await axios.get(
          `/trialsApplication/trialsGetApplicationById?${searchParams}`
        );
        if (response && response.data && response.data.payload) {
          const data = response.data.payload[0] || false;
          commit('setCurrentApplication', data);
        }
      } catch (e) {
        console.log(e);
      }
    },

    /* ADMIN METHODS  */
    async fetchAllApplicationsForAdmin({ commit, getters }) {
      try {
        const URL_PARAMS = new URLSearchParams({
          appID: APP_ID,
          sessionID: getters.getSessionId,
          pageSize: 300,
        });
        const searchParams = URL_PARAMS.toString();
        const response = await axios.get(
          `/trialsApplication/trialsApplicationAdminGetAll?${searchParams}`
        );
        if (response && response.data && response.data.payload) {
          const data = response.data.payload;
          commit('setApplications', data);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async approveApplication({ getters }, data) {
      const {
        id,
        comment,
        organizationalSupportMeasuresStatus,
        financialSupportMeasuresStatus,
      } = data;
      const formData = new FormData();
      formData.append('id', id);
      formData.append('status', 8);
      if (comment) formData.append('comment', comment);
      if (organizationalSupportMeasuresStatus)
        formData.append(
          'organizationalSupportMeasuresStatus',
          organizationalSupportMeasuresStatus
        );
      if (financialSupportMeasuresStatus)
        formData.append(
          'financialSupportMeasuresStatus',
          financialSupportMeasuresStatus
        );
      try {
        const URL_PARAMS = new URLSearchParams({
          appID: APP_ID,
          sessionID: getters.getSessionId,
        });
        const searchParams = URL_PARAMS.toString();
        const options = { headers: { 'Content-Type': 'multipart/form-data' } };
        const response = await axios.post(
          `/trialsApplication/trialsApplicationAdminApprove?${searchParams}`,
          formData,
          options
        );
        if (response && response.data) {
          return true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async reworkApplication({ getters }, data) {
      const {
        id,
        comment,
        newStatusId,
        organizationalSupportMeasuresStatus,
        financialSupportMeasuresStatus,
      } = data;
      const formData = new FormData();
      formData.append('id', id);
      formData.append('status', newStatusId);
      if (comment) formData.append('comment', comment);
      if (organizationalSupportMeasuresStatus)
        formData.append(
          'organizationalSupportMeasuresStatus',
          organizationalSupportMeasuresStatus
        );
      if (financialSupportMeasuresStatus)
        formData.append(
          'financialSupportMeasuresStatus',
          financialSupportMeasuresStatus
        );
      try {
        const URL_PARAMS = new URLSearchParams({
          appID: APP_ID,
          sessionID: getters.getSessionId,
        });
        const searchParams = URL_PARAMS.toString();
        const options = { headers: { 'Content-Type': 'multipart/form-data' } };
        const response = await axios.post(
          `/trialsApplication/trialsApplicationAdminRework?${searchParams}`,
          formData,
          options
        );
        if (response && response.data) {
          return true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async rejectApplication({ getters }, data) {
      const {
        id,
        comment,
        newStatusId,
        organizationalSupportMeasuresStatus,
        financialSupportMeasuresStatus,
      } = data;
      const formData = new FormData();
      formData.append('id', id);
      formData.append('status', newStatusId);
      if (comment) formData.append('comment', comment);
      if (organizationalSupportMeasuresStatus)
        formData.append(
          'organizationalSupportMeasuresStatus',
          organizationalSupportMeasuresStatus
        );
      if (financialSupportMeasuresStatus)
        formData.append(
          'financialSupportMeasuresStatus',
          financialSupportMeasuresStatus
        );
      try {
        const URL_PARAMS = new URLSearchParams({
          appID: APP_ID,
          sessionID: getters.getSessionId,
        });
        const searchParams = URL_PARAMS.toString();
        const options = { headers: { 'Content-Type': 'multipart/form-data' } }; //onUploadProgress: progressEvent => callBack((progressEvent.loaded / progressEvent.total) * 100)
        const response = await axios.post(
          `/trialsApplication/trialsApplicationAdminReject?${searchParams}`,
          formData,
          options
        );
        if (response && response.data) {
          return true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getApplicationsCSV({ getters }) {
      try {
        //getters;
        const URL_PARAMS = new URLSearchParams({
          appID: APP_ID,
          sessionID: getters.getSessionId,
        });

        const formData = new FormData();
        formData.append('action', 'createDocAplication');
        const searchParams = URL_PARAMS.toString();
        const options = { headers: { 'Content-Type': 'multipart/form-data' } }; //onUploadProgress: progressEvent => callBack((progressEvent.loaded / progressEvent.total) * 100)
        const response = await axios.post(
          `/_trialsApplicationAction/trailsDocApplication?${searchParams}`,
          formData,
          options
        );
        if (response?.data) {
          if (response?.data?.result[0]?.docLink) {
            return response?.data?.result[0]?.docLink;
          }
          return false;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};

// function checkProtoFileOrEmpty(value){
//   if (typeof value === 'object' ) {
//     if (Object.prototype.toString.call(value) === '[object File]') return true;
//   }
//   if (typeof value === 'string') {
//     if (value === '') return true;
//   }
//   return false;
// }

async function uploadFile(file, sessionID) {
  const URL_PARAMS = new URLSearchParams({
    appID: APP_ID,
    sessionID,
  });

  const searchParams = URL_PARAMS.toString();
  const options = { headers: { 'Content-Type': 'multipart/form-data' } };

  const data = new FormData();
  data.append('file', file);

  const response = await axios.post(
    `/inputFiles/uploadFile?${searchParams}`,
    data,
    options
  );

  if (response?.data) {
    return response.data?.result[0]?.file?.id;
  }
}

async function prepareFormData(fieldsObject, sessionID) {
  //debugger;
  if (!fieldsObject || Object.keys(fieldsObject).length === 0) return false;
  const formData = new FormData();
  const entries = Object.entries(fieldsObject);

  for await (const [key, value] of entries) {
    if (key.includes('file_')) {
      if (!value) {
        formData.append(key, '');
      } else if (Array.isArray(value)) {
        const ids = await Promise.all(
          value?.map(async (file) => {
            // If file already exists, return its id.
            if (file.id) {
              return file.id;
            }

            // Upload file if not 
            const id = await uploadFile(file, sessionID);
            return id;
          })
        );

        formData.append(`${key}`, ids.join(','));
      } else {
        formData.append(`${key}`, value.id);
      }


    } else if (Array.isArray(value)) {
      formData.append(`${key}`, value.join('#'));
    } else {
      formData.append(key, value);
    }
  }

  return formData;
}
