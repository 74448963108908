<template>
  <footer v-if="$route.path === '/'" class="footer" >
    <div class="wrapper">
      <div class="footer__wrap">
        <span>(с) {{(new Date().getFullYear())}}, московский центр инновационных технологий в здравоохранении</span>
        <a href="mailto: trials-support@zdrav.mos.ru">тех.поддержка: trials-support@zdrav.mos.ru</a>
        <a href="/policy" data-type="iframe" data-fancybox>политика конфиденциальности</a>
      </div>
    </div>
  </footer>
</template>

<script>
import "@fancyapps/ui/dist/fancybox.css";
import { Fancybox } from "@fancyapps/ui";
Fancybox;
export default {
  name: "FooterLayout"
}
</script>

<style lang="scss">
</style>