<template>
  <base-layout>
    <router-view/>
  </base-layout>
  <vue-progress-bar></vue-progress-bar>
</template>

<script>
import BaseLayout from "@/components/layouts/BaseLayout";
export default {
  components: {BaseLayout},
  async created() {
    await this.$store.commit('initAuthCache');
    if (!await this.$store.dispatch('init')) console.info('No auth');

    this.$Progress.start();
    this.$router.beforeEach(() => {
      this.$Progress.start();
    });
    this.$router.afterEach(() => {
      this.$Progress.finish();
    });
  },
  mounted() {
    this.$Progress.finish();
  },
}
</script>
<style lang="scss">
</style>
