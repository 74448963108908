<template>
  <div class="skeleton-box">
    <svg width="100%" viewBox="0 0 1340 754" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.25" y="-0.25" width="1339.5" height="0.5" transform="matrix(1 0 0 -1 0 28.5)" fill="#E6E6E6" stroke="#E6E6E6" stroke-width="0.5"/>
      <rect x="0.25" y="-0.25" width="1339.5" height="0.5" transform="matrix(1 0 0 -1 0 89.5)" fill="#E6E6E6" stroke="#E6E6E6" stroke-width="0.5"/>
      <rect x="0.25" y="-0.25" width="1339.5" height="0.5" transform="matrix(1 0 0 -1 0 221.5)" fill="#E6E6E6" stroke="#E6E6E6" stroke-width="0.5"/>
      <rect x="0.25" y="-0.25" width="1339.5" height="0.5" transform="matrix(1 0 0 -1 0 353.5)" fill="#E6E6E6" stroke="#E6E6E6" stroke-width="0.5"/>
      <rect x="0.25" y="-0.25" width="1339.5" height="0.5" transform="matrix(1 0 0 -1 0 485.5)" fill="#E6E6E6" stroke="#E6E6E6" stroke-width="0.5"/>
      <rect x="0.25" y="-0.25" width="1339.5" height="0.5" transform="matrix(1 0 0 -1 0 617.5)" fill="#E6E6E6" stroke="#E6E6E6" stroke-width="0.5"/>
      <rect x="0.25" y="-0.25" width="1339.5" height="0.5" transform="matrix(1 0 0 -1 0 155.5)" fill="#E6E6E6" stroke="#E6E6E6" stroke-width="0.5"/>
      <rect x="0.25" y="-0.25" width="1339.5" height="0.5" transform="matrix(1 0 0 -1 0 287.5)" fill="#E6E6E6" stroke="#E6E6E6" stroke-width="0.5"/>
      <rect x="0.25" y="-0.25" width="1339.5" height="0.5" transform="matrix(1 0 0 -1 0 419.5)" fill="#E6E6E6" stroke="#E6E6E6" stroke-width="0.5"/>
      <rect x="0.25" y="-0.25" width="1339.5" height="0.5" transform="matrix(1 0 0 -1 0 551.5)" fill="#E6E6E6" stroke="#E6E6E6" stroke-width="0.5"/>
      <rect x="0.25" y="-0.25" width="1339.5" height="0.5" transform="matrix(1 0 0 -1 0 683.5)" fill="#E6E6E6" stroke="#E6E6E6" stroke-width="0.5"/>
      <path d="M1321.5 59.5C1321.5 60.0523 1321.05 60.5 1320.5 60.5C1319.95 60.5 1319.5 60.0523 1319.5 59.5C1319.5 58.9477 1319.95 58.5 1320.5 58.5C1321.05 58.5 1321.5 58.9477 1321.5 59.5ZM1327.5 59.5C1327.5 60.0523 1327.05 60.5 1326.5 60.5C1325.95 60.5 1325.5 60.0523 1325.5 59.5C1325.5 58.9477 1325.95 58.5 1326.5 58.5C1327.05 58.5 1327.5 58.9477 1327.5 59.5ZM1333.5 59.5C1333.5 60.0523 1333.05 60.5 1332.5 60.5C1331.95 60.5 1331.5 60.0523 1331.5 59.5C1331.5 58.9477 1331.95 58.5 1332.5 58.5C1333.05 58.5 1333.5 58.9477 1333.5 59.5Z" fill="#E6E6E6" stroke="#E6E6E6"/>
      <path d="M1321.5 191.5C1321.5 192.052 1321.05 192.5 1320.5 192.5C1319.95 192.5 1319.5 192.052 1319.5 191.5C1319.5 190.948 1319.95 190.5 1320.5 190.5C1321.05 190.5 1321.5 190.948 1321.5 191.5ZM1327.5 191.5C1327.5 192.052 1327.05 192.5 1326.5 192.5C1325.95 192.5 1325.5 192.052 1325.5 191.5C1325.5 190.948 1325.95 190.5 1326.5 190.5C1327.05 190.5 1327.5 190.948 1327.5 191.5ZM1333.5 191.5C1333.5 192.052 1333.05 192.5 1332.5 192.5C1331.95 192.5 1331.5 192.052 1331.5 191.5C1331.5 190.948 1331.95 190.5 1332.5 190.5C1333.05 190.5 1333.5 190.948 1333.5 191.5Z" fill="#E6E6E6" stroke="#E6E6E6"/>
      <path d="M1321.5 323.5C1321.5 324.052 1321.05 324.5 1320.5 324.5C1319.95 324.5 1319.5 324.052 1319.5 323.5C1319.5 322.948 1319.95 322.5 1320.5 322.5C1321.05 322.5 1321.5 322.948 1321.5 323.5ZM1327.5 323.5C1327.5 324.052 1327.05 324.5 1326.5 324.5C1325.95 324.5 1325.5 324.052 1325.5 323.5C1325.5 322.948 1325.95 322.5 1326.5 322.5C1327.05 322.5 1327.5 322.948 1327.5 323.5ZM1333.5 323.5C1333.5 324.052 1333.05 324.5 1332.5 324.5C1331.95 324.5 1331.5 324.052 1331.5 323.5C1331.5 322.948 1331.95 322.5 1332.5 322.5C1333.05 322.5 1333.5 322.948 1333.5 323.5Z" fill="#E6E6E6" stroke="#E6E6E6"/>
      <path d="M1321.5 455.5C1321.5 456.052 1321.05 456.5 1320.5 456.5C1319.95 456.5 1319.5 456.052 1319.5 455.5C1319.5 454.948 1319.95 454.5 1320.5 454.5C1321.05 454.5 1321.5 454.948 1321.5 455.5ZM1327.5 455.5C1327.5 456.052 1327.05 456.5 1326.5 456.5C1325.95 456.5 1325.5 456.052 1325.5 455.5C1325.5 454.948 1325.95 454.5 1326.5 454.5C1327.05 454.5 1327.5 454.948 1327.5 455.5ZM1333.5 455.5C1333.5 456.052 1333.05 456.5 1332.5 456.5C1331.95 456.5 1331.5 456.052 1331.5 455.5C1331.5 454.948 1331.95 454.5 1332.5 454.5C1333.05 454.5 1333.5 454.948 1333.5 455.5Z" fill="#E6E6E6" stroke="#E6E6E6"/>
      <path d="M1321.5 587.5C1321.5 588.052 1321.05 588.5 1320.5 588.5C1319.95 588.5 1319.5 588.052 1319.5 587.5C1319.5 586.948 1319.95 586.5 1320.5 586.5C1321.05 586.5 1321.5 586.948 1321.5 587.5ZM1327.5 587.5C1327.5 588.052 1327.05 588.5 1326.5 588.5C1325.95 588.5 1325.5 588.052 1325.5 587.5C1325.5 586.948 1325.95 586.5 1326.5 586.5C1327.05 586.5 1327.5 586.948 1327.5 587.5ZM1333.5 587.5C1333.5 588.052 1333.05 588.5 1332.5 588.5C1331.95 588.5 1331.5 588.052 1331.5 587.5C1331.5 586.948 1331.95 586.5 1332.5 586.5C1333.05 586.5 1333.5 586.948 1333.5 587.5Z" fill="#E6E6E6" stroke="#E6E6E6"/>
      <path d="M1321.5 125.5C1321.5 126.052 1321.05 126.5 1320.5 126.5C1319.95 126.5 1319.5 126.052 1319.5 125.5C1319.5 124.948 1319.95 124.5 1320.5 124.5C1321.05 124.5 1321.5 124.948 1321.5 125.5ZM1327.5 125.5C1327.5 126.052 1327.05 126.5 1326.5 126.5C1325.95 126.5 1325.5 126.052 1325.5 125.5C1325.5 124.948 1325.95 124.5 1326.5 124.5C1327.05 124.5 1327.5 124.948 1327.5 125.5ZM1333.5 125.5C1333.5 126.052 1333.05 126.5 1332.5 126.5C1331.95 126.5 1331.5 126.052 1331.5 125.5C1331.5 124.948 1331.95 124.5 1332.5 124.5C1333.05 124.5 1333.5 124.948 1333.5 125.5Z" fill="#E6E6E6" stroke="#E6E6E6"/>
      <path d="M1321.5 257.5C1321.5 258.052 1321.05 258.5 1320.5 258.5C1319.95 258.5 1319.5 258.052 1319.5 257.5C1319.5 256.948 1319.95 256.5 1320.5 256.5C1321.05 256.5 1321.5 256.948 1321.5 257.5ZM1327.5 257.5C1327.5 258.052 1327.05 258.5 1326.5 258.5C1325.95 258.5 1325.5 258.052 1325.5 257.5C1325.5 256.948 1325.95 256.5 1326.5 256.5C1327.05 256.5 1327.5 256.948 1327.5 257.5ZM1333.5 257.5C1333.5 258.052 1333.05 258.5 1332.5 258.5C1331.95 258.5 1331.5 258.052 1331.5 257.5C1331.5 256.948 1331.95 256.5 1332.5 256.5C1333.05 256.5 1333.5 256.948 1333.5 257.5Z" fill="#E6E6E6" stroke="#E6E6E6"/>
      <path d="M1321.5 389.5C1321.5 390.052 1321.05 390.5 1320.5 390.5C1319.95 390.5 1319.5 390.052 1319.5 389.5C1319.5 388.948 1319.95 388.5 1320.5 388.5C1321.05 388.5 1321.5 388.948 1321.5 389.5ZM1327.5 389.5C1327.5 390.052 1327.05 390.5 1326.5 390.5C1325.95 390.5 1325.5 390.052 1325.5 389.5C1325.5 388.948 1325.95 388.5 1326.5 388.5C1327.05 388.5 1327.5 388.948 1327.5 389.5ZM1333.5 389.5C1333.5 390.052 1333.05 390.5 1332.5 390.5C1331.95 390.5 1331.5 390.052 1331.5 389.5C1331.5 388.948 1331.95 388.5 1332.5 388.5C1333.05 388.5 1333.5 388.948 1333.5 389.5Z" fill="#E6E6E6" stroke="#E6E6E6"/>
      <path d="M1321.5 521.5C1321.5 522.052 1321.05 522.5 1320.5 522.5C1319.95 522.5 1319.5 522.052 1319.5 521.5C1319.5 520.948 1319.95 520.5 1320.5 520.5C1321.05 520.5 1321.5 520.948 1321.5 521.5ZM1327.5 521.5C1327.5 522.052 1327.05 522.5 1326.5 522.5C1325.95 522.5 1325.5 522.052 1325.5 521.5C1325.5 520.948 1325.95 520.5 1326.5 520.5C1327.05 520.5 1327.5 520.948 1327.5 521.5ZM1333.5 521.5C1333.5 522.052 1333.05 522.5 1332.5 522.5C1331.95 522.5 1331.5 522.052 1331.5 521.5C1331.5 520.948 1331.95 520.5 1332.5 520.5C1333.05 520.5 1333.5 520.948 1333.5 521.5Z" fill="#E6E6E6" stroke="#E6E6E6"/>
      <path d="M1321.5 653.5C1321.5 654.052 1321.05 654.5 1320.5 654.5C1319.95 654.5 1319.5 654.052 1319.5 653.5C1319.5 652.948 1319.95 652.5 1320.5 652.5C1321.05 652.5 1321.5 652.948 1321.5 653.5ZM1327.5 653.5C1327.5 654.052 1327.05 654.5 1326.5 654.5C1325.95 654.5 1325.5 654.052 1325.5 653.5C1325.5 652.948 1325.95 652.5 1326.5 652.5C1327.05 652.5 1327.5 652.948 1327.5 653.5ZM1333.5 653.5C1333.5 654.052 1333.05 654.5 1332.5 654.5C1331.95 654.5 1331.5 654.052 1331.5 653.5C1331.5 652.948 1331.95 652.5 1332.5 652.5C1333.05 652.5 1333.5 652.948 1333.5 653.5Z" fill="#E6E6E6" stroke="#E6E6E6"/>
      <rect x="1103" y="42" width="157" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="1103" y="59.5" width="157" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="1103" y="172" width="171" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="1103" y="189.5" width="171" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="1103" y="306" width="157" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="1103" y="323.5" width="157" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="1103" y="436" width="171" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="1103" y="453.5" width="171" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="1103" y="579" width="145" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="1103" y="117" width="65" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="1103" y="249" width="145" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="1103" y="370" width="164" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="1103" y="387.5" width="164" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="1103" y="513" width="127" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="1103" y="645" width="127" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="1000" width="16" height="16" fill="#E6E6E6"/>
      <path d="M1015.33 4.91892L1012.66 2.27959C1012.29 1.9068 1011.7 1.9068 1011.32 2.27959L1008.67 4.91892C1008.45 5.13012 1008 5.6179 1008 5.6179L1009.39 6.87217C1009.39 6.87217 1009.88 6.36837 1010.01 6.24604L1011.05 5.21715V14C1011.05 14 1011.62 14 1012 14C1012.33 14 1012.95 14 1012.95 14V5.21715L1013.99 6.24604C1014.17 6.42498 1014.66 6.87217 1014.66 6.87217L1016 5.6179C1016 5.6179 1015.54 5.12183 1015.33 4.91892Z" fill="#E6E6E6"/>
      <path opacity="0.4" d="M1007.33 11.0811L1004.66 13.7204C1004.29 14.0932 1003.7 14.0932 1003.32 13.7204L1000.67 11.0811C1000.45 10.8699 1000 10.3821 1000 10.3821L1001.39 9.12783C1001.39 9.12783 1001.88 9.63163 1002.01 9.75396L1003.05 10.7829V2C1003.05 2 1003.62 2 1004 2C1004.33 2 1004.95 2 1004.95 2V10.7829L1005.99 9.75396C1006.17 9.57502 1006.66 9.12783 1006.66 9.12783L1008 10.3821C1008 10.3821 1007.54 10.8782 1007.33 11.0811Z" fill="#E6E6E6"/>
      <mask id="path-60-inside-1_802_19811" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M169.225 738.655C169.314 738.714 169.39 738.789 169.449 738.877C169.697 739.245 169.597 739.741 169.225 739.987L165.433 742.492C165.3 742.579 165.145 742.626 164.985 742.626C164.539 742.626 164.178 742.268 164.178 741.826V736.816C164.178 736.658 164.225 736.503 164.313 736.372C164.561 736.004 165.062 735.905 165.433 736.15L169.225 738.655Z"/>
      </mask>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M169.225 738.655C169.314 738.714 169.39 738.789 169.449 738.877C169.697 739.245 169.597 739.741 169.225 739.987L165.433 742.492C165.3 742.579 165.145 742.626 164.985 742.626C164.539 742.626 164.178 742.268 164.178 741.826V736.816C164.178 736.658 164.225 736.503 164.313 736.372C164.561 736.004 165.062 735.905 165.433 736.15L169.225 738.655Z" fill="#E6E6E6"/>
      <path d="M169.449 738.877L168.62 739.435V739.435L169.449 738.877ZM169.225 739.987L168.674 739.152L169.225 739.987ZM165.433 742.492L165.984 743.326L165.433 742.492ZM164.313 736.372L163.484 735.814L164.313 736.372ZM165.433 736.15L164.882 736.984H164.882L165.433 736.15ZM168.674 739.49C168.653 739.476 168.635 739.457 168.62 739.435L170.279 738.319C170.146 738.121 169.975 737.952 169.777 737.821L168.674 739.49ZM168.62 739.435C168.555 739.339 168.585 739.211 168.674 739.152L169.777 740.821C170.608 740.271 170.839 739.15 170.279 738.319L168.62 739.435ZM168.674 739.152L164.882 741.657L165.984 743.326L169.777 740.821L168.674 739.152ZM164.882 741.657C164.913 741.637 164.949 741.626 164.985 741.626V743.626C165.34 743.626 165.688 743.522 165.984 743.326L164.882 741.657ZM164.985 741.626C165.083 741.626 165.178 741.707 165.178 741.826H163.178C163.178 742.829 163.996 743.626 164.985 743.626V741.626ZM165.178 741.826V736.816H163.178V741.826H165.178ZM165.178 736.816C165.178 736.857 165.165 736.897 165.143 736.93L163.484 735.814C163.285 736.11 163.178 736.458 163.178 736.816H165.178ZM165.143 736.93C165.082 737.021 164.966 737.04 164.882 736.984L165.984 735.316C165.158 734.77 164.039 734.988 163.484 735.814L165.143 736.93ZM164.882 736.984L168.674 739.49L169.777 737.821L165.984 735.316L164.882 736.984Z" fill="#E6E6E6" mask="url(#path-60-inside-1_802_19811)"/>
      <rect x="152.242" y="724.5" width="29.2773" height="29" rx="3.5" fill="#E6E6E6" stroke="#E6E6E6"/>
      <rect x="125" y="729" width="10" height="14" rx="5" fill="#E6E6E6"/>
      <rect x="88" y="729" width="10" height="14" rx="5" fill="#E6E6E6"/>
      <rect x="42" y="724" width="30" height="30" rx="4" fill="#E6E6E6"/>
      <rect x="54" y="729" width="7" height="14" rx="3.5" fill="#E6E6E6"/>
      <rect x="0.5" y="724.5" width="29.2773" height="29" rx="3.5" fill="#E6E6E6" stroke="#E6E6E6"/>
      <mask id="path-68-inside-2_802_19811" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7971 738.655L16.5896 736.15C16.9606 735.905 17.4619 736.004 17.7093 736.372C17.7977 736.503 17.8449 736.658 17.8449 736.816V741.826C17.8449 742.268 17.4834 742.626 17.0375 742.626C16.8781 742.626 16.7223 742.579 16.5896 742.492L12.7971 739.987C12.4261 739.741 12.3258 739.245 12.5732 738.877C12.6323 738.789 12.7084 738.714 12.7971 738.655Z"/>
      </mask>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7971 738.655L16.5896 736.15C16.9606 735.905 17.4619 736.004 17.7093 736.372C17.7977 736.503 17.8449 736.658 17.8449 736.816V741.826C17.8449 742.268 17.4834 742.626 17.0375 742.626C16.8781 742.626 16.7223 742.579 16.5896 742.492L12.7971 739.987C12.4261 739.741 12.3258 739.245 12.5732 738.877C12.6323 738.789 12.7084 738.714 12.7971 738.655Z" fill="#E6E6E6"/>
      <path d="M12.7971 738.655L13.3483 739.49L12.7971 738.655ZM16.5896 736.15L17.1408 736.984V736.984L16.5896 736.15ZM17.7093 736.372L18.539 735.814L17.7093 736.372ZM16.5896 742.492L16.0385 743.326L16.5896 742.492ZM12.7971 739.987L13.3483 739.152H13.3483L12.7971 739.987ZM12.5732 738.877L13.4029 739.435L12.5732 738.877ZM13.3483 739.49L17.1408 736.984L16.0385 735.316L12.2459 737.821L13.3483 739.49ZM17.1408 736.984C17.0565 737.04 16.9405 737.021 16.8796 736.93L18.539 735.814C17.9833 734.988 16.8648 734.77 16.0385 735.316L17.1408 736.984ZM16.8796 736.93C16.8573 736.897 16.8449 736.857 16.8449 736.816H18.8449C18.8449 736.458 18.7381 736.11 18.539 735.814L16.8796 736.93ZM16.8449 736.816V741.826H18.8449V736.816H16.8449ZM16.8449 741.826C16.8449 741.707 16.9398 741.626 17.0375 741.626V743.626C18.027 743.626 18.8449 742.829 18.8449 741.826H16.8449ZM17.0375 741.626C17.0735 741.626 17.1094 741.637 17.1408 741.657L16.0385 743.326C16.3351 743.522 16.6827 743.626 17.0375 743.626V741.626ZM17.1408 741.657L13.3483 739.152L12.2459 740.821L16.0385 743.326L17.1408 741.657ZM13.3483 739.152C13.438 739.211 13.4676 739.339 13.4029 739.435L11.7435 738.319C11.1841 739.15 11.4142 740.271 12.2459 740.821L13.3483 739.152ZM13.4029 739.435C13.388 739.457 13.3693 739.476 13.3483 739.49L12.2459 737.821C12.0475 737.952 11.8766 738.121 11.7435 738.319L13.4029 739.435Z" fill="#E6E6E6" mask="url(#path-68-inside-2_802_19811)"/>
      <rect width="118" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" width="123" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="436" width="171" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="732" width="34" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="876" width="115" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="1097" width="44" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="1276" width="64" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect y="49" width="48" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect y="181" width="48" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect y="313" width="48" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect y="445" width="48" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect y="577" width="48" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect y="115" width="24" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect y="247" width="48" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect y="379" width="48" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect y="511" width="48" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect y="643" width="48" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="41" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="58.5" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="173" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="190.5" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="305" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="322.5" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="437" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="454.5" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="569" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="586.5" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="107" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="124.5" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="239" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="256.5" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="371" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="388.5" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="503" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="520.5" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="635" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="140" y="652.5" width="251" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="436" y="40" width="240" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="436" y="57.5" width="240" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="436" y="172" width="240" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="436" y="189.5" width="240" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="436" y="304" width="240" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="436" y="321.5" width="240" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="436" y="446" width="240" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="436" y="578" width="240" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="436" y="116" width="240" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="436" y="238" width="240" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="436" y="255.5" width="240" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="436" y="380" width="240" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="436" y="512" width="240" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="436" y="644" width="240" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="732" y="49" width="165" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="732" y="179" width="165" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="732" y="309" width="165" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="732" y="441" width="165" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="732" y="573" width="165" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="732" y="112" width="165" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="732" y="243" width="165" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="732" y="375" width="165" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="732" y="507" width="165" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="732" y="641" width="165" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="967" y="49" width="24" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="967" y="178" width="24" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="967" y="309" width="24" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="967" y="441" width="24" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="967" y="573" width="24" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="967" y="112" width="24" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="967" y="243" width="24" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="967" y="375" width="24" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="967" y="507" width="24" height="12.25" rx="6.125" fill="#E6E6E6"/>
      <rect x="967" y="641" width="24" height="12.25" rx="6.125" fill="#E6E6E6"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "skeletonPreloader"
}
</script>

<style lang="scss">
.skeleton-box {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  @media screen and (max-width: 600px) {
    display: none;
  }
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    transform: translateX(-100%);
    background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

</style>