<template>
  <nav class="breadcrumbs">
    <div class="wrapper">
      <router-link v-if="!isAdmPage" to="/applications/" class="breadcrumbs__link">Мои заявки</router-link>
      <router-link v-else to="/admin/applications/" class="breadcrumbs__link">Все заявки</router-link>
      <span class="breadcrumbs__link">Заявка {{ application.number }}</span>
    </div>
  </nav>
  <section class="cabinet cabinet--tabs">
    <div class="wrapper">
      <div action="#" class="form form--tabs">

        <div class="form__columns">

          <div class="form__column">
            <ul class="cabinet__tabs">
              <li class="cabinet__name" :class="{ 'ui-tabs-active': !showDocumentsTab }"
                @click.prevent="showDocumentsTab = false">
                <a href="#tabs-1">Основные данные</a>
              </li>
              <li class="cabinet__name" :class="{ 'ui-tabs-active': showDocumentsTab }"
                @click.prevent="showDocumentsTab = true">
                <a href="#tabs-2">Документы</a>
              </li>
            </ul>
            <div class="cabinet__field" v-show="!showDocumentsTab">
              <div class="form__fields">

                <div v-if="application.organizationalSupportMeasuresStatus > 0" class="cabinet__measures-lbl">
                  <span>Организационные меры поддержки:</span>
                  <div v-html="getStatusHtmlElement(application.organizationalSupportMeasuresStatus)"></div>
                </div>
                <div v-if="application.financialSupportMeasuresStatus > 0" class="cabinet__measures-lbl">
                  <span>Возмещение части затрат:</span>
                  <div v-html="getStatusHtmlElement(application.financialSupportMeasuresStatus)"></div>
                </div>
                <p class="cabinet__maintitle">Клинические исследования лекарственного препарата «{{ application.name }}»
                </p>
                <span v-if="application?.companyId?.name"
                  class="cabinet__subtitle">{{ application?.companyId?.name }}</span>
              </div>
              <div class="form__fields">
                <div class="form__field" :class="{ 'form__field--error': v$.application.name.$errors.length }">
                  <label for="name" class="form__label">Наименование лекарственного препарата*</label>
                  <input type="text" v-model="v$.application.name.$model" id="name" class="form__input"
                    :disabled="forbiddenToEdit">
                </div>
                <div class="form__field"
                  :class="{ 'form__field--error': v$.application.internationalName.$errors.length }">
                  <label for="name2" class="form__label">Международное непатентованное наименование*</label>
                  <input type="text" v-model="v$.application.internationalName.$model" id="name2" class="form__input"
                    :disabled="forbiddenToEdit">
                </div>
                <div class="form__field" :class="{ 'form__field--error': v$.application.form.$errors.length }">
                  <label for="shape" class="form__label">Форма выпуска*</label>
                  <input type="text" v-model="v$.application.form.$model" id="shape" class="form__input"
                    :disabled="forbiddenToEdit">
                </div>
                <div class="form__field" :class="{ 'form__field--error': v$.application.dose.$errors.length }">
                  <label for="dose" class="form__label">Дозировка*</label>
                  <input type="text" v-model="v$.application.dose.$model" id="dose" class="form__input"
                    :disabled="forbiddenToEdit">
                </div>
                <div class="form__field"
                  :class="{ 'form__field--error': v$.application.indicationsForUse.$errors.length }">
                  <label for="reason" class="form__label">Показания к применению*</label>
                  <textarea id="reason" v-model="v$.application.indicationsForUse.$model" class="form__text"
                    :disabled="forbiddenToEdit"></textarea>
                </div>
                <div class="form__field"
                  :class="{ 'form__field--error': v$.application.contraindicationsForUse.$errors.length }">
                  <label for="against" class="form__label">Противопоказания к применению (при наличии)</label>
                  <textarea id="against" v-model="v$.application.contraindicationsForUse.$model" class="form__text"
                    :disabled="forbiddenToEdit || checks.contraindicationsForUse"></textarea>
                </div>
                <div class="form__field">
                  <input type="checkbox" id="checkbox1" class="form__checkbox" v-model="checks.contraindicationsForUse"
                    :disabled="forbiddenToEdit">
                  <label for="checkbox1" class="form__checkbox-label">Не установлены</label>
                </div>
              </div>
              <div class="form__fields">
                <div class="form__field" :class="{ 'form__field--error': v$.application.therapeuticArea.$errors.length }">
                  <label for="area" class="form__label">Терапевтическая область*</label>

                  <VueMultiselect v-model="application.therapeuticArea" placeholder="Выбрать из справочника"
                    :select-label="'Выбрать'" :selected-label="'Выбрано'" :deselect-label="``" :showLabels="false"
                    :options="dictionaries.therapeuticArea" :disabled="forbiddenToEdit">
                  </VueMultiselect>

                </div>
                <div class="form__field" :class="{ 'form__field--error': v$.application.researchPhase.$errors.length }">
                  <label for="phase" class="form__label">Фаза клинического исследования *</label>

                  <VueMultiselect v-model="application.researchPhase" placeholder="Выбрать из справочника"
                    :select-label="'Выбрать'" :selected-label="'Выбрано'" :deselect-label="``" :showLabels="false"
                    :options="dictionaries.researchPhase" :disabled="forbiddenToEdit">
                  </VueMultiselect>

                </div>
                <div class="form__field"
                  :class="{ 'form__field--error': v$.application.permissionDetails.$errors.length }">
                  <label for="req" class="form__label">Реквизиты (дата и номер) разрешения на проведение клинического
                    исследования, выданного уполномоченным федеральным органом исполнительной власти*</label>
                  <input type="text" v-model="v$.application.permissionDetails.$model" id="req" class="form__input"
                    :disabled="forbiddenToEdit">
                </div>
                <div class="form__field"
                  :class="{ 'form__field--error': v$.application.numberOfPatients.$errors.length }">
                  <label for="number" class="form__label">Число пациентов - общее количество по РКИ, количество запланированных пациентов в центрах ДЗМ, количество рандомизированных пациентов в центрах ДЗМ*</label>
                  <input type="text" v-model="v$.application.numberOfPatients.$model" id="number" class="form__input"
                    :disabled="forbiddenToEdit">
                </div>
                <div class="form__field"
                  :class="{ 'form__field--error': v$.application.durationOfParticipation.$errors.length }">
                  <label for="period" class="form__label">Длительность участия пациентов в исследовании*</label>
                  <input type="text" v-model="v$.application.durationOfParticipation.$model" id="period"
                    class="form__input" :disabled="forbiddenToEdit">
                </div>
                <div class="form__field"
                  :class="{ 'form__field--error': v$.application.purposeOfResearch.$errors.length }">
                  <label for="goal" class="form__label">Цель исследования*</label>
                  <textarea id="goal" v-model="v$.application.purposeOfResearch.$model" class="form__text"
                    :disabled="forbiddenToEdit">Текст для теста</textarea>
                </div>
                <div class="form__field" :class="{ 'form__field--error': v$.application.goalsOfResearch.$errors.length }">
                  <label for="task" class="form__label">Задачи исследования*</label>
                  <textarea id="task" v-model="v$.application.goalsOfResearch.$model" class="form__text"
                    :disabled="forbiddenToEdit">Текст для теста</textarea>
                </div>
              </div>
              <div class="form__fields">
                <div class="form__field"
                  :class="{ 'form__field--error': v$.application.selectionAndExclusion.$errors.length }">
                  <label for="criteria" class="form__label">Отбор и исключение субъектов исследования (критерии включения,
                    критерии не включения, критерии исключения субъектов)*</label>
                  <textarea type="text" v-model="v$.application.selectionAndExclusion.$model" id="criteria"
                    class="form__text" :disabled="forbiddenToEdit"></textarea>
                </div>
                <div class="form__field" :class="{ 'form__field--error': v$.application.comparisonGroup.$errors.length }">
                  <label for="group" class="form__label">Наличие группы сравнения (для плацебо и препарата
                    сравнения)*</label>
                  <input type="text" v-model="application.comparisonGroup" id="group" class="form__input"
                    :disabled="forbiddenToEdit || checks.comparisonGroup">
                </div>
                <div class="form__field">
                  <input type="checkbox" v-model="checks.comparisonGroup" id="checkbox" class="form__checkbox"
                    :disabled="forbiddenToEdit">
                  <label for="checkbox" class="form__checkbox-label">Отсутствуют</label>
                </div>

                <div class="form__field">
                  <label for="actual" class="form__label">
                    Перечень городских медицинских организаций, на базе которых проводится (планируется проводить
                    клиническое исследование)
                  </label>
                </div>
                <div class="form__field-error-panel" v-if="v$.application.coopMedOrgsAlreadyWord.$errors.length
                  || v$.application.coopMedOrgsWantToCoop.$errors.length
                  || v$.application.coopMedOrgsHelpToCoop.$errors.length">
                  Выберите хотя бы одну опцию ниже
                </div>
                <div class="form__field">
                  <input type="checkbox" id="coopMedOrgsAlreadyWord"
                    v-model="v$.application.coopMedOrgsAlreadyWord.$model" class="form__checkbox"
                    :disabled="forbiddenToEdit">
                  <label for="coopMedOrgsAlreadyWord" class="form__checkbox-label">Уже работаем с городскими медицинскими
                    организациями, по которым получено разрешение Минздрава России на проведении в них клинического
                    исследования (указать)</label>
                </div>
                <div v-if="application.coopMedOrgsAlreadyWord"
                  :class="{ 'form__field&#45;&#45;error': v$.application.coopMedOrgsList.$errors.length }"
                  class="form__field">
                  <VueMultiselect v-model="v$.application.coopMedOrgsList.$model" placeholder="Выбрать из справочника"
                    :select-label="'Выбрать'" :selected-label="'Выбрано'" :deselect-label="``" :showLabels="false"
                    :multiple="true" :disabled="forbiddenToEdit" :options="dictionaries.medOrgs">
                  </VueMultiselect>
                </div>
                <div class="form__field">
                  <input type="checkbox" id="coopMedOrgsWantToCoop" v-model="v$.application.coopMedOrgsWantToCoop.$model"
                    class="form__checkbox" :disabled="forbiddenToEdit">
                  <label for="coopMedOrgsWantToCoop" class="form__checkbox-label">Хотим работать с конкретными городскими
                    медицинскими организациями (указать)</label>
                </div>
                <div v-if="application.coopMedOrgsWantToCoop"
                  :class="{ 'form__field&#45;&#45;error': v$.application.coopMedOrgsWishList.$errors.length }"
                  class="form__field">
                  <VueMultiselect v-model="v$.application.coopMedOrgsWishList.$model" placeholder="Выбрать из справочника"
                    :select-label="'Выбрать'" :selected-label="'Выбрано'" :deselect-label="``" :showLabels="false"
                    :multiple="true" :disabled="forbiddenToEdit" :options="dictionaries.medOrgs">
                  </VueMultiselect>
                </div>
                <div class="form__field">
                  <input type="checkbox" id="coopMedOrgsHelpToCoop" v-model="v$.application.coopMedOrgsHelpToCoop.$model"
                    class="form__checkbox" :disabled="forbiddenToEdit">
                  <label for="coopMedOrgsHelpToCoop" class="form__checkbox-label">Просим оказать помощь в подборе
                    городской медицинской организации</label>
                </div>
                <!--                <template v-if="
                  v$.application.coopMedOrgsAlreadyWord.$errors.length ||
                  v$.application.coopMedOrgsAlreadyWord.$errors.length ||
                  v$.application.coopMedOrgsList.$errors.length ||
                  v$.application.coopMedOrgsHelpToCoop.$errors.length">
                      <div class="form__field form__field&#45;&#45;custom-error">
                        Для отправки заявки корректно заполните блок выше, либо установите опцию "Просим оказать помощь в подборе городской медицинской организации"
                      </div>
                    </template>-->

                <div class="form__field" :class="{ 'form__field--error': v$.application.relevanceOfDrug.$errors.length }">
                  <label for="actual" class="form__label">Актуальность лекарственного препарата для столичного
                    здравоохранения*</label>
                  <textarea v-model="v$.application.relevanceOfDrug.$model" id="actual" class="form__text"
                    :disabled="forbiddenToEdit"></textarea>
                </div>
                <div class="form__field"
                  :class="{ 'form__field--error': v$.application.availabilityOfAnalogues.$errors.length }">
                  <label for="analog" class="form__label">Наличие аналогичных отечественных и (или) зарубежных
                    лекарственных препаратов (указать перечень)</label>
                  <textarea id="analog" v-model="application.availabilityOfAnalogues" class="form__text"
                    :disabled="forbiddenToEdit || checks.availabilityOfAnalogues"></textarea>
                </div>
                <div class="form__field">
                  <input type="checkbox" v-model="checks.availabilityOfAnalogues" id="checkbox2" class="form__checkbox"
                    :disabled="forbiddenToEdit">
                  <label for="checkbox2" class="form__checkbox-label">Отсутствуют</label>
                </div>
                <div class="form__field"
                  :class="{ 'form__field--error': v$.application.potentialEfficiency.$errors.length }">
                  <label for="effect" class="form__label">Потенциальная медико-экономическая эффективность лекарственного
                    препарата*</label>
                  <textarea id="effect" v-model="v$.application.potentialEfficiency.$model" class="form__text"
                    :disabled="forbiddenToEdit">Текст для теста</textarea>
                </div>
              </div>

              <!-- Меры поддержки -->
              <div>
                <div v-if="!isAdmPage" class="form__fields no-print">
                  <p class="title-4">Меры поддержки</p>
                  <input type="checkbox" id="checkbox01" class="form__checkbox" v-model="checks.applyRequestMeasures"
                    :disabled="forbiddenToEdit">
                  <label for="checkbox01" class="form__checkbox-label">Подтверждаем, что запрашиваем меры поддержки в
                    отношении клинического исследования, которое проводится (планируется проводить) на базе
                    медицинских организаций системы Департамента здравоохранения города Москвы</label>
                </div>
                <div class="form__fields">
                  <div class="form__field">
                    <input type="checkbox" id="switch1" class="form__switcher no-print"
                      :disabled="!checks.applyRequestMeasures || forbiddenToEdit" v-model="checks.switch1">
                    <label for="switch1" class="form__switcher-label no-print">Организационные</label>
                    <div class="form__dropdown">
                      <div class="form__field"
                        :class="{ 'form__field--error': v$.application.organizationalSupportMeasures.$errors.length }">

                        <VueMultiselect v-model="application.organizationalSupportMeasures"
                          placeholder="Выбрать из справочника" :select-label="'Выбрать'" :selected-label="'Выбрано'"
                          :deselect-label="``" :showLabels="false" :multiple="true" :disabled="forbiddenToEdit"
                          :options="dictionaries.organizationalSupportMeasures">
                        </VueMultiselect>
                      </div>
                    </div>
                  </div>
                  <div class="form__field">
                    <input type="checkbox" id="switch2" class="form__switcher"
                      :disabled="!checks.applyRequestMeasures || forbiddenToEdit || !application.coopMedOrgsAlreadyWord"
                      v-model="checks.switch2">
                    <label for="switch2" class="form__switcher-label no-print">Возмещение части затрат</label>
                    <div class="form__caption--small"
                      v-if="checks.applyRequestMeasures && !application.coopMedOrgsAlreadyWord">
                      Возможность выбора данной меры поддержки возможно только в случае проведения клинического
                      исследования в городских медицинских
                      организациях, по которым получено разрешение Минздрава России на проведение в них исследования
                      (укажите их ранее
                    </div>
                    <div class="form__dropdown">
                      <div class="form__field"
                        :class="{ 'form__field--error': v$.application.financialSupportMeasures.$errors.length }">
                        <VueMultiselect v-model="application.financialSupportMeasures"
                          placeholder="Выбрать из справочника" :select-label="'Выбрать'" :selected-label="'Выбрано'"
                          :deselect-label="``" :showLabels="false" :multiple="true" :disabled="forbiddenToEdit"
                          :options="dictionaries.financialSupportMeasures">
                        </VueMultiselect>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="!isAdmPage" class="form__fields no-print">
                  <p class="cabinet__subtitle">Подтверждаем</p>
                  <div class="form__field">
                    <input type="checkbox" id="checkbox4" class="form__checkbox" :disabled="forbiddenToEdit"
                      v-model="checks.checkbox4">
                    <label for="checkbox4" class="form__checkbox-label">С порядком рассмотрения, отбора заявки
                      ознакомлены и согласны</label>
                  </div>
                  <div class="form__field">
                    <input type="checkbox" id="checkbox5" class="form__checkbox" :disabled="forbiddenToEdit"
                      v-model="checks.checkbox5">
                    <label for="checkbox5" class="form__checkbox-label">Подлинность и достоверность предоставленных в
                      составе заявки сведений и документов</label>
                  </div>
                  <div class="form__field">
                    <input type="checkbox" id="checkbox6" class="form__checkbox" :disabled="forbiddenToEdit"
                      v-model="checks.checkbox6">
                    <label for="checkbox6" class="form__checkbox-label">При подготовке заявки соблюдены авторские и иные
                      права третьих лиц, а также иных правообладателей на представление материалов и их использование
                      для проведения экспертизы</label>
                  </div>
                  <div class="form__field">
                    <input type="checkbox" id="checkbox7" class="form__checkbox" :disabled="forbiddenToEdit"
                      v-model="checks.checkbox7">
                    <label for="checkbox7" class="form__checkbox-label">согласие на передачу материалов заявки членам
                      Клинического комитета</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="cabinet__field" v-show="showDocumentsTab">
              <div class="cabinet__docs">
                <p v-if="!isAdmPage" class="cabinet__subtitle">Загрузите, пожалуйста, необходимые документы</p>
                <ul class="cabinet__list">
                  <li v-for="(caption, field) in filedFields" :key="field" class="cabinet__item">
                    <template v-if="application[field]">
                      <div class="cabinet__doc">
                        <template v-if="Array.isArray(application[field]) && application[field].length">
                          <div :style="{marginRight: '8px', display: 'flex', alignItems: 'center'}" v-for="file in application[field]" :key="file.originalFileName">
                            <a :href="file.urlLink" class="cabinet__download"
                              :download="file.originalFileName">{{ file.originalFileName || file.name }}
                            </a>
                            <a v-if="!forbiddenToEdit && !isAdmPage" href="#" class="cabinet__delete"
                              data-title="Удаление файла" @click.prevent="handleRemoveFromArray(field, file)">
                              <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7M7 7L13 1M7 7L13 13M7 7L1 13" stroke-width="2"></path>
                              </svg>
                            </a>
                          </div>
                        </template>
                        <template v-else-if="application[field].hasOwnProperty('originalFileName')">
                          <a :href="application[field].urlLink" class="cabinet__download"
                            :download="application[field].originalFileName">
                            {{ application[field].originalFileName }}
                          </a>
                          <a v-if="!forbiddenToEdit && !isAdmPage" href="#" class="cabinet__delete"
                            data-title="Удаление файла" @click.prevent="application[field] = ''">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 1L7 7M7 7L13 1M7 7L13 13M7 7L1 13" stroke-width="2"></path>
                            </svg>
                          </a>
                        </template>
                        <template v-else-if="application[field] === '[object Object]'">
                          <a href="#" @click.prevent class="cabinet__download">Требуется перезалив файла</a>
                        </template>
                        <template v-else>
                          <a href="#" @click.prevent class="cabinet__download" v-if="caption !== 'Доп. файлы'">Ожидает
                            загрузки</a>
                        </template>

                      </div>
                      <p class="cabinet__filename">{{ caption }}</p>
                    </template>
                  </li>
                </ul>
                <template v-if="!isAdmPage">
                  <p v-if="addFilesCaptionShown" class="cabinet__more">Необходимо добавить</p>
                  <ul class="cabinet__list" v-if="!isAdmPage">
                    <li class="cabinet__item" v-if="!application.file_articlesOfAssociation">
                      <input type="file" accept=".pdf,image/*" id="file1" class="cabinet__upload"
                        @change="loadFile($event, 'file_articlesOfAssociation')">
                      <label for="file1" class="cabinet__upload-file">Устав организации</label>
                    </li>
                    <li class="cabinet__item" v-if="!application.file_certificateOfState">
                      <input type="file" accept=".pdf,image/*" id="file2" class="cabinet__upload"
                        @change="loadFile($event, 'file_certificateOfState')">
                      <label for="file2" class="cabinet__upload-file">Свидетельство о государственной регистрации</label>
                    </li>
                    <li class="cabinet__item" v-if="!application.file_certificateOfTax">
                      <input type="file" accept=".pdf,image/*" id="file3" class="cabinet__upload"
                        @change="loadFile($event, 'file_certificateOfTax')">
                      <label for="file3" class="cabinet__upload-file">Свидетельство о постановке на учет в налоговом
                        органе</label>
                    </li>
                    <li class="cabinet__item" v-if="!application.file_decisionOfAppointment">
                      <input type="file" accept=".pdf,image/*" id="file4" class="cabinet__upload"
                        @change="loadFile($event, 'file_decisionOfAppointment')">
                      <label for="file4" class="cabinet__upload-file">Решение и приказ о назначении на должность
                        руководителя</label>
                    </li>
                    <li class="cabinet__item" v-if="!application.file_proxyToSign">
                      <input type="file" accept=".pdf,image/*" id="file5" class="cabinet__upload"
                        @change="loadFile($event, 'file_proxyToSign')">
                      <label for="file5" class="cabinet__upload-file">Доверенность на подписанта соглашения</label>
                      <p class="cabinet__comment">Если соглашение подписывается по доверенности не руководителем
                        организации</p>
                    </li>
                    <li class="cabinet__item" v-if="!application.file_researchPermission">
                      <input type="file" accept=".pdf,image/*" id="file6" class="cabinet__upload"
                        @change="loadFile($event, 'file_researchPermission')">
                      <label for="file6" class="cabinet__upload-file">Разрешение на проведение клинического исследования,
                        выданное уполномоченным федеральным органом исполнительной власти</label>
                    </li>
                    <li class="cabinet__item" v-if="!application.file_researchDesign">
                      <input type="file" accept=".pdf,image/*" id="file7" class="cabinet__upload"
                        @change="loadFile($event, 'file_researchDesign')">
                      <label for="file7" class="cabinet__upload-file">Дизайн клинического исследования и (или) брошюра
                        исследователя</label>
                    </li>
                    <li class="cabinet__item" v-if="!application.file_agreementDraft">
                      <input type="file" accept=".pdf,image/*" id="file8" class="cabinet__upload"
                        @change="loadFile($event, 'file_agreementDraft')">
                      <label for="file8" class="cabinet__upload-file">Проект договора о проведении клинического
                        исследования лекарственного препарата для медицинского применения, планируемый к заключению с
                        городской медицинской организацией</label>
                    </li>
                    <li class="cabinet__item"
                      v-if="!application.file_smeta && application.financialSupportMeasures.length">
                      <input type="file" accept=".pdf,.xls,.xlsx,image/*" id="file9" class="cabinet__upload"
                        @change="loadFile($event, 'file_smeta')">
                      <label for="file9" class="cabinet__upload-file">Смета расходов на проведение клинического
                        исследования</label>
                    </li>
                    <li class="cabinet__item">
                      <input type="file" multiple accept=".pdf,.xls,.xlsx,image/*" id="file10" class="cabinet__upload"
                        @change="loadFile($event, 'file_additional', true)">
                      <label for="file10" class="cabinet__upload-file">Доп. документы</label>
                    </li>
                  </ul>
                </template>
              </div>
            </div>
          </div>
          <aside class="form__aside">
            <div class="form__aside-inner">
              <template v-if="lastLog?.comment && !isAdmPage">
                <div class="cabinet__order-comment">
                  <p class="title-4">Комментарий по заявке</p>
                  <div class="cabinet__order-text">{{ lastLog.comment }}</div>
                </div>
              </template>
              <div class="cabinet__history">
                <p class="cabinet__subtitle">История изменений</p>

                <ul class="cabinet__stagelist">
                  <template v-for="(log, idx) in application.log" :key="idx">
                    <li v-if="log?.organizationalSupportMeasuresStatus || log?.financialSupportMeasuresStatus"
                      class="cabinet__stageitem" :class="getLogStatusElement(log, application.log[idx - 1]).class">
                      <p>
                        <span v-html="getLogStatusElement(log, application.log[idx - 1]).html"></span>
                        <br />
                        <a v-if="isAdmPage && log.comment" href="#" @click.prevent="openedLogId = log.id">ПОДРОБНЕЕ</a>
                      </p>
                      <span class="cabinet__time"
                        v-if="log.timestamp">{{ new Date(log.timestamp).toLocaleDateString({ year: 'numeric', month: 'short', day: 'numeric' }) }}
                      </span>
                      <div :id="`commentPopup${idx}`" v-if="log.comment" v-show="false">
                        {{ log.comment }}
                      </div>
                    </li>
                    <li v-else class="cabinet__stageitem" :class="getStatusClass(log.status)">
                      <p>
                        <span v-if="log.status === 0">Черновик</span>
                        <span v-if="log.status === 1">Экспертиза заявки</span>
                        <span v-if="log.status === 2">Отправлена на доработку экспертом</span>
                        <span v-if="log.status === 3">Отклонена экспертом</span>
                        <span v-if="log.status === 4">Заявка одобрена</span>
                        <span v-if="log.status === 5">На рассмотрении клинического комитета</span>
                        <span v-if="log.status === 6">Отправлена на доработку клиническим комитетом</span>
                        <span v-if="log.status === 7">Отклонена клиническим комитетом</span>
                        <span v-if="log.status === 8">Одобрена клиническим комитетом</span>
                        <br />
                        <a v-if="isAdmPage && log.comment" href="#" @click.prevent="openedLogId = log.id">ПОДРОБНЕЕ</a>
                      </p>
                      <span class="cabinet__time">{{ log.date }}</span>
                      <div :id="`commentPopup${idx}`" v-if="log.comment" v-show="false">
                        {{ log.comment }}
                      </div>
                    </li>
                    <template v-if="openedLogId === log.id">
                      <div class="overlay"></div>
                      <div class="message">
                        <p class="message__title">История комментариев</p>
                        <ul class="message__list">
                          <li class="message__item">
                            <div class="message__text">{{ log.comment }}</div>
                            <span class="message__date">{{ log.date }}</span>
                          </li>
                        </ul>
                        <a href="#" @click.prevent="openedLogId = ''" class="message__btn">Закрыть</a>
                        <button type="button" @click.prevent="openedLogId = ''" class="message__close"></button>
                      </div>
                    </template>
                  </template>
                </ul>
              </div>
              <template v-if="!application.archive">
                <!-- admin controls -->
                <template v-if="isAdmPage">
                  <div v-if="application.organizationalSupportMeasuresStatus === 1" class="cabinet__btns-set">
                    <div class="cabinet__user">
                      <a href="#" class="cabinet__draft cabinet__draft--apply"
                        :class="{ 'cabinet__draft--disabled': showUpworkPanel }" @click.prevent="sendApprove('org')"
                        :disabled="showUpworkPanel">
                        Одобрить (орг.&nbsp;поддержка)
                      </a>
                      <a href="#" class="cabinet__draft cabinet__draft--decline"
                        :class="{ 'cabinet__draft--disabled': showUpworkPanel }"
                        @click.prevent="showUpworkPanel = true; sendAction = 'reject'; sendType = 'org'">
                        Отклонить (орг.&nbsp;поддержка)
                      </a>
                    </div>
                  </div>
                  <div v-if="application.financialSupportMeasuresStatus === 1" class="cabinet__btns-set">
                    <div class="cabinet__user">
                      <a href="#" class="cabinet__draft cabinet__draft--apply"
                        :class="{ 'cabinet__draft--disabled': showUpworkPanel }" @click.prevent="sendApprove('fin')"
                        :disabled="showUpworkPanel">
                        Одобрить (фин.&nbsp;поддержка)
                      </a>
                      <a href="#" class="cabinet__draft cabinet__draft--decline"
                        :class="{ 'cabinet__draft--disabled': showUpworkPanel }"
                        @click.prevent="showUpworkPanel = true; sendAction = 'reject'; sendType = 'fin'">
                        Отклонить (фин.&nbsp;поддержка)
                      </a>
                    </div>
                  </div>
                  <div
                    v-if="application.organizationalSupportMeasuresStatus === 1 || application.financialSupportMeasuresStatus === 1"
                    class="cabinet__btns-set">
                    <button @click.prevent="showUpworkPanel = true; sendAction = 'rework';"
                      class="btn cabinet__submit cabinet__submit--full">
                      <svg width="20" height="22" viewBox="0 0 20 22" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.81 6.94L11.06 3.19L2 12.25V16H5.75L14.81 6.94ZM4 14V13.08L11.06 6.02L11.98 6.94L4.92 14H4Z" />
                        <path
                          d="M17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0C14.41 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04Z" />
                        <path d="M20 18H0V22H20V18Z" />
                      </svg>
                      Отправить на доработку
                    </button>
                  </div>
                  <div class="cabinet__upwork" v-if="showUpworkPanel">
                    <p class="title-4">Укажите необходимые комментарии по заявке</p>
                    <p>После отправки они отобразятся у пользователя в заявке</p>
                    <textarea class="form__text" v-model="logMessageText"></textarea>
                    <div class="cabinet__btns cabinet__btns--admin">
                      <a href="#" class="btn cabinet__save" @click.prevent="showUpworkPanel = false">Отменить</a>
                      <button type="submit" class="btn cabinet__submit" @click.prevent="send">Отправить</button>
                    </div>
                  </div>
                </template>
                <template v-if="isAdmPage">
                  <!--
                    0 - draft
                    1 - на рассмотрении
                    2 - доработка
                    3 - принято
                    4 - отмена
                  -->
                  <!--

                  <template v-if="application.organizationalSupportMeasuresStatus === 1">
                    <div class="cabinet__user">
                      <a href="#"
                        class="cabinet__draft cabinet__draft&#45;&#45;decline"
                        :class="{'cabinet__draft&#45;&#45;disabled': showUpworkPanel}"
                        @click.prevent="showUpworkPanel = true; sendAction = 'reject'; sendType = 'org'"
                      >
                        отклонить (орг. поддержка)
                      </a>
                      <a href="#"
                        class="cabinet__draft cabinet__draft&#45;&#45;return"
                        :class="{'cabinet__draft&#45;&#45;disabled': showUpworkPanel}"
                        @click.prevent="showUpworkPanel = true; sendAction = 'rework'; sendType = 'org'"
                      >
                        на доработку  (орг. поддержка)
                      </a>
                    </div>
                    <button
                      @click.prevent="sendApprove" :disabled="showUpworkPanel"
                      type="submit"
                      class="btn cabinet__submit"
                    >Одобрить (орг. поддержка)
                    </button>
                  </template>
                  <template v-if="application.financialSupportMeasuresStatus === 1">
                    <div class="cabinet__user">
                      <a href="#"
                        class="cabinet__draft cabinet__draft&#45;&#45;decline"
                        :class="{'cabinet__draft&#45;&#45;disabled': showUpworkPanel}"
                        @click.prevent="showUpworkPanel = true; sendAction = 'reject'; sendType = 'fin'"
                      >
                        отклонить (орг. поддержка)
                      </a>
                      <a href="#"
                        class="cabinet__draft cabinet__draft&#45;&#45;return"
                        :class="{'cabinet__draft&#45;&#45;disabled': showUpworkPanel}"
                        @click.prevent="showUpworkPanel = true; sendAction = 'rework'; sendType = 'fin'"
                      >
                        на доработку  (орг. поддержка)
                      </a>
                    </div>
                    <button
                      @click.prevent="sendApprove" :disabled="showUpworkPanel"
                      type="submit"
                      class="btn cabinet__submit"
                    >Одобрить (орг. поддержка)
                    </button>
                  </template>
-->
                </template>
                <!-- user controls -->
                <template v-else>
                  <div class="cabinet__user">
                    <a v-if="application.organizationalSupportMeasuresStatus === 0 && application.financialSupportMeasuresStatus === 0"
                      href="#" @click.prevent="deleteConformationShown = true"
                      class="cabinet__draft cabinet__draft--remove">
                      Удалить
                    </a>
                    <a v-if="application.organizationalSupportMeasuresStatus === 0 && application.financialSupportMeasuresStatus === 0"
                      href="#" @click.prevent="sendEdit(true)" class="cabinet__draft">
                      Сохранить черновик
                    </a>
                  </div>
                  <!-- Если не отклонена -->
                  <template
                    v-if="application.organizationalSupportMeasuresStatus === 0 && application.financialSupportMeasuresStatus === 0">
                    <button type="submit" @click.prevent="tryConformation" class="btn cabinet__submit">
                      Отправить на рассмотрение
                    </button>
                  </template>
                  <template
                    v-else-if="application.organizationalSupportMeasuresStatus === 2 || application.financialSupportMeasuresStatus === 2">
                    <button type="submit" @click.prevent="tryConformation" class="btn cabinet__submit">
                      Повторно отправить на рассмотрение
                    </button>
                  </template>

                  <div class="cabinet__application-submit-error" v-if="this.v$.application.$errors.length">Для отправки на
                    рассмотрение заполните все обязательные поля, а также загрузите файлы в разделе “Документы”</div>

                  <div v-show="deleteConformationShown">
                    <div class="overlay"></div>
                    <div class="message">
                      <p class="message__title">Вы действительно хотите удалить заявку?</p>
                      <div class="message__btns">
                        <a href="#" @click.prevent="deleteConformationShown = false" class="message__link">Отменить</a>
                        <button type="button" @click.prevent="sendDelete(); deleteConformationShown = false;"
                          class="message__btn message__btn--red">Удалить</button>
                      </div>
                      <button type="button" @click.prevent="deleteConformationShown = false"
                        class="message__close"></button>
                    </div>
                  </div>
                  <div v-show="sendConformationShown">
                    <div class="overlay"></div>
                    <div class="message">
                      <p class="message__title">Необходимо подтвердить изменения для сохранения</p>
                      <p v-if="v$.application.$error">Для отправки на рассмотрение Вам нужно заполнить все обязательные
                        поля</p>
                      <div class="message__btns">
                        <a href="#" @click.prevent="sendConformationShown = false" class="message__link">Отменить</a>
                        <button v-if="!v$.application.$error" type="submit"
                          @click.prevent="sendEdit(false); sendConformationShown = false;"
                          class="message__btn">Подтвердить</button>
                      </div>
                      <button type="button" @click.prevent="sendConformationShown = false"
                        class="message__close"></button>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </aside>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import "@fancyapps/ui/dist/fancybox.css";
import { Fancybox } from "@fancyapps/ui";
Fancybox;
import * as dictionaries from '@/misc/dictionaries.js';
import useVuelidate from '@vuelidate/core'
// eslint-disable-next-line
import { required, minLength, maxLength, sameAs, helpers } from '@vuelidate/validators'
import VueMultiselect from 'vue-multiselect'

export default {
  components: { VueMultiselect },
  async created() {
    await this.$store.dispatch('fetchAllApplicationById', this.$route.params.id || false);
    this.application = {
      ...this.application,
      ...this.$store.getters.getCurrentApplication,
    }

    if (!this.application.coopMedOrgsList) this.application.coopMedOrgsList = [];
    if (!this.application.coopMedOrgsWishList) this.application.coopMedOrgsWishList = [];
    if (!this.application.organizationalSupportMeasures) this.application.organizationalSupportMeasures = [];
    if (!this.application.financialSupportMeasures) this.application.financialSupportMeasures = [];


    if (this.application?.organizationalSupportMeasures.length) {
      this.application.organizationalSupportMeasures = this.application.organizationalSupportMeasures.split('#');
    }

    if (this.application.financialSupportMeasures.length) {
      this.application.financialSupportMeasures = this.application.financialSupportMeasures.split('#');
    }

    if (this.application.coopMedOrgsList.length) {
      this.application.coopMedOrgsList = this.application.coopMedOrgsList.split('#');
    }

    if (this.application.coopMedOrgsWishList.length) {
      this.application.coopMedOrgsWishList = this.application.coopMedOrgsWishList.split('#');
    }

    //#organizationalSupportMeasures
    //#financialSupportMeasures
    // if (this.application?.coopMedOrgsList[0] === '') {
    //   this.application.coopMedOrgsList = [];
    // }
    // if (this.application?.coopMedOrgsWishList[0] === '') {
    //   this.application.coopMedOrgsWishList = [];
    // }

    this.placeAgreementChecks();
    if (this.application.contraindicationsForUse === 'Не установлены' || this.application.contraindicationsForUse.length === 0) {
      this.checks.contraindicationsForUse = true;
    }
    if (this.application.comparisonGroup === 'Отсутствуют' || this.application.comparisonGroup.length === 0) {
      this.checks.comparisonGroup = true;
    }
    if (this.application.availabilityOfAnalogues === 'Отсутствуют' || this.application.availabilityOfAnalogues.length === 0) {
      this.checks.availabilityOfAnalogues = true;
    }

  },
  mounted() {
  },
  unmounted() {
    this.$store.commit('clearCurrentApplication');
  },
  computed: {
    agreementsScreenIsValid() {
      if (!this.checks.applyRequestMeasures) return false;
      if (!this.checks.checkbox4) return false;
      if (!this.checks.checkbox5) return false;
      if (!this.checks.checkbox6) return false;
      if (!this.checks.checkbox7) return false;
      if (!this.checks.switch1 && !this.checks.switch2) return false;
      if (this.checks.switch1 && !this.application?.organizationalSupportMeasures.length) return false;
      if (this.checks.switch2 && !this.application.financialSupportMeasures.length) return false;
      return true;
    },
    addFilesCaptionShown() {

      const chunk = this.filedFields;
      let checkObject = JSON.parse(JSON.stringify(chunk));
      if (this.application.financialSupportMeasures.length) {
        delete checkObject['file_smeta'];
      }

      const allFilled = Object.keys(checkObject).some(of => {
        !this.application[of];
      });

      const statusAllows = ([0, 2, 7].includes(this.application.status));

      return (allFilled && statusAllows)

    },
    isAdmPage() {
      return (this.$route.name === 'admin-applications-view');
    },
    dictionaries() {
      return dictionaries;
    },
    forbiddenToEdit() {
      if (this.application.archive) return true;
      if (this.isAdmPage) return true;
      return ![0, 2, 6].includes(+this.application.status) || false;
    },
    lastLog() {
      if (this.application.log && this.application.log.length) {
        return this.application.log[this.application.log.length - 1];
      }
      return false
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      sendAction: false,
      sendType: '',
      openedLogId: '',
      logMessageText: '',
      showDocumentsTab: false,
      showUpworkPanel: false,
      deleteConformationShown: false,
      sendConformationShown: false,
      checks: {
        contraindicationsForUse: false,
        comparisonGroup: false,
        availabilityOfAnalogues: false,
        applyRequestMeasures: false,
        switch1: false,
        switch2: false,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
      },
      filedFields: {
        file_articlesOfAssociation: 'Устав организации*',
        file_certificateOfState: 'Свидетельство о государственной регистрации*',
        file_certificateOfTax: 'Свидетельство о постановке на учет в налоговом органе*',
        file_decisionOfAppointment: 'Решение и приказ о назначении на должность руководителя*',
        file_proxyToSign: 'Доверенность на подписанта соглашения',
        file_researchPermission: 'Разрешение на проведение клинического исследования, выданное уполномоченным федеральным органом исполнительной власти*',
        file_researchDesign: 'Дизайн клинического исследования и (или) брошюра исследователя*',
        file_agreementDraft: 'Проект договора о проведении клинического исследования лекарственного препарата для медицинского применения, планируемый к заключению с городской медицинской организацией,*',
        file_smeta: 'Смета расходов на проведение клинического исследования*',
        file_additional: 'Доп. файлы',
      },
      application: {
        log: [
        ],
        id: '',
        userId: '',
        companyId: '',
        status: 0,
        /*1 SCREEN ----------------------*/
        name: '',//Наименование лекарственного препарата*
        internationalName: '',//Международное непатентованное наименование*
        form: '',//Форма выпуска*
        dose: '',//Дозировка*
        indicationsForUse: '',//Показания к применению*
        contraindicationsForUse: '',//Противопоказания к применению (при наличии)*
        /*2 SCREEN ----------------------*/
        therapeuticArea: '', //Терапевтическая область*
        researchPhase: '', //Фаза клинического исследования*
        permissionDetails: '', //Реквизиты (дата и номер) разрешения на проведение клинического исследования, выданного уполномоченным федеральным органом исполнительной власти*
        numberOfPatients: '', //Число пациентов*
        durationOfParticipation: '', //Длительность участия пациентов в исследовании*
        purposeOfResearch: '', //Цель исследования*
        goalsOfResearch: '', //Задачи исследования*
        /*3 SCREEN ----------------------*/
        selectionAndExclusion: '', //Отбор и исключение субъектов исследования (критерии включения, критерии не включения, критерии исключения субъектов)*
        comparisonGroup: '', //Наличие группы сравнения (для плацебо и препарата сравнения)*
        coopMedOrgsAlreadyWord: false, //Уже работаем с городскими медицинскими организациями (
        coopMedOrgsWantToCoop: false, //Хотим работать с конкретными городскими медицинскими организациями
        coopMedOrgsHelpToCoop: false, //O просим оказать помощь в подборе городской медицинской организации
        coopMedOrgsList: [], //Список медорганизаций
        coopMedOrgsWishList: [], //Список медорганизаций
        relevanceOfDrug: '', //Актуальность лекарственного препарата для столичного здравоохранения*
        availabilityOfAnalogues: '', //Наличие аналогичных отечественных и (или) зарубежных лекарственных препаратов (указать перечень)*
        potentialEfficiency: '', //Потенциальная медико-экономическая эффективность лекарственного препарата*
        /*4 SCREEN ----------------------*/
        organizationalSupportMeasures: [],
        financialSupportMeasures: [],
        organizationalSupportMeasuresStatus: 0,
        financialSupportMeasuresStatus: 0,
        /*5 SCREEN FILES----------------------*/
        file_articlesOfAssociation: undefined, //Устав организации
        file_certificateOfState: undefined, //Свидетельство о государственной регистрации
        file_certificateOfTax: undefined, //Свидетельство о постановке на учет в налоговом органе
        file_decisionOfAppointment: undefined, //Решение и приказ о назначении на должность руководителя
        file_proxyToSign: undefined, //Доверенность на подписанта соглашения
        file_researchPermission: undefined, //Разрешение на проведение клинического исследования, выданное уполномоченным федеральным органом исполнительной власти
        file_researchDesign: undefined, //Дизайн клинического исследования и (или) брошюра исследователя
        file_agreementDraft: undefined, //Проект договора о проведении клинического исследования лекарственного препарата для медицинского применения, планируемый к заключению с городской медицинской организацией,
        file_smeta: undefined, //Проект договора о проведении клинического исследования лекарственного препарата для медицинского применения, планируемый к заключению с городской медицинской организацией,
        file_additional: undefined
      }
    }
  },
  watch: {
    // 'application.coopMedOrgsAlreadyWord'(v){
    //   if (!v) return;
    //   this.application.coopMedOrgsWantToCoop = false;
    //   this.application.coopMedOrgsHelpToCoop = false;
    // },
    // 'application.coopMedOrgsWantToCoop'(v){
    //   if (!v) return;
    //   this.application.coopMedOrgsAlreadyWord = false;
    //   this.application.coopMedOrgsHelpToCoop = false;
    // },
    // 'application.coopMedOrgsHelpToCoop'(v){
    //   if (!v) return;
    //   this.application.coopMedOrgsAlreadyWord = false;
    //   this.application.coopMedOrgsWantToCoop = false;
    //   this.application.coopMedOrgsList = [];
    // },
    'checks.applyRequestMeasures'(v) {
      if (!v) {
        this.checks.switch1 = false;
        this.checks.switch2 = false;
        this.application.organizationalSupportMeasures = [];
        this.application.financialSupportMeasures = [];
      }
    },
    'checks.contraindicationsForUse'(v) {
      this.application.contraindicationsForUse = v ? 'Не установлены' : '';
    },
    'checks.comparisonGroup'(v) {
      this.application.comparisonGroup = v ? 'Отсутствуют' : '';
    },
    'checks.availabilityOfAnalogues'(v) {
      this.application.availabilityOfAnalogues = v ? 'Отсутствуют' : '';
    },
    //Снимаем галочку "Возмещение части затрат"
    'application.coopMedOrgsAlreadyWord'(v) {
      if (!v) {
        this.checks.switch2 = false;
        this.application.financialSupportMeasure = [];
      }
    }
  },
  methods: {
    getLogStatusElement(log, prevLog = false) {

      const orgStatus = log?.organizationalSupportMeasuresStatus || 0;
      const finStatus = log?.financialSupportMeasuresStatus || 0;

      const result = {
        html: '',
        class: ''
      }

      const classesMap = {
        0: '',
        1: 'cabinet__stageitem--pending',
        2: 'cabinet__stageitem--red',
        3: 'cabinet__stageitem--done',
        4: 'cabinet__stageitem--decline',
      }
      classesMap;
      finStatus;

      //debugger;

      let orgStatusChanged = false;
      let finStatusChanged = false;
      let sameStatuses = false;

      if (log?.organizationalSupportMeasuresStatus === log?.financialSupportMeasuresStatus) {
        sameStatuses = true;
      }

      if (sameStatuses) {
        if (orgStatus === 0) {
          result.html = '<span>Черновик</span>';
          result.class = 0
        }
        if (orgStatus === 1) {
          result.html = '<span>Экспертиза заявки</span>';
          result.class = 1
        }
        if (orgStatus === 2) {
          result.html = '<span>Отправлена на доработку</span>';
          result.class = 2
        }
        if (orgStatus === 3) {
          result.html = '<span>Одобрена</span>';
          result.class = 3
        }
        if (orgStatus === 4) {
          result.html = '<span>Отклонена</span>';
          result.class = 4
        }
      }

      if (prevLog) {
        if (prevLog?.organizationalSupportMeasuresStatus !== log?.organizationalSupportMeasuresStatus) {
          orgStatusChanged = true;
        }
        if (prevLog?.financialSupportMeasuresStatus !== log?.financialSupportMeasuresStatus) {
          finStatusChanged = true;
        }
      }

      if (orgStatusChanged) {
        if (orgStatus === 0) {
          result.html = '<span>Черновик</span>';
          result.class = 0;
        }
        if (orgStatus === 1) {
          result.html = '<span>Экспертиза заявки</span>';
          result.class = 1;
        }
        if (orgStatus === 2) {
          result.html = '<span>Отправлена на доработку</span>';
          result.class = 2;
        }
        if (orgStatus === 3) {
          result.html = '<span>Организационные меры поддержки одобрены</span>';
          result.class = 3;
        }
        if (orgStatus === 4) {
          result.html = '<span>Организационные меры поддержки отклонены</span>';
          result.class = 4;
        }
      }

      if (finStatusChanged) {
        if (finStatus === 0) {
          result.html = '<span>Черновик</span>';
          result.class = 0;
        }
        if (finStatus === 1) {
          result.html = '<span>Экспертиза заявки</span>';
          result.class = 1;
        }
        if (finStatus === 2) {
          result.html = '<span>Отправлена на доработку</span>';
          result.class = 2;
        }
        if (finStatus === 3) {
          result.html = '<span>Возмещение части затрат одобрено</span>';
          result.class = 3;
        }
        if (finStatus === 4) {
          result.html = '<span>Возмещение части затрат отклонено</span>';
          result.class = 4;
        }
      }

      result.class = classesMap[result.class];
      return result;

    },
    getStatusHtmlElement(statusCode) {
      if (typeof statusCode !== 'number') return '';
      let txt = '';
      let className = '';
      switch (statusCode) {
        case 0:
          txt = 'Черновик';
          className = 'cabinet__status--draft'
          break;
        case 1:
          txt = 'Экспертиза заявки';
          className = 'cabinet__status--pending'
          break;
        case 2:
          txt = 'Отправлена на доработку';
          break;
        case 3:
          txt = 'Одобрено';
          className = 'cabinet__status--ok'
          break;
        case 4:
          txt = 'Отклонено';
          className = 'cabinet__status--cancel'
          break;
      }
      return `<p class="cabinet__status ${className}">${txt}</p>`
    },
    // deleteIsAllowed(){
    //   if (!this.application?.organizationalSupportMeasuresStatus
    //       && !this.application?.financialSupportMeasuresStatus) return true;
    //   return false;
    // },
    // toDraftIsAllowed(){
    //   if (!this.application?.organizationalSupportMeasuresStatus
    //       && !this.application?.financialSupportMeasuresStatus) return true;
    //   return false;
    // },
    placeAgreementChecks() {
      /* Т.к. мы галочки на согласие не храним в api приходится вот таким методом грязным методом их расставлять */
      if (this.application?.organizationalSupportMeasures.length !== 0) {
        this.checks.applyRequestMeasures = true;
        this.checks.switch1 = true;
      }
      if (this.application.financialSupportMeasures.length !== 0) {
        this.checks.applyRequestMeasures = true;
        this.checks.switch2 = true;
      }
      if (this.application.status === 0) return;
      this.checks.applyRequestMeasures = true;
      this.checks.checkbox4 = true;
      this.checks.checkbox5 = true;
      this.checks.checkbox6 = true;
    },
    tryConformation() {
      this.v$.application.$touch();
      if (!this.v$.application.$errors.length) {
        this.sendConformationShown = true;
      }
    },
    checkFileValidationObject: (v) => {
      if (typeof v === 'object') return true
      return false;
    },
    denySave() {
      Fancybox.getInstance().close;
    },
    confirmSave() {
      Fancybox.getInstance().close;
    },
    getStatusClass(status) {
      const classes = {
        0: '',
        1: 'cabinet__stageitem--pending',
        2: 'cabinet__stageitem--red',
        3: 'cabinet__stageitem--decline',
        4: 'cabinet__stageitem--done',
        5: 'cabinet__stageitem--pending',
        6: 'cabinet__stageitem--red',
        7: 'cabinet__stageitem--decline',
        8: 'cabinet__stageitem--done',
      }
      return classes[status];
    },
    handleRemoveFromArray(field, file) {
      this.application[field] = this.application[field].filter(el => {
        if (file.originalFileName) {
          return el.originalFileName !== file.originalFileName;
        }

        return el.name !== file.name
      })
    },
    loadFile($event, targetField, multiple) {
      const files = [...$event.target.files];

      if (multiple) {
        const oldFiles = this.application[targetField] || []
        this.application[targetField] = oldFiles.concat(files)
        return
      }

      this.application[targetField] = files;
    },
    async send() {
      if (this.sendAction === 'approve') this.sendApprove();
      if (this.sendAction === 'rework') this.sendRework();
      if (this.sendAction === 'reject') this.sendReject();
    },
    async sendApprove(type) {

      if (type === 'org') {
        this.application.organizationalSupportMeasuresStatus = 3;
      }
      if (type === 'fin') {
        this.application.financialSupportMeasuresStatus = 3;
      }

      const result = await this.$store.dispatch('approveApplication', {
        id: this.$route.params.id || false,
        comment: this.logMessageText,
        organizationalSupportMeasuresStatus: this.application.organizationalSupportMeasuresStatus,
        financialSupportMeasuresStatus: this.application.financialSupportMeasuresStatus,
      });
      if (result) {
        this.$router.push('/admin/applications');
      }
    },
    async sendRework() {
      const { status } = this.application;
      const newStatusId = status < 4 ? 2 : 6;

      const organizationalSupportMeasuresStatus = (this.application.organizationalSupportMeasuresStatus === 0) ? 0 : 2;
      const financialSupportMeasuresStatus = (this.application.financialSupportMeasuresStatus === 0) ? 0 : 2;

      const result = await this.$store.dispatch('reworkApplication', {
        id: this.$route.params.id || false,
        comment: this.logMessageText,
        newStatusId,
        organizationalSupportMeasuresStatus,
        financialSupportMeasuresStatus,
      });
      if (result) {
        this.$router.push('/admin/applications');
      }
    },
    async sendReject() {
      const { status } = this.application;
      const newStatusId = status < 4 ? 3 : 7;

      //this.sendType

      if (this.sendType === 'org') {
        this.application.organizationalSupportMeasuresStatus = 4;
      }
      if (this.sendType === 'fin') {
        this.application.financialSupportMeasuresStatus = 4;
      }

      const result = await this.$store.dispatch('rejectApplication', {
        id: this.$route.params.id || false,
        comment: this.logMessageText,
        newStatusId,
        organizationalSupportMeasuresStatus: this.application.organizationalSupportMeasuresStatus,
        financialSupportMeasuresStatus: this.application.financialSupportMeasuresStatus,
      });
      if (result) {
        this.$router.push('/admin/applications');
      }
    },
    async sendDelete() {
      const result = await this.$store.dispatch('deleteApplication', this.$route.params.id || false)
      if (result) {
        this.$router.push('/applications');
      }
    },
    async sendEdit(draft = false) {

      const fieldsObject = this.prepareApplicationPayload(draft);

      if (!draft) {
        if ([0, 2].includes(this.application.organizationalSupportMeasuresStatus)
          && this.application.organizationalSupportMeasures.length
        ) {
          fieldsObject.organizationalSupportMeasuresStatus = 1;
        }
        if ([0, 2].includes(this.application.financialSupportMeasuresStatus)
          && this.application.financialSupportMeasures.length
        ) {
          fieldsObject.financialSupportMeasuresStatus = 1;
        }
      }


      const result = await this.$store.dispatch('editApplication', { fieldsObject })

      if (result) {
        this.$router.push('/applications');
      }

    },
    prepareApplicationPayload(draft = false) {
      //prepareUser;
      const formPayload = this.application;
      const merged = {
        ...formPayload,
      };

      if (draft) {
        this.application.organizationalSupportMeasuresStatus = 0;
        this.application.financialSupportMeasuresStatus = 0;
      }

      merged.id = this.$route.params.id;
      merged.userId = this.$store.getters.getUserId;
      merged.isDraft = !!draft;

      if (merged?.companyId?.id) {
        merged.companyId = merged?.companyId?.id;
      }

      delete merged.number;
      delete merged.status;
      delete merged.log;
      return merged;
    }
  },
  validations() {
    return {
      application: {
        "name": {
          required,
          max: maxLength(250)
        },//Наименование лекарственного препарата*
        "internationalName": {
          required,
          max: maxLength(250)
        },//Международное непатентованное наименование*
        "form": { required },//Форма выпуска*
        "dose": { required },//Дозировка*
        "indicationsForUse": { required },//Показания к применению*
        "contraindicationsForUse": { test: (v) => (v.length > 3 || this.checks.contraindicationsForUse) },//Противопоказания к применению (при наличии)*
        "therapeuticArea": { required }, //Терапевтическая область*
        "researchPhase": { required }, //Фаза клинического исследования*
        "permissionDetails": { required }, //Реквизиты (дата и номер) разрешения на проведение клинического исследования, выданного уполномоченным федеральным органом исполнительной власти*
        "numberOfPatients": { required }, //Число пациентов*
        "durationOfParticipation": { required }, //Длительность участия пациентов в исследовании*
        "purposeOfResearch": { required }, //Цель исследования*
        "goalsOfResearch": { required }, //Задачи исследования*
        "selectionAndExclusion": { required }, //Отбор и исключение субъектов исследования (критерии включения, критерии не включения, критерии исключения субъектов)*
        "comparisonGroup": { test: (v) => (v.length > 3 || this.checks.comparisonGroup) }, //Наличие группы сравнения (для плацебо и препарата сравнения)*
        "relevanceOfDrug": { required }, //Актуальность лекарственного препарата для столичного здравоохранения*
        "availabilityOfAnalogues": { test: (v) => (v.length > 3 || this.checks.availabilityOfAnalogues) }, //Наличие аналогичных отечественных и (или) зарубежных лекарственных препаратов (указать перечень)*
        "potentialEfficiency": { required }, //Потенциальная медико-экономическая эффективность лекарственного препарата*          "organizationalSupportMeasures": {required},

        "coopMedOrgsAlreadyWord": {
          test: (v) => {
            if (this.application.coopMedOrgsHelpToCoop) return true;
            if (this.application.coopMedOrgsWantToCoop) return true;
            //if (this.application.coopMedOrgsWantToCoop) return true;
            if (v && this.application.coopMedOrgsList.length) return true;
            return false;
          }
        },
        "coopMedOrgsWantToCoop": {
          test: (v) => {
            if (this.application.coopMedOrgsHelpToCoop) return true;
            if (this.application.coopMedOrgsAlreadyWord) return true;
            if (v && this.application.coopMedOrgsWishList.length) return true;

            /*            if (this.application.coopMedOrgsWishList.length && (
                            this.application.coopMedOrgsAlreadyWord || this.application.coopMedOrgsWantToCoop
                        )) return true;*/

            return false;
          }
        },
        "coopMedOrgsHelpToCoop": {
          test: (v) => {
            if (v) return true;
            if (this.application.coopMedOrgsAlreadyWord || this.application.coopMedOrgsWantToCoop) return true;
            return false;
          }
        },
        "coopMedOrgsList": {
          test: (v) => {
            if (!v.length && (
              this.application.coopMedOrgsAlreadyWord || this.application.coopMedOrgsWantToCoop
            )) return false;
            return true;

            /*            if (this.application.coopMedOrgsHelpToCoop) return true;
                        if (v.length > 0 && (
                            this.application.coopMedOrgsAlreadyWord || this.application.coopMedOrgsWantToCoop
                        )) return true;
                        return false;*/

          }
        },
        "coopMedOrgsWishList": {},

        "organizationalSupportMeasures": {
          test: (v) => {
            return (v.length || this.application.financialSupportMeasures.length)
          }
        },
        "financialSupportMeasures": {
          test: (v) => {
            return (v.length || this.application.organizationalSupportMeasures.length)
          }
        },
        "file_articlesOfAssociation": { test: this.checkFileValidationObject },
        "file_certificateOfState": { test: this.checkFileValidationObject },
        "file_certificateOfTax": { test: this.checkFileValidationObject },
        "file_decisionOfAppointment": { test: this.checkFileValidationObject },
        "file_proxyToSign": {},
        "file_researchPermission": { test: this.checkFileValidationObject },
        "file_researchDesign": { test: this.checkFileValidationObject },
        "file_agreementDraft": { test: this.checkFileValidationObject },
        "file_smeta": {
          test: (v) => {
            if (!this.application.financialSupportMeasures.length) return true;
            if (typeof v === 'object') return true
            return false;
          }
        }, //Наличие группы сравнения (для плацебо и препарата сравнения)*
      }
    }
  }
}
</script>

<style scoped>
.form__field-error-panel {
  color: #EB5757;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 15px;
}

.cabinet__doc .cabinet__download {
  margin-right: 8px;
  padding-left: 8px;
}

.cabinet__application-submit-error {
  color: red;
  display: block;
  margin-left: auto;
  text-align: right;
  margin-top: 10px;
}

.cabinet__subtitle {
  margin-top: 8px;
}
</style>