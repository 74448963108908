<template>
  <template v-if="!$route.meta.hideNav">
    <template v-if="$route.path === '/'">
      <header class="header">
        <div class="wrapper">
          <button type="button" class="menu-btn" ></button>
          <div class="header__wrap">
            <div class="logo">
              <router-link to="/"><img src="@/assets/img/images/logo-1.svg" alt="Московский центр иновационных технологий в здравоохранении" width="288" height="60"></router-link>
              <img src="@/assets/img/images/logo-2.svg" alt="Комплекс социального развития Москвы" width="138" height="54">
            </div>
            <nav class="menu">
              <ul class="menu__list" >
                <li class="menu__item">
                  <a href="#about" class="menu__link" v-smooth-scroll>о программе</a>
                </li>
                <li class="menu__item">
                  <a href="#measures" class="menu__link" v-smooth-scroll>меры поддержки</a>
                </li>
                <li class="menu__item">
                  <a href="#requirements" class="menu__link" v-smooth-scroll>требования</a>
                </li>
                <li class="menu__item">
                  <a href="#criteria" class="menu__link" v-smooth-scroll>критерии</a>
                </li>
                <li class="menu__item">
                  <a href="#documents" class="menu__link" v-smooth-scroll>документы</a>
                </li>
              </ul>
            </nav>
            <div class="header__login">
              <router-link v-if="!$store.getters.isAuthorized" to="/login" class="header__btn">вход</router-link>
              <router-link v-else-if="$store.getters.isAdmin" to="/admin/applications" class="header__btn header__btn--filled">Список заявок</router-link>
              <router-link v-else to="/applications" class="header__btn header__btn--filled">мои заявки</router-link>
            </div>
            <div class="header__contacts">
              <a href="mailto:trials-support@zdrav.mos.ru">trials-support@zdrav.mos.ru</a>
            </div>
          </div>
        </div>
      </header>
    </template>
    <template v-else>
      <header class="header header--cabinet">
        <div class="wrapper">
          <div class="header__wrap">

            <router-link class="header__logo header__logo--small" to="/">
              <img src="@/assets/img/images/logo.svg" alt="Московский центр иновационных технологий в здравоохранении" width="272" height="48">
            </router-link>
            <router-link to="/" class="header__back">Вернуться на главную</router-link>

            <a class="header__tech" href="mailto: trials-support@zdrav.mos.ru">тех.поддержка: trials-support@zdrav.mos.ru</a>

            <div class="header__login">
<!--              <a href="#" class="header__notification header__notification&#45;&#45;active"></a>-->
              <a href="#" class="header__notification"></a>
              <router-link to="/profile" class="header__user">{{$store.getters.getName}}</router-link>
              <button type="button" @click="profileMenuOpened = !profileMenuOpened" class="header__down" :class="{active:profileMenuOpened}"></button>
              <div class="header__user-menu" :class="{active:profileMenuOpened}">
                <ul v-if="!$store.getters.isAdmin">
                  <li><router-link class="header__user-link" @click="profileMenuOpened = false" to="/applications">Заявки</router-link></li>
                  <li><router-link class="header__user-link" @click="profileMenuOpened = false" to="/profile">Профиль</router-link></li>
                  <li><router-link class="header__user-link" @click="profileMenuOpened = false" to="/logout">Выход</router-link></li>
                </ul>
                <ul v-else>
                  <li><router-link class="header__user-link" @click="profileMenuOpened = false" to="/admin/applications">Заявки</router-link></li>
                  <li><router-link class="header__user-link" @click="profileMenuOpened = false" to="/logout">Выход</router-link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </template>
  </template>
</template>

<script>
export default {
  name: "HeaderLayout",
  computed: {
  },
  data(){
    return{
      mobileMenuOpened: false,
      profileMenuOpened: false,
    }
  },
  mounted() {
    const $ = window.jQuery;
    $('.menu-btn').click(function() {
      $('.header__wrap').slideToggle();
      $(this).toggleClass('menu-btn--close');
    });
    $('.menu__item').click(function() {
      if (window.innerWidth <= 1200) {
        $('.header__wrap').slideToggle();
        $('.menu-btn').toggleClass('menu-btn--close');
      }
    });
  }
}
</script>

<style lang="scss">
  .header__tech {
    font-size: 16px;
    line-height: 24px;
    margin-right: 24px;
    @media screen and (max-width: 1000px) {
      display: none;
      order: 4;
    }
  }
  .header__down.active {
    transform: rotate(180deg);
  }
  .header__login {
    position: relative;
  }
  .header__user-menu {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    background: #fff;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 4px 32px rgba(0, 34, 89, 0.1);
    &.active{
      display: block;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }
    li {
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .header__user-link {
    color: #094DF1;
    &:hover, &:active {
      border-bottom: 1px solid;
      text-decoration: none;
      //color: #4F4F4F;
    }
  }
</style>