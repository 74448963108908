<template>
  <div class="login">
    <router-link to="/" class="login__logo">
      <img src="@/assets/img/images/logo.png" alt="Московский центр иновационных технологий в здравоохранении" width="318" height="80">
    </router-link>
    <p class="login__title">Вход и авторизация на сервис осуществляется через портал <span>mos.ru</span></p>
    <a href="https://login.mos.ru/sps/oauth/ae?client_id=broker.medtech.moscow&response_type=code%20&scope=openid profile usr_grps&access_type=offline&state=342a2c0c-d9ef-4cd6-b328-b67d9baf6a7f%20&redirect_uri=https://trials.medtech.moscow/login/ok" class="btn login__btn">Войти через mos.ru</a>
    <template v-if="error">
      <p>Что-то пошло не так. Попробуйте еще раз</p>
    </template>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
export default {
  setup () {
    return { v$: useVuelidate() }
  },
  async created() {
    const {path, query} = this.$route;
    if (path === '/login/ok' && query?.code){
      this.$store.commit('logout');
      const result = await this.$store.dispatch('authenticate', query?.code);
      
      if (result){
        this.$store.dispatch('init');
        this.$router.push('/');
        return;
      }
      //handle error
      this.error = true;
    }
  },
  mounted() {
    this.v$.email.$touch();
  },
  data(){
    return {
      error: false,
      success: false,
      email: '',
    }
  },
  methods: {
    async reg(){
      const res = await this.$store.dispatch('subscribe', email)
      if (res){
        this.success = true;
      }
    }
  },
  validations() {
    return {
      email: {required, email}
    }
  }
}
</script>

<style scoped>
  .login__title-caption {
    font-size: 14px;
    line-height: 22px;
  }
</style>