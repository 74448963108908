<template>
  <div class="login">
      <router-link to="/" class="login__logo">
        <img src="@/assets/img/images/logo.png" alt="Московский центр иновационных технологий в здравоохранении" width="318" height="80">
      </router-link>
    <p class="login__title">Авторизация</p>
    <div class="form">
      <div class="form__fields">
        <div class="form__field">
          <label for="login" class="form__label">Логин</label>
          <input type="text" v-model="username" id="login" class="form__input" placeholder="Ваша электронная почта или номер телефона">
        </div>
        <div class="form__field">
          <label for="password" class="form__label">Ваш пароль</label>
          <input type="password" v-model="password" id="password" class="form__input" placeholder="Введите ваш пароль">
        </div>
<!--        <div class="form__line">
          <input type="checkbox" id="checkbox" class="form__checkbox">
          <label for="checkbox" class="form__checkbox-label">Запомнить меня</label>
          <a href="#" class="form__link">Восстановить пароль</a>
        </div>-->
      </div>
      <button @click.prevent="login" type="submit" class="btn form__btn" :disabled="!username || !password">Войти</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
    }
  },
  methods: {
    async login(){
      const payload = {
        username: this.username,
        password: this.password
      };
      const result = await this.$store.dispatch('login', payload);
      if (result) this.$router.push('/admin/applications');
    }
  }
}
</script>

<style scoped>

</style>