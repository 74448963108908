<template>
  <header-layout/>
  <main>
    <slot/>
  </main>
  <footer-layout/>
</template>
<script>
  import HeaderLayout from "@/components/layouts/HeaderLayout";
  import FooterLayout from "@/components/layouts/FooterLayout";
  export default {
    components: {FooterLayout, HeaderLayout},
  }
</script>