<template>
  <section v-if="step === 1" class="cabinet cabinet--line">
    <div class="wrapper">
      <div class="cabinet__wrap">
        <div class="cabinet__links">
          <router-link to="/" class="cabinet__back">Назад</router-link>
        </div>
        <div class="cabinet__order">
          <h1>Оформление новой заявки</h1>
          <p>Заявка на оказание поддержки при проведении клинического исследования лекарственного препарата для
            медицинского применения в медицинских организациях, подведомственных Департаменту здравоохранения города
            Москвы (далее – городская медицинская организация)</p>
          <div class="form__field form__field--error-custom-txt" data-text="Пожалуйста, выберите организацию из списка"
            :class="{ 'form__field--error': !formPayload.screen0.companyId }">
            <VueMultiselect placeholder="Выбрать из справочника" v-model="formPayload.screen0.companyId"
              :select-label="'Выбрать'" :selected-label="'Выбрано'" :deselect-label="``" :showLabels="false"
              track-by="name" label="name" :options="userOrganisations">
            </VueMultiselect>
          </div>
          <p>В соответствии с постановлением Правительства Москвы от 20 сентября 2022 г. N 2010-ПП "О внесении изменений в
            постановление Правительства Москвы от 19 октября 2021 г. N 1636-ПП" направляет заявку на оказание поддержки
            проведения клинического исследования лекарственного препарата для медицинского применения (далее – заявка,
            лекарственный препарат) на базе городской медицинской организации (все поля таблицы обязательные для
            заполнения)</p>
        </div>
        <div class="cabinet__btns">
          <a href="/uploads/instruction.pdf" class="cabinet__instruction" target="_blank">Инструкция по подаче заявки</a>
          <a href="#" class="btn cabinet__run" @click.prevent="step++" :disabled="!formPayload.screen0.companyId">Перейти
            к заполнению</a>
        </div>
      </div>
    </div>
  </section>
  <section v-if="step === 2" class="cabinet cabinet--line">
    <div class="wrapper">
      <div class="cabinet__wrap">
        <div class="cabinet__steps">
          <div class="cabinet__active cabinet__active--state1"></div>
        </div>
        <div class="cabinet__links">
          <a href="#" class="cabinet__back" @click.prevent="step--">Назад</a>
        </div>
        <form action="#" class="form">
          <div class="form__fields">
            <div class="form__field" :class="{ 'form__field--error': v$.formPayload.screen1.name.$errors.length }">
              <label for="name" class="form__label">Наименование лекарственного препарата*</label>
              <input type="text" v-model="v$.formPayload.screen1.name.$model" id="name" class="form__input" required="">
            </div>
            <div class="form__field"
              :class="{ 'form__field--error': v$.formPayload.screen1.internationalName.$errors.length }">
              <label for="name2" class="form__label">Международное непатентованное наименование*</label>
              <input type="text" v-model="v$.formPayload.screen1.internationalName.$model" id="name2" class="form__input"
                required="">
            </div>
            <div class="form__field" :class="{ 'form__field--error': v$.formPayload.screen1.form.$errors.length }">
              <label for="shape" class="form__label">Форма выпуска*</label>
              <input type="text" v-model="v$.formPayload.screen1.form.$model" id="shape" class="form__input" required="">
            </div>
            <div class="form__field" :class="{ 'form__field--error': v$.formPayload.screen1.dose.$errors.length }">
              <label for="dose" class="form__label">Дозировка*</label>
              <input type="text" v-model="v$.formPayload.screen1.dose.$model" id="dose" class="form__input" required="">
            </div>
            <div class="form__field"
              :class="{ 'form__field--error': v$.formPayload.screen1.indicationsForUse.$errors.length }">
              <label for="reason" class="form__label">Показания к применению*</label>
              <textarea v-model="v$.formPayload.screen1.indicationsForUse.$model" id="reason" class="form__text"
                required=""></textarea>
            </div>
            <div class="form__field"
              :class="{ 'form__field--error': v$.formPayload.screen1.contraindicationsForUse.$errors.length }">
              <label for="against" class="form__label">Противопоказания к применению (при наличии)</label>
              <textarea v-model="v$.formPayload.screen1.contraindicationsForUse.$model" id="against" class="form__text"
                :disabled="checks.contraindicationsForUse"></textarea>
            </div>
            <div class="form__field">
              <input type="checkbox" id="checkbox1" class="form__checkbox" v-model="checks.contraindicationsForUse"
                checked>
              <label for="checkbox1" class="form__checkbox-label">Не установлены</label>
            </div>
          </div>
          <div class="form__top">Поля со звёздочкой обязательны к заполнению</div>
          <div class="cabinet__btns">
            <a href="#" @click.prevent="sendApplication(true)" class="btn cabinet__save"
              :disabled="draftBtnDisabled">Сохранить в черновике</a>
            <a href="#" class="btn cabinet__next" @click.prevent="step++">
              <span>Далее</span>
              <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.9987 3.33333L8.8237 4.50833L13.4737 9.16666H3.33203V10.8333H13.4737L8.8237 15.4917L9.9987 16.6667L16.6654 9.99999L9.9987 3.33333Z">
                </path>
              </svg>
            </a>
          </div>
        </form>
      </div>
    </div>
  </section>
  <section v-if="step === 3" class="cabinet cabinet--line">
    <div class="wrapper">
      <div class="cabinet__wrap">
        <div class="cabinet__steps">
          <div class="cabinet__active cabinet__active--state2"></div>
        </div>
        <div class="cabinet__links">
          <a href="#" class="cabinet__back" @click.prevent="step--">Назад</a>
        </div>
        <form action="#" class="form">
          <div class="form__fields">
            <div class="form__field"
              :class="{ 'form__field--error': v$.formPayload.screen2.therapeuticArea.$errors.length }">
              <label for="area" class="form__label">Терапевтическая область*</label>
              <VueMultiselect v-model="formPayload.screen2.therapeuticArea" placeholder="Выбрать из справочника"
                :select-label="'Выбрать'" :selected-label="'Выбрано'" :deselect-label="``" :showLabels="false"
                :options="dictionaries.therapeuticArea">
              </VueMultiselect>
            </div>
            <div class="form__field"
              :class="{ 'form__field--error': v$.formPayload.screen2.researchPhase.$errors.length }">
              <label for="phase" class="form__label">Фаза клинического исследования (отметить в списке)*</label>
              <VueMultiselect v-model="formPayload.screen2.researchPhase" placeholder="Выбрать из справочника"
                :select-label="'Выбрать'" :selected-label="'Выбрано'" :deselect-label="``" :multiple="true"
                :showLabels="false" :options="dictionaries.researchPhase">
              </VueMultiselect>
            </div>
            <div class="form__field"
              :class="{ 'form__field--error': v$.formPayload.screen2.permissionDetails.$errors.length }">
              <label for="req" class="form__label">Реквизиты (дата и номер) разрешения на проведение клинического
                исследования, выданного уполномоченным федеральным органом исполнительной власти*</label>
              <input v-model="v$.formPayload.screen2.permissionDetails.$model" type="text" id="req" class="form__input"
                required="">
            </div>
            <div class="form__field"
              :class="{ 'form__field--error': v$.formPayload.screen2.numberOfPatients.$errors.length }">
              <label for="number" class="form__label">Число пациентов - общее количество по РКИ, количество запланированных пациентов в центрах ДЗМ, количество рандомизированных пациентов в центрах ДЗМ*</label>
              <input v-model="v$.formPayload.screen2.numberOfPatients.$model" type="text" id="number" class="form__input"
                placeholder="Укажите количество пациентов" required="">
            </div>
            <div class="form__field"
              :class="{ 'form__field--error': v$.formPayload.screen2.durationOfParticipation.$errors.length }">
              <label for="period" class="form__label">Длительность участия пациентов в исследовании*</label>
              <input v-model="v$.formPayload.screen2.durationOfParticipation.$model" type="text" id="period"
                class="form__input" placeholder="Укажите длительность" required="">
            </div>
            <div class="form__field"
              :class="{ 'form__field--error': v$.formPayload.screen2.purposeOfResearch.$errors.length }">
              <label for="goal1" class="form__label">Цель исследования*</label>
              <textarea v-model="v$.formPayload.screen2.purposeOfResearch.$model" id="goal1" class="form__text"
                placeholder="Укажите цель исследования" required=""></textarea>
            </div>
            <div class="form__field"
              :class="{ 'form__field--error': v$.formPayload.screen2.goalsOfResearch.$errors.length }">
              <label for="task1" class="form__label">Задачи исследования*</label>
              <textarea v-model="v$.formPayload.screen2.goalsOfResearch.$model" id="task1" class="form__text"
                placeholder="Перечислите задачи исследования" required=""></textarea>
            </div>
          </div>
          <div class="cabinet__btns">
            <a href="#" @click.prevent="sendApplication(true)" class="btn cabinet__save"
              :disabled="draftBtnDisabled">Сохранить в черновике</a>
            <!--            <a href="#" class="btn cabinet__next" @click.prevent="nextIfValid('screen2')" :disabled="!!v$.formPayload.screen2.$errors.length">-->
            <a href="#" class="btn cabinet__next" @click.prevent="step++">
              <span>Далее</span>
              <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.9987 3.33333L8.8237 4.50833L13.4737 9.16666H3.33203V10.8333H13.4737L8.8237 15.4917L9.9987 16.6667L16.6654 9.99999L9.9987 3.33333Z">
                </path>
              </svg>
            </a>
          </div>
        </form>
      </div>
    </div>
  </section>
  <section v-if="step === 4" class="cabinet cabinet--line">
    <div class="wrapper">
      <div class="cabinet__wrap">
        <div class="cabinet__steps">
          <div class="cabinet__active cabinet__active--state3"></div>
        </div>
        <div class="cabinet__links">
          <a href="#" class="cabinet__back" @click.prevent="step--">Назад</a>
        </div>
        <div class="form">
          <div class="form__fields">
            <div class="form__field"
              :class="{ 'form__field--error': v$.formPayload.screen3.selectionAndExclusion.$errors.length }">
              <label for="criteria" class="form__label">Критерии включения, критерии не включения, критерии исключения
                субъектов*</label>
              <textarea v-model="v$.formPayload.screen3.selectionAndExclusion.$model" type="text" id="criteria"
                class="form__text"></textarea>
            </div>
            <div class="form__field"
              :class="{ 'form__field--error': v$.formPayload.screen3.comparisonGroup.$errors.length }">
              <label for="group" class="form__label">Наличие группы сравнения (для плацебо и препарата сравнения)*</label>
              <input v-model="v$.formPayload.screen3.comparisonGroup.$model" type="text" id="group" class="form__input"
                :disabled="checks.comparisonGroup">
            </div>
            <div class="form__field">
              <input type="checkbox" id="checkbox2" class="form__checkbox" v-model="checks.comparisonGroup">
              <label for="checkbox2" class="form__checkbox-label">Отсутствуют</label>
            </div>

            <div class="form__field">
              <label for="actual" class="form__label">
                Перечень городских медицинских организаций, на базе которых проводится (планируется проводить клиническое
                исследование)
              </label>
            </div>

            <div class="form__field">
              <input type="checkbox" id="coopMedOrgsAlreadyWord"
                v-model="v$.formPayload.screen3.coopMedOrgsAlreadyWord.$model" class="form__checkbox">
              <label for="coopMedOrgsAlreadyWord" class="form__checkbox-label">Уже работаем с городскими медицинскими
                организациями, по которым получено разрешение Минздрава России на проведении в них клинического
                исследования (указать)</label>
            </div>

            <!--  :class="{ 'form__field&#45;&#45;error' : v$.formPayload.screen3.coopMedOrgsWishList.$errors.length }"  -->
            <div v-if="formPayload.screen3.coopMedOrgsAlreadyWord"
              :class="{ 'form__field&#45;&#45;error': v$.formPayload.screen3.coopMedOrgsList.$errors.length }"
              class="form__field">
              <VueMultiselect v-model="v$.formPayload.screen3.coopMedOrgsList.$model" placeholder="Выбрать из справочника"
                :select-label="'Выбрать'" :selected-label="'Выбрано'" :deselect-label="``" :showLabels="false"
                :multiple="true" :options="dictionaries.medOrgs">
              </VueMultiselect>
            </div>

            <div class="form__field">
              <input type="checkbox" id="coopMedOrgsWantToCoop"
                v-model="v$.formPayload.screen3.coopMedOrgsWantToCoop.$model" class="form__checkbox">
              <label for="coopMedOrgsWantToCoop" class="form__checkbox-label">Хотим работать с конкретными городскими
                медицинскими организациями (указать)</label>
            </div>

            <div v-if="formPayload.screen3.coopMedOrgsWantToCoop"
              :class="{ 'form__field&#45;&#45;error': v$.formPayload.screen3.coopMedOrgsWishList.$errors.length }"
              class="form__field">
              <VueMultiselect v-model="v$.formPayload.screen3.coopMedOrgsWishList.$model"
                placeholder="Выбрать из справочника" :select-label="'Выбрать'" :selected-label="'Выбрано'"
                :deselect-label="``" :showLabels="false" :multiple="true" :options="dictionaries.medOrgs">
              </VueMultiselect>
            </div>

            <div class="form__field">
              <input type="checkbox" id="coopMedOrgsHelpToCoop"
                v-model="v$.formPayload.screen3.coopMedOrgsHelpToCoop.$model" class="form__checkbox">
              <label for="coopMedOrgsHelpToCoop" class="form__checkbox-label">Просим оказать помощь в подборе городской
                медицинской организации</label>
            </div>

            <!--            <template v-if="
              v$.formPayload.screen3.coopMedOrgsAlreadyWord.$errors.length ||
              v$.formPayload.screen3.coopMedOrgsAlreadyWord.$errors.length ||
              v$.formPayload.screen3.coopMedOrgsList.$errors.length ||
              v$.formPayload.screen3.coopMedOrgsHelpToCoop.$errors.length">
              <div class="form__field form__field&#45;&#45;custom-error">
                Для отправки заявки корректно заполните блок выше, либо установите опцию "Просим оказать помощь в подборе городской медицинской организации"
              </div>
            </template>-->

            <div class="form__field"
              :class="{ 'form__field--error': v$.formPayload.screen3.relevanceOfDrug.$errors.length }">
              <label for="actual" class="form__label">Актуальность лекарственного препарата для столичного
                здравоохранения*</label>
              <textarea v-model="v$.formPayload.screen3.relevanceOfDrug.$model" type="text" id="actual"
                class="form__text"></textarea>
            </div>

            <div class="form__field"
              :class="{ 'form__field--error': v$.formPayload.screen3.availabilityOfAnalogues.$errors.length }">
              <label for="analog" class="form__label">Наличие аналогичных отечественных и (или) зарубежных лекарственных
                препаратов (указать перечень)</label>
              <textarea v-model="v$.formPayload.screen3.availabilityOfAnalogues.$model" id="analog" class="form__text"
                :disabled="checks.availabilityOfAnalogues"></textarea>
            </div>
            <div class="form__field">
              <input type="checkbox" id="checkbox3" class="form__checkbox" v-model="checks.availabilityOfAnalogues">
              <label for="checkbox3" class="form__checkbox-label">Отсутствуют</label>
            </div>
            <div class="form__field"
              :class="{ 'form__field--error': v$.formPayload.screen3.potentialEfficiency.$errors.length }">
              <label for="effect" class="form__label">Потенциальная медико-экономическая эффективность лекарственного
                препарата*</label>
              <textarea v-model="v$.formPayload.screen3.potentialEfficiency.$model" id="effect" class="form__text"
                required=""></textarea>
            </div>

            <div class="form__field">
              <div class="form__label">Контактные данные заявителя</div>
            </div>

            <div class="form__field" :class="{ 'form__field--error': v$.formPayload.screen3.fio.$errors.length }">
              <label for="fio" class="form__label">ФИО*</label>
              <input v-model="v$.formPayload.screen3.fio.$model" id="fio" class="form__input" required="">
            </div>
            <div class="form__field" :class="{ 'form__field--error': v$.formPayload.screen3.job.$errors.length }">
              <label for="job" class="form__label">Должность*</label>
              <input v-model="v$.formPayload.screen3.job.$model" id="job" class="form__input" required="">
            </div>
            <div class="form__field" :class="{ 'form__field--error': v$.formPayload.screen3.phone.$errors.length }">
              <label for="phone" class="form__label">Телефон*</label>
              <input v-model="v$.formPayload.screen3.phone.$model" id="phone" class="form__input" required="">
            </div>
            <div class="form__field" :class="{ 'form__field--error': v$.formPayload.screen3.email.$errors.length }">
              <label for="email" class="form__label">Адрес электронной почты*</label>
              <input v-model="v$.formPayload.screen3.email.$model" id="email" class="form__input" required="">
            </div>
          </div>
          <div class="cabinet__btns">
            <a href="#" @click.prevent="sendApplication(true)" class="btn cabinet__save"
              :disabled="draftBtnDisabled">Сохранить в черновике</a>
            <a href="#" class="btn cabinet__next" @click.prevent="step++">
              <span>Далее</span>
              <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.9987 3.33333L8.8237 4.50833L13.4737 9.16666H3.33203V10.8333H13.4737L8.8237 15.4917L9.9987 16.6667L16.6654 9.99999L9.9987 3.33333Z">
                </path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section v-if="step === 5" class="cabinet cabinet--line">
    <div class="wrapper">
      <div class="cabinet__wrap">
        <div class="cabinet__steps">
          <div class="cabinet__active cabinet__active--state4"></div>
        </div>
        <div class="cabinet__links">
          <a href="#" class="cabinet__back" @click.prevent="step--">Назад</a>
        </div>
        <form action="#" class="form">
          <div class="form__fields">
            <p class="title-4">Меры поддержки</p>
            <input type="checkbox" id="checkbox01" class="form__checkbox" v-model="checks.applyRequestMeasures">
            <label for="checkbox01" class="form__checkbox-label">Подтверждаем, что запрашиваем меры поддержки в отношении
              клинического исследования, которое проводится (планируется проводить) на базе медицинских организаций
              системы Департамента здравоохранения города Москвы</label>
          </div>
          <div class="form__fields">
            <div class="form__field">
              <input type="checkbox" id="switch1" class="form__switcher" :disabled="!checks.applyRequestMeasures"
                v-model="checks.switch1">
              <label for="switch1" class="form__switcher-label">Организационные</label>
              <div class="form__dropdown">
                <VueMultiselect v-model="formPayload.screen4.organizationalSupportMeasures"
                  placeholder="Выбрать из справочника" :select-label="'Выбрать'" :selected-label="'Выбрано'"
                  :deselect-label="``" :showLabels="false" :multiple="true"
                  :options="dictionaries.organizationalSupportMeasures">
                </VueMultiselect>
              </div>
            </div>
            <div class="form__field">
              <input type="checkbox" id="switch2" class="form__switcher"
                :disabled="!checks.applyRequestMeasures || !formPayload.screen3.coopMedOrgsAlreadyWord"
                v-model="checks.switch2">
              <label for="switch2" class="form__switcher-label">Возмещение части затрат</label>
              <div class="form__caption--small"
                v-if="checks.applyRequestMeasures && !formPayload.screen3.coopMedOrgsAlreadyWord">
                Возможность выбора данной меры поддержки возможно только в случае проведения клинического исследования в
                городских медицинских
                организациях, по которым получено разрешение Минздрава России на проведение в них исследования (укажите их
                ранее)
              </div>
              <div class="form__dropdown">
                <VueMultiselect v-model="formPayload.screen4.financialSupportMeasures"
                  placeholder="Выбрать из справочника" :select-label="'Выбрать'" :selected-label="'Выбрано'"
                  :deselect-label="``" :showLabels="false" :multiple="true"
                  :options="dictionaries.financialSupportMeasures">
                </VueMultiselect>
              </div>
              <a v-if="checks.switch2" class="form__link form__link--smeta" href="/smeta.xlsx">Скачать шаблон сметы</a>
            </div>
          </div>
          <div class="form__fields">
            <p class="cabinet__subtitle">Подтверждаем</p>
            <div class="form__field">
              <input type="checkbox" id="checkbox4" class="form__checkbox" v-model="checks.checkbox4">
              <label for="checkbox4" class="form__checkbox-label">С порядком рассмотрения, отбора заявки ознакомлены и
                согласны</label>
            </div>
            <div class="form__field">
              <input type="checkbox" id="checkbox5" class="form__checkbox" v-model="checks.checkbox5">
              <label for="checkbox5" class="form__checkbox-label">Подлинность и достоверность предоставленных в составе
                заявки сведений и документов</label>
            </div>
            <div class="form__field">
              <input type="checkbox" id="checkbox6" class="form__checkbox" v-model="checks.checkbox6">
              <label for="checkbox6" class="form__checkbox-label">При подготовке заявки соблюдены авторские и иные права
                третьих лиц, а также иных правообладателей на представление материалов и их использование для проведения
                экспертизы</label>
            </div>
            <div class="form__field" v-if="checks.switch2">
              <input type="checkbox" id="checkbox7" class="form__checkbox" v-model="checks.checkbox7">
              <label for="checkbox7" class="form__checkbox-label">Согласны на передачу материалов заявки членам
                Клинического комитета (<a target="_blank" href="/docs/agreement.pdf">Согласие на передачу
                  информации</a>)</label>
            </div>
          </div>
          <div class="cabinet__btns">
            <a href="#" @click.prevent="sendApplication(true)" class="btn cabinet__save"
              :disabled="draftBtnDisabled">Сохранить в черновике</a>
            <a href="#" class="btn cabinet__next" @click.prevent="v$.formPayload.$touch(); step++;">
              <span>Далее</span>
              <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.9987 3.33333L8.8237 4.50833L13.4737 9.16666H3.33203V10.8333H13.4737L8.8237 15.4917L9.9987 16.6667L16.6654 9.99999L9.9987 3.33333Z">
                </path>
              </svg>
            </a>
          </div>
        </form>
      </div>
    </div>
  </section>
  <section v-if="step === 6" class="cabinet cabinet--line">
    <div class="wrapper">
      <div class="cabinet__wrap">
        <div class="cabinet__steps">
          <div class="cabinet__active cabinet__active--state5"></div>
        </div>
        <div class="cabinet__links">
          <a href="#" class="cabinet__back" @click.prevent="step--">Назад</a>
        </div>
        <div class="cabinet__docs">
          <p class="cabinet__subtitle">Загрузите, пожалуйста, необходимые документы</p>
          <ul class="cabinet__list">

            <li class="cabinet__item" v-if="formPayload.screen5.file_articlesOfAssociation?.length">
              <a href="#" v-for="file in formPayload.screen5.file_articlesOfAssociation" :key="file.name" @click.prevent="formPayload.screen5.file_articlesOfAssociation = []" class="cabinet__download">
                {{ file.name }}
                <span class="cabinet__delete--new">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                    data-v-3390f49a="">
                    <path d="M1 1L7 7M7 7L13 1M7 7L13 13M7 7L1 13" stroke-width="2" data-v-3390f49a=""></path>
                  </svg>
                </span>
              </a>
              <p class="cabinet__filename">Устав организации</p>

            </li>
            <li class="cabinet__item" v-if="formPayload.screen5.file_certificateOfState?.length">
              <a href="#" v-for="file in formPayload.screen5.file_certificateOfState" :key="file.name" @click.prevent="formPayload.screen5.file_certificateOfState = []" class="cabinet__download">
                {{ file.name }}
                <span class="cabinet__delete--new">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                    data-v-3390f49a="">
                    <path d="M1 1L7 7M7 7L13 1M7 7L13 13M7 7L1 13" stroke-width="2" data-v-3390f49a=""></path>
                  </svg>
                </span>
              </a>
              <p class="cabinet__filename">Свидетельство о государственной регистрации</p>
            </li>
            <li class="cabinet__item" v-if="formPayload.screen5.file_certificateOfTax?.length">
              <a href="#" v-for="file in formPayload.screen5.file_certificateOfTax" :key="file.name" @click.prevent="formPayload.screen5.file_certificateOfTax = []" class="cabinet__download">
                {{ file.name }}
                <span class="cabinet__delete--new">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                    data-v-3390f49a="">
                    <path d="M1 1L7 7M7 7L13 1M7 7L13 13M7 7L1 13" stroke-width="2" data-v-3390f49a=""></path>
                  </svg>
                </span>
              </a>
              <p class="cabinet__filename">Свидетельство о постановке на учет в налоговом органе</p>
            </li>
            <li class="cabinet__item" v-if="formPayload.screen5.file_decisionOfAppointment?.length">
              <a href="#" v-for="file in formPayload.screen5.file_decisionOfAppointment" :key="file.name" @click.prevent="formPayload.screen5.file_decisionOfAppointment = []" class="cabinet__download">
                {{ file.name }}
                <span class="cabinet__delete--new">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                    data-v-3390f49a="">
                    <path d="M1 1L7 7M7 7L13 1M7 7L13 13M7 7L1 13" stroke-width="2" data-v-3390f49a=""></path>
                  </svg>
                </span>
              </a>
              <p class="cabinet__filename">Решение и приказ о назначении на должность руководителя</p>
            </li>
            <li class="cabinet__item" v-if="formPayload.screen5.file_proxyToSign?.length">
              <a href="#" v-for="file in formPayload.screen5.file_proxyToSign" :key="file.name" @click.prevent="formPayload.screen5.file_proxyToSign = []" class="cabinet__download">
                {{ file.name }}
                <span class="cabinet__delete--new">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                    data-v-3390f49a="">
                    <path d="M1 1L7 7M7 7L13 1M7 7L13 13M7 7L1 13" stroke-width="2" data-v-3390f49a=""></path>
                  </svg>
                </span>
              </a>
              <p class="cabinet__filename">Доверенность на подписанта соглашения</p>
            </li>
            <li class="cabinet__item" v-if="formPayload.screen5.file_researchPermission?.length">
              <a href="#" v-for="file in formPayload.screen5.file_researchPermission" :key="file.name" @click.prevent="formPayload.screen5.file_researchPermission = []" class="cabinet__download">
                {{ file.name }}
                <span class="cabinet__delete--new">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                    data-v-3390f49a="">
                    <path d="M1 1L7 7M7 7L13 1M7 7L13 13M7 7L1 13" stroke-width="2" data-v-3390f49a=""></path>
                  </svg>
                </span>
              </a>
              <p class="cabinet__filename">
                Разрешение на проведение клинического исследования, выданное уполномоченным
                федеральным органом исполнительной власти (в случае направления заявки на возмещение части затрат, в
                разрешении должны быть указаны медицинские организации государственной системы здравоохранения города
                Москвы)
              </p>
            </li>
            <li class="cabinet__item" v-if="formPayload.screen5.file_researchDesign?.length">
              <a href="#" v-for="file in formPayload.screen5.file_researchDesign" :key="file.name" @click.prevent="formPayload.screen5.file_researchDesign = []" class="cabinet__download">
                {{ file.name }}
                <span class="cabinet__delete--new">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                    data-v-3390f49a="">
                    <path d="M1 1L7 7M7 7L13 1M7 7L13 13M7 7L1 13" stroke-width="2" data-v-3390f49a=""></path>
                  </svg>
                </span>
              </a>
              <p class="cabinet__filename">Протокол клинического исследования</p>
            </li>
            <li class="cabinet__item" v-if="formPayload.screen5.file_agreementDraft?.length">
              <a href="#" v-for="file in formPayload.screen5.file_agreementDraft" :key="file.name" @click.prevent="formPayload.screen5.file_agreementDraft = []" class="cabinet__download">
                {{ file.name }}
                <span class="cabinet__delete--new">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                    data-v-3390f49a="">
                    <path d="M1 1L7 7M7 7L13 1M7 7L13 13M7 7L1 13" stroke-width="2" data-v-3390f49a=""></path>
                  </svg>
                </span>
              </a>
              <p class="cabinet__filename">Брошюра исследователя - в случае подачи заявки на финансовую поддержку</p>
            </li>
            <li class="cabinet__item" v-if="formPayload.screen5.file_smeta?.length">
              <a href="#" v-for="file in formPayload.screen5.file_smeta" :key="file.name" @click.prevent="formPayload.screen5.file_smeta = []" class="cabinet__download">
                {{ file.name }}
                <span class="cabinet__delete--new">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                    data-v-3390f49a="">
                    <path d="M1 1L7 7M7 7L13 1M7 7L13 13M7 7L1 13" stroke-width="2" data-v-3390f49a=""></path>
                  </svg>
                </span>
              </a>
              <p class="cabinet__filename">Смета всех расходов на проведение клинического исследования, которые возникли
                (могут возникнуть) в процессе проведения клинического исследования в городских медицинских организациях за
                подписью руководителя организации - в случае подачи заявки на финансовую поддержку</p>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                data-v-3390f49a="">
                <path d="M1 1L7 7M7 7L13 1M7 7L13 13M7 7L1 13" stroke-width="2" data-v-3390f49a=""></path>
              </svg>
            </li>
            <li class="cabinet__item" v-if="formPayload.screen5.file_additional?.length">
              <a href="#" v-for="file in formPayload.screen5.file_additional" :key="file.name"
                @click.prevent="removeFile(file.name, 'file_additional')" class="cabinet__download">
                {{ file.name }}
                <span class="cabinet__delete--new">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                    data-v-3390f49a="">
                    <path d="M1 1L7 7M7 7L13 1M7 7L13 13M7 7L1 13" stroke-width="2" data-v-3390f49a=""></path>
                  </svg>
                </span>
              </a>
              <p class="cabinet__filename">Доп. документы</p>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                data-v-3390f49a="">
                <path d="M1 1L7 7M7 7L13 1M7 7L13 13M7 7L1 13" stroke-width="2" data-v-3390f49a=""></path>
              </svg>
            </li>
          </ul>
          <p v-if="addFilesCaptionShown" class="cabinet__more">Необходимо добавить</p>
          <ul class="cabinet__list">
            <li class="cabinet__item" v-if="!formPayload.screen5.file_articlesOfAssociation">
              <input type="file" accept=".pdf,image/*" id="file1" class="cabinet__upload"
                @change="loadFile($event, 'file_articlesOfAssociation')">
              <label for="file1" class="cabinet__upload-file">Устав организации*</label>
            </li>
            <li class="cabinet__item" v-if="!formPayload.screen5.file_certificateOfState">
              <input type="file" accept=".pdf,image/*" id="file2" class="cabinet__upload"
                @change="loadFile($event, 'file_certificateOfState')">
              <label for="file2" class="cabinet__upload-file">Свидетельство о государственной регистрации*</label>
            </li>
            <li class="cabinet__item" v-if="!formPayload.screen5.file_certificateOfTax">
              <input type="file" accept=".pdf,image/*" id="file3" class="cabinet__upload"
                @change="loadFile($event, 'file_certificateOfTax')">
              <label for="file3" class="cabinet__upload-file">Свидетельство о постановке на учет в налоговом
                органе*</label>
            </li>
            <li class="cabinet__item" v-if="!formPayload.screen5.file_decisionOfAppointment">
              <input type="file" accept=".pdf,image/*" id="file4" class="cabinet__upload"
                @change="loadFile($event, 'file_decisionOfAppointment')">
              <label for="file4" class="cabinet__upload-file">Решение и приказ о назначении на должность
                руководителя*</label>
            </li>
            <li class="cabinet__item" v-if="!formPayload.screen5.file_proxyToSign">
              <input type="file" accept=".pdf,image/*" id="file5" class="cabinet__upload"
                @change="loadFile($event, 'file_proxyToSign')">
              <label for="file5" class="cabinet__upload-file">Доверенность на подписанта соглашения</label>
              <p class="cabinet__comment">Если соглашение подписывается по доверенности не руководителем организации</p>
            </li>
            <li class="cabinet__item" v-if="!formPayload.screen5.file_researchPermission">
              <input type="file" accept=".pdf,image/*" id="file6" class="cabinet__upload"
                @change="loadFile($event, 'file_researchPermission')">
              <label for="file6" class="cabinet__upload-file">Разрешение на проведение клинического исследования, выданное
                уполномоченным федеральным органом исполнительной власти (в случае направления заявки на возмещение части
                затрат, в разрешении должны быть указаны медицинские организации государственной системы здравоохранения
                города Москвы)*</label>
            </li>
            <li class="cabinet__item" v-if="!formPayload.screen5.file_researchDesign">
              <input type="file" accept=".pdf,image/*" id="file7" class="cabinet__upload"
                @change="loadFile($event, 'file_researchDesign')">
              <label for="file7" class="cabinet__upload-file">Протокол клинического исследования*</label>
            </li>
            <li class="cabinet__item" v-if="!formPayload.screen5.file_agreementDraft">
              <input type="file" accept=".pdf,image/*" id="file8" class="cabinet__upload"
                @change="loadFile($event, 'file_agreementDraft')">
              <label for="file8" class="cabinet__upload-file">Брошюра исследователя - в случае подачи заявки на финансовую
                поддержку*</label>
            </li>
            <li class="cabinet__item"
              v-if="!formPayload.screen5.file_smeta && formPayload.screen4.financialSupportMeasures.length">
              <input type="file" accept=".pdf,.xls,.xlsx,image/*" id="file9" class="cabinet__upload"
                @change="loadFile($event, 'file_smeta')">
              <label for="file9" class="cabinet__upload-file">Смета всех расходов на проведение клинического исследования,
                которые возникли (могут возникнуть) в процессе проведения клинического исследования в городских
                медицинских организациях за подписью руководителя организации - в случае подачи заявки на финансовую
                поддержку*</label>
            </li>

            <li class="cabinet__item">
              <input type="file" multiple accept=".pdf,.xls,.xlsx,image/*" id="file10" class="cabinet__upload"
                @change="loadFile($event, 'file_additional', true)">
              <label for="file10" class="cabinet__upload-file">Доп. документы</label>
            </li>
          </ul>
        </div>

        <template v-if="v$.formPayload.$errors.length">
          <div class="cabinet__error">
            Для отправки заявки должны быть заполнены все обязательные поля.
          </div>
        </template>
        <div class="cabinet__btns">
          <a href="#" @click.prevent="sendApplication(true)" class="btn cabinet__save"
            :disabled="draftBtnDisabled">Сохранить в черновике</a>
          <a href="#" @click.prevent="sendApplication(false)" class="btn cabinet__btn cabinet__send"
            :disabled="v$.formPayload.$errors.length || !agreementsScreenIsValid || sendBtnDisabled">Отправить заявку на
            рассмотрение</a>
        </div>
      </div>
    </div>
  </section>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<script>

import * as dictionaries from '@/misc/dictionaries.js';
import useVuelidate from '@vuelidate/core'
// eslint-disable-next-line
import { required, minLength, maxLength, sameAs, helpers } from '@vuelidate/validators'
import VueMultiselect from 'vue-multiselect'
export default {
  components: { VueMultiselect },
  setup() {
    return { v$: useVuelidate() }
  },
  mounted() {
  },
  computed: {
    userOrganisations() {
      let userOrgs = this.$store.getters.getUserOrganisations;
      if (!userOrgs.length && this.$store.getters.isAdmin) {
        //if (!result.length) {
        userOrgs = [
          {
            name: "ООО \"Супер фарма\"",
            ogrn: "600-600",
            id: "111",
            inn: "100-100-INN",
            //companyId: "111"
          },
        ]
      }
      if (!userOrgs.length && !this.$store.getters.isAdmin) {
        // eslint-disable-next-line
        this.$router.push('/profile');
        return;
      }
      return userOrgs;
    },
    dictionaries() {
      return dictionaries;
    },
    agreementsScreenIsValid() {
      if (!this.checks.applyRequestMeasures) return false;
      if (!this.checks.checkbox4) return false;
      if (!this.checks.checkbox5) return false;
      if (!this.checks.checkbox6) return false;
      if (!this.checks.switch1 && !this.checks.switch2) return false;
      if (this.checks.switch1 && !this.formPayload.screen4.organizationalSupportMeasures.length) return false;
      if (this.checks.switch2 && !this.formPayload.screen4.financialSupportMeasures.length) return false;
      if (this.checks.switch2 && !this.checks.checkbox7) return false;
      return true;
    },
    addFilesCaptionShown() {
      const chunk = this.formPayload.screen5;
      let checkObject = JSON.parse(JSON.stringify(chunk));
      if (!this.formPayload.screen4.financialSupportMeasures.length) {
        delete checkObject['file_smeta'];
      }
      return Object.values(checkObject).some(e => !e)
    }
  },
  data() {
    return {
      step: 1,
      draftBtnDisabled: false,
      sendBtnDisabled: false,
      checks: {
        contraindicationsForUse: false,
        comparisonGroup: false,
        availabilityOfAnalogues: false,
        applyRequestMeasures: false,
        switch1: false,
        switch2: false,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
      },
      formPayload: {
        screen0: {
          "userId": this.$store.getters.getUserId,
          "companyId": '',
        },
        screen1: {
          "name": '',//Наименование лекарственного препарата*
          "internationalName": '',//Международное непатентованное наименование*
          "form": '',//Форма выпуска*
          "dose": '',//Дозировка*
          "indicationsForUse": '',//Показания к применению*
          "contraindicationsForUse": '',//Противопоказания к применению (при наличии)*
        },
        screen2: {
          "therapeuticArea": '', //Терапевтическая область*
          "researchPhase": false, //Фаза клинического исследования*
          "permissionDetails": '', //Реквизиты (дата и номер) разрешения на проведение клинического исследования, выданного уполномоченным федеральным органом исполнительной власти*
          "numberOfPatients": '', //Число пациентов*
          "durationOfParticipation": '', //Длительность участия пациентов в исследовании*
          "purposeOfResearch": '', //Цель исследования*
          "goalsOfResearch": '' //Задачи исследования*
        },
        screen3: {
          "selectionAndExclusion": '', //Отбор и исключение субъектов исследования (критерии включения, критерии не включения, критерии исключения субъектов)*
          "comparisonGroup": '', //Наличие группы сравнения (для плацебо и препарата сравнения)*
          "coopMedOrgsAlreadyWord": false, //Уже работаем с городскими медицинскими организациями (
          "coopMedOrgsWantToCoop": false, //Хотим работать с конкретными городскими медицинскими организациями
          "coopMedOrgsHelpToCoop": false, //O просим оказать помощь в подборе городской медицинской организации
          "coopMedOrgsList": [], //Список медорганизаций
          "coopMedOrgsWishList": [], //Список медорганизаций
          "relevanceOfDrug": '', //Актуальность лекарственного препарата для столичного здравоохранения*
          "availabilityOfAnalogues": '', //Наличие аналогичных отечественных и (или) зарубежных лекарственных препаратов (указать перечень)*
          "potentialEfficiency": '', //Потенциальная медико-экономическая эффективность лекарственного препарата*
          'fio': '',
          'job': '',
          'phone': '',
          'email': '',
        },
        screen4: {
          "organizationalSupportMeasures": [],
          "organizationalSupportMeasuresStatus": 0,
          "financialSupportMeasures": [],
          "financialSupportMeasuresStatus": 0,
        },
        screen5: {
          "file_articlesOfAssociation": undefined, //Устав организации
          "file_certificateOfState": undefined, //Свидетельство о государственной регистрации
          "file_certificateOfTax": undefined, //Свидетельство о постановке на учет в налоговом органе
          "file_decisionOfAppointment": undefined, //Решение и приказ о назначении на должность руководителя
          "file_proxyToSign": undefined, //Доверенность на подписанта соглашения
          "file_researchPermission": undefined, //Разрешение на проведение клинического исследования, выданное уполномоченным федеральным органом исполнительной власти
          "file_researchDesign": undefined, //Дизайн клинического исследования и (или) брошюра исследователя
          "file_agreementDraft": undefined, //Проект договора о проведении клинического исследования лекарственного препарата для медицинского применения, планируемый к заключению с городской медицинской организацией,
          "file_smeta": undefined, //Smeta
          "file_additional": undefined,
        },
      },
    }
  },
  watch: {
    //'formPayload.screen3.coopMedOrgsAlreadyWord'(v){
    //  if (!v) return;
    //  this.formPayload.screen3.coopMedOrgsWantToCoop = false;
    //  this.formPayload.screen3.coopMedOrgsHelpToCoop = false;
    //},
    //'formPayload.screen3.coopMedOrgsWantToCoop'(v){
    //  if (!v) return;
    //  this.formPayload.screen3.coopMedOrgsAlreadyWord = false;
    //  this.formPayload.screen3.coopMedOrgsHelpToCoop = false;
    //},
    //'formPayload.screen3.coopMedOrgsHelpToCoop'(v){
    //  if (!v) return;
    //  this.formPayload.screen3.coopMedOrgsAlreadyWord = false;
    //  this.formPayload.screen3.coopMedOrgsWantToCoop = false;
    //  this.formPayload.screen3.coopMedOrgsList = [];
    //},
    'formPayload.screen4.organizationalSupportMeasures'(v) {
      if (v.length) {
        this.formPayload.screen4.organizationalSupportMeasuresStatus = 1;
        return;
      }
      this.formPayload.screen4.organizationalSupportMeasuresStatus = 0;
    },
    'formPayload.screen4.financialSupportMeasures'(v) {
      if (v.length) {
        this.formPayload.screen4.financialSupportMeasuresStatus = 1;
        return;
      }
      this.formPayload.screen4.financialSupportMeasuresStatus = 0;
    },
    'checks.applyRequestMeasures'(v) {
      if (!v) {
        this.checks.switch1 = false;
        this.checks.switch2 = false;
        this.formPayload.screen4.organizationalSupportMeasures = [];
        this.formPayload.screen4.financialSupportMeasures = [];
      }
    },
    'checks.contraindicationsForUse'(v) {
      this.formPayload.screen1.contraindicationsForUse = v ? 'Не установлены' : '';
    },
    'checks.comparisonGroup'(v) {
      this.formPayload.screen3.comparisonGroup = v ? 'Отсутствуют' : '';
    },
    'checks.availabilityOfAnalogues'(v) {
      this.formPayload.screen3.availabilityOfAnalogues = v ? 'Отсутствуют' : '';
    },
    //Снимаем галочку "Возмещение части затрат"
    'formPayload.screen3.coopMedOrgsAlreadyWord'(v) {
      if (!v) {
        this.checks.switch2 = false;
        this.formPayload.screen4.financialSupportMeasure = [];
      }
    }
  },
  methods: {
    nextIfValid(screen) {
      this.v$.formPayload[screen].$touch();
      if (!this.v$.formPayload[screen].$errors.length) {
        this.step++;
      }
    },
    removeFile(name, targetField) {
      this.formPayload.screen5[targetField] = this.formPayload.screen5[targetField].filter(el => el.name !== name)
    },
    loadFile($event, targetField, multiple) {
      this.v$.formPayload.screen5.$touch();
      
      const files = [...$event.target.files];

      if (multiple) {
        const oldFiles = this.formPayload.screen5[targetField] || []

        this.formPayload.screen5[targetField] = oldFiles.concat(files)
        return
      }

      this.formPayload.screen5[targetField] = files;
    },
    async sendApplication(draft = false) {
      const fieldsObject = this.prepareApplicationPayload();
      fieldsObject.isDraft = !!draft;
      if (draft) {
        this.draftBtnDisabled = true
        /* DROP STATUS 0 FOR DRAFT */
        fieldsObject.organizationalSupportMeasuresStatus = 0;
        fieldsObject.financialSupportMeasuresStatus = 0;
      }
      if (!draft) this.sendBtnDisabled = true;
      const result = await this.$store.dispatch('createApplication', { fieldsObject })
      if (result) {
        this.$router.push('/applications');
        return;
      }
      this.draftBtnDisabled = false;
      this.sendBtnDisabled = false;
    },
    prepareApplicationPayload() {
      //prepareUser;
      const formPayload = this.formPayload;
      const merged = {
        ...formPayload.screen0,
        ...formPayload.screen1,
        ...formPayload.screen2,
        ...formPayload.screen3,
        ...formPayload.screen4,
        ...formPayload.screen5,
      };
      /*DIRTY ONE*/
      if (merged?.companyId?.id) {
        merged.companyId = merged?.companyId?.id;
      }
      //merged.companyName  = merged?.companyId?.name;
      return merged
    }
  },
  validations() {
    return {
      formPayload: {
        screen0: {
          "companyId": { required },//Наименование лекарственного препарата**
        },
        screen1: {
          "name": {
            required,
            max: maxLength(250),
          },//Наименование лекарственного препарата*
          "internationalName": {
            required,
            max: maxLength(250),
          },//Международное непатентованное наименование*
          "form": { required },//Форма выпуска*
          "dose": { required },//Дозировка*
          "indicationsForUse": { required },//Показания к применению*
          "contraindicationsForUse": { test: (v) => (v.length > 0 || this.checks.contraindicationsForUse) },//Противопоказания к применению (при наличии)*
        },
        screen2: {
          "therapeuticArea": { required }, //Терапевтическая область*
          "researchPhase": {
            test: () => {
              if (this.formPayload.screen2.researchPhase.length) return true;
              return false;
            }
          }, //Фаза клинического исследования*
          "permissionDetails": { required }, //Реквизиты (дата и номер) разрешения на проведение клинического исследования, выданного уполномоченным федеральным органом исполнительной власти*
          "numberOfPatients": { required }, //Число пациентов*
          "durationOfParticipation": { required }, //Длительность участия пациентов в исследовании*
          "purposeOfResearch": { required }, //Цель исследования*
          "goalsOfResearch": { required } //Задачи исследования*
        },
        screen3: {
          "selectionAndExclusion": { required }, //Отбор и исключение субъектов исследования (критерии включения, критерии не включения, критерии исключения субъектов)*
          "comparisonGroup": { test: (v) => (v.length > 0 || this.checks.comparisonGroup) }, //Наличие группы сравнения (для плацебо и препарата сравнения)*
          "relevanceOfDrug": { required }, //Актуальность лекарственного препарата для столичного здравоохранения*
          "availabilityOfAnalogues": { test: (v) => (v.length > 0 || this.checks.availabilityOfAnalogues) }, //Наличие аналогичных отечественных и (или) зарубежных лекарственных препаратов (указать перечень)*
          "potentialEfficiency": { required }, //Потенциальная медико-экономическая эффективность лекарственного препарата*
          "coopMedOrgsAlreadyWord": {
            test: () => {
              if (this.formPayload.screen3.coopMedOrgsHelpToCoop) return true;
              if (this.formPayload.screen3.coopMedOrgsList.length && (
                this.formPayload.screen3.coopMedOrgsAlreadyWord || this.formPayload.screen3.coopMedOrgsWantToCoop
              )) return true;
              return false;
            }
          },
          "coopMedOrgsWantToCoop": {
            test: () => {
              if (this.formPayload.screen3.coopMedOrgsHelpToCoop) return true;
              if (this.formPayload.screen3.coopMedOrgsList.length && (
                this.formPayload.screen3.coopMedOrgsAlreadyWord || this.formPayload.screen3.coopMedOrgsWantToCoop
              )) return true;
              return false;
            }
          },
          "coopMedOrgsHelpToCoop": {
            test: (v) => {
              if (v) return true;
              if (this.formPayload.screen3.coopMedOrgsAlreadyWord || this.formPayload.screen3.coopMedOrgsWantToCoop) return true;
              return false;
            }
          },
          "coopMedOrgsList": {
            test: (v) => {
              if (!v.length && (
                this.formPayload.screen3.coopMedOrgsAlreadyWord || this.formPayload.screen3.coopMedOrgsWantToCoop
              )) return false;
              return true;

              /*              //if (this.formPayload.screen3.coopMedOrgsHelpToCoop) return true;
                            if (v.length > 0 && (
                                this.formPayload.screen3.coopMedOrgsAlreadyWord || this.formPayload.screen3.coopMedOrgsWantToCoop
                            )) return true;
                            return false;*/
            }
          },
          "coopMedOrgsWishList": {},
          "fio": { required },
          "job": { required },
          "phone": { required },
          "email": { required }
        },
        screen4: {
          "organizationalSupportMeasures": {
            test: (v) => {
              if (v.length || this.formPayload.screen4.financialSupportMeasures.length) return true;
              return false;
            }
          },
          "financialSupportMeasures": {
            test: (v) => {
              if (v.length || this.formPayload.screen4.organizationalSupportMeasures.length) return true;
              return false;
            }
          },
        },
        screen5: {
          "file_articlesOfAssociation": { required },
          "file_certificateOfState": { required },
          "file_certificateOfTax": { required },
          "file_decisionOfAppointment": { required },
          "file_proxyToSign": {},
          "file_researchPermission": { required },
          "file_researchDesign": { required },
          "file_agreementDraft": { required },
          "file_smeta": {
            test: (v) => {
              if (!this.formPayload.screen4.financialSupportMeasures.length) return true;
              if (typeof v === 'object') return true
              return false;
            }
          }, //Наличие группы сравнения (для плацебо и препарата сравнения)*
        },
      }
    }
  }
}
</script>

<style lang="scss">
.form__aside {
  position: relative;

  &-inner {
    position: sticky;
    top: 80px;
    z-index: 1000;
  }
}

.form__field--error.form__field--error-custom-txt::before {
  content: attr(data-text) !important;
}

.form__field--custom-error {
  color: #EB5757;
  font-size: 12px;
  line-height: 16px;
}

.form__caption--small {
  font-size: 12px;
  margin-top: 18px;
}

.form__switcher[disabled]+label::before,
.form__switcher[disabled]+label::after,
{
opacity: 0.4;
}

.cabinet__download {
  display: inline-flex !important;
  align-items: center;
}

.cabinet__delete--new {
  margin-left: 4px;
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;

  svg {
    width: 12px;
    height: 12px;
    stroke: #828282;
  }
}

.cabinet__error {
  color: red;
}


.cabinet__btns {
  .cabinet__send {
    padding-left: 8px;
    padding-right: 8px;
    width: auto;
  }

  .cabinet__save {
    width: auto !important;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.multiselect__tags {
  background: transparent !important;
}

.multiselect__tag {
  background: #094df1;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect--active .multiselect__tags {
  background: #fff !important;
}

.multiselect__input,
.multiselect__single {
  background: transparent;
}

.multiselect__option--highlight {
  background: #E0E0E0;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #4775e3;
}

.multiselect__option {
  white-space: normal !important;
}

.multiselect__option:hover::after,
.multiselect__option--highlight:hover::after {
  display: none !important;
}
</style>

<style scoped>
.form__link--smeta {
  color: #094df1;
  margin-top: 12px;
  display: block;
}
</style>