<template>
  <section class="cabinet cabinet--admin" id="tabs" @click="shownCtxMenu = false">
    <div class="wrapper">
      <ul class="cabinet__tabs">
        <li class="cabinet__name" :class="{ 'ui-tabs-active': !showArchive }">
          <a href="#tabs-1" @click.prevent="showArchive = false">Заявки <span>({{ applicationsCountActive }})</span></a>
        </li>
        <li v-if="!isAdmPage" class="cabinet__name" :class="{ 'ui-tabs-active': showArchive }">
          <a href="#tabs-2" @click.prevent="showArchive = true">Архив <span>({{ applicationsCountArchive }})</span></a>
        </li>
      </ul>
      <div class="cabinet__field">
        <div class="cabinet__top">
          <div class="search">
            <button type="submit" class="search__btn" title="Найти">
              <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M16.1457 15.8117C16.406 15.5513 16.406 15.1292 16.1457 14.8689L12.4499 11.1731C13.0074 10.3665 13.334 9.38806 13.334 8.33334C13.334 5.57192 11.0954 3.33334 8.33398 3.33334C5.57256 3.33334 3.33398 5.57192 3.33398 8.33334C3.33398 11.0948 5.57256 13.3333 8.33398 13.3333C9.55954 13.3333 10.6821 12.8924 11.5517 12.1605L15.2029 15.8117C15.4632 16.072 15.8853 16.072 16.1457 15.8117ZM12.0007 8.33334C12.0007 9.08926 11.7729 9.80761 11.3531 10.4149L11.0719 10.8216L10.6931 11.1404C10.0366 11.6929 9.21075 12 8.33398 12C6.30894 12 4.66732 10.3584 4.66732 8.33334C4.66732 6.3083 6.30894 4.66668 8.33398 4.66668C10.359 4.66668 12.0007 6.3083 12.0007 8.33334Z">
                </path>
              </svg>
            </button>
            <input type="text" class="search__input" v-model="filterName" placeholder="Поиск по названию или номеру" />
          </div>
          <div class="filter">
            <button type="button" class="filter__open">
              <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.5 4H4.8C5.35228 4 5.8 4.44772 5.8 5V6C5.8 6.55228 5.35228 7 4.8 7H3.5C2.94772 7 2.5 6.55228 2.5 6V5C2.5 4.44772 2.94772 4 3.5 4ZM3.5 5V6H4.8V5H3.5Z">
                </path>
                <path
                  d="M7.4 5C7.12386 5 6.9 5.22386 6.9 5.5C6.9 5.77614 7.12386 6 7.4 6H17.4C17.6761 6 17.9 5.77614 17.9 5.5C17.9 5.22386 17.6761 5 17.4 5H7.4Z">
                </path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M4.8 8.5H3.5C2.94772 8.5 2.5 8.94772 2.5 9.5V10.5C2.5 11.0523 2.94772 11.5 3.5 11.5H4.8C5.35228 11.5 5.8 11.0523 5.8 10.5V9.5C5.8 8.94772 5.35228 8.5 4.8 8.5ZM3.5 10.5V9.5H4.8V10.5H3.5Z">
                </path>
                <path
                  d="M7.4 9.5C7.12386 9.5 6.9 9.72386 6.9 10C6.9 10.2761 7.12386 10.5 7.4 10.5H17.4C17.6761 10.5 17.9 10.2761 17.9 10C17.9 9.72386 17.6761 9.5 17.4 9.5H7.4Z">
                </path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M4.8 13H3.5C2.94772 13 2.5 13.4477 2.5 14V15C2.5 15.5523 2.94772 16 3.5 16H4.8C5.35228 16 5.8 15.5523 5.8 15V14C5.8 13.4477 5.35228 13 4.8 13ZM3.5 15V14H4.8V15H3.5Z">
                </path>
                <path
                  d="M7.4 14C7.12386 14 6.9 14.2239 6.9 14.5C6.9 14.7761 7.12386 15 7.4 15H17.4C17.6761 15 17.9 14.7761 17.9 14.5C17.9 14.2239 17.6761 14 17.4 14H7.4Z">
                </path>
              </svg>
              <span>Фильтры ({{ filtersCount }})</span>
            </button>
            <div class="filter__dropdown">
              <ul class="filter__menu">
                <li class="filter__item">
                  <span>Статус заявки</span>
                  <div class="filter__options">
                    <!--
                    0 Черновик
                    1 Экспертиза заявки
                    2 Отправлена на доработку экспертом
                    3 Одобрена
                    4 Отклонена
                  -->

                    <ul class="filter__sublist">
                      <li class="filter__subitem">
                        <input type="checkbox" id="checkbox_0" class="form__checkbox" v-model="filterStatus[0]" />
                        <label for="checkbox_0" class="form__checkbox-label">Черновик</label>
                      </li>
                      <li class="filter__subitem">
                        <input type="checkbox" id="checkbox_1" class="form__checkbox" v-model="filterStatus[1]" />
                        <label for="checkbox_1" class="form__checkbox-label">Экспертиза заявки</label>
                      </li>
                      <li class="filter__subitem">
                        <input type="checkbox" id="checkbox_2" class="form__checkbox" v-model="filterStatus[2]" />
                        <label for="checkbox_2" class="form__checkbox-label">Отправлена на доработку</label>
                      </li>
                      <li class="filter__subitem">
                        <input type="checkbox" id="checkbox_3" class="form__checkbox" v-model="filterStatus[3]" />
                        <label for="checkbox_3" class="form__checkbox-label">Одобрено возмещение части затрат</label>
                      </li>
                      <li class="filter__subitem">
                        <input type="checkbox" id="checkbox_4" class="form__checkbox" v-model="filterStatus[4]" />
                        <label for="checkbox_4" class="form__checkbox-label">Одобрены организационные меры
                          поддержки</label>
                      </li>
                      <li class="filter__subitem">
                        <input type="checkbox" id="checkbox_5" class="form__checkbox" v-model="filterStatus[5]" />
                        <label for="checkbox_5" class="form__checkbox-label">Отклонено возмещение части затрат</label>
                      </li>
                      <li class="filter__subitem">
                        <input type="checkbox" id="checkbox_6" class="form__checkbox" v-model="filterStatus[6]" />
                        <label for="checkbox_6" class="form__checkbox-label">Отклонены организационные меры
                          поддержки</label>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="filter__item">
                  <span>Фаза</span>
                  <div class="filter__options">
                    <ul class="filter__sublist">
                      <li class="filter__subitem" v-for="item in phases" :key="item.id">
                        <input type="checkbox" :id="'checkbox'+item.id" :checked="item.active" class="form__checkbox" @change="item.active = !item.active"/>
                        <label :for="'checkbox'+item.id" class="form__checkbox-label">{{item.value}}</label>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="filter__item filter__item--has-list">
                  <span>Терапевтическая область</span>
                  <div class="filter__options filter__options--select">
                    <VueMultiselect v-model="filterArea" placeholder="Выбрать из справочника" :select-label="'Выбрать'"
                      :selected-label="'Выбрано'" :multiple="true" :deselect-label="``" :showLabels="false"
                      :options="dictionaries.therapeuticArea">
                    </VueMultiselect>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <a href="/uploads/instruction.pdf" class="cabinet__instruction" v-if="!isAdmPage">Инструкция по подаче
            заявки</a>
          <template v-if="isAdmPage">
            <button @click="getApplicationsCSV" class="btn cabinet__submit">
              Скачать CSV
            </button>
          </template>
          <router-link v-if="$route.name === 'applications'" to="/applications/create"
            class="btn cabinet__submit">Подать
            заявку</router-link>
          <div class="cabinet__filters">
            <a href="#" class="cabinet__filter" v-for="(area, idx) in filterArea" :key="idx"
              @click.prevent="filterArea = filterArea.filter((f) => f !== area)">{{ area }}
            </a>

            <a href="#" class="cabinet__filter" v-if="filterStatus[0]"
              @click.prevent="filterStatus[0] = false">Черновик</a>
            <a href="#" class="cabinet__filter" v-if="filterStatus[1]"
              @click.prevent="filterStatus[1] = false">Экспертиза
              заявки</a>
            <a href="#" class="cabinet__filter" v-if="filterStatus[2]"
              @click.prevent="filterStatus[2] = false">Отправлена на
              доработку</a>
            <a href="#" class="cabinet__filter" v-if="filterStatus[3]" @click.prevent="filterStatus[3] = false">Одобрено
              возмещение части затрат</a>
            <a href="#" class="cabinet__filter" v-if="filterStatus[4]" @click.prevent="filterStatus[4] = false">Одобрены
              организационные меры поддержки</a>
            <a href="#" class="cabinet__filter" v-if="filterStatus[5]"
              @click.prevent="filterStatus[5] = false">Отклонено
              возмещение части затрат</a>
            <a href="#" class="cabinet__filter" v-if="filterStatus[6]"
              @click.prevent="filterStatus[6] = false">Отклонены
              организационные меры поддержки</a>


            <a href="#" v-for="phase in phases.filter(el => el.active)" :key="phase.id" class="cabinet__filter"  @click.prevent="phase.active = false">Фаза {{ phase.value }}
            </a>

            <a href="#" v-if="filtersCount > 0" class="cabinet__clear" @click.prevent="dropFilter">Сбросить</a>
          </div>
        </div>
        <skeleton-preloader v-if="!applications" />
        <template v-else-if="applications.length">
          <table class="cabinet__table">
            <tr>
              <th><i>Номер заявки (id)</i><span>Заявка (id)</span></th>
              <th>Наименование ЛП</th>
              <th>Терапевтическая область</th>
              <th>Фаза</th>
              <th>
                Число пациентов
                <button type="button" @click="sortByPatients = !sortByPatients" class="cabinet__sort"
                  :class="{ active: sortByPatients }"></button>
              </th>
              <th>Статус</th>
              <th v-if="false">Действия</th>
            </tr>

            <tr v-for="application in paginatedApplicationsList" :key="application.id" @click="
              $router.push(
                `${isAdmPage ? '/admin' : ''}/applications/${application.id}`
              )
              ">
              <td>
                {{ application.number }}
              </td>
              <td>
                {{ application.name }}
              </td>
              <td>{{ application.therapeuticArea }}</td>
              <td>{{ application.researchPhase }}</td>
              <td>{{ application.numberOfPatients }}</td>
              <td>
                <template v-if="
                  application?.organizationalSupportMeasuresStatus === 1 ||
                  application?.financialSupportMeasuresStatus === 1
                ">
                  <p class="cabinet__status cabinet__status--pending">
                    Экспертиза заявки
                  </p>
                </template>
                <template v-else-if="
                  application?.organizationalSupportMeasuresStatus === 2 ||
                  application?.financialSupportMeasuresStatus === 2
                ">
                  <p class="cabinet__status cabinet__status--decline" data-v-27c23bf1="">
                    Отправлена на доработку экспертом
                  </p>
                </template>
                <template v-else-if="
                  application?.organizationalSupportMeasuresStatus ||
                  application?.financialSupportMeasuresStatus
                ">
                  <div class="cabinet__row-measures" v-if="application?.organizationalSupportMeasuresStatus">
                    <span>Организационные меры поддержки:</span>
                    <div v-html="getStatusHtmlElement(
                      application?.organizationalSupportMeasuresStatus
                    )
                      "></div>
                  </div>
                  <div class="cabinet__row-measures" v-if="application?.financialSupportMeasuresStatus">
                    <span>Возмещение части затрат:</span>
                    <div v-html="getStatusHtmlElement(
                      application?.financialSupportMeasuresStatus
                    )
                      "></div>
                  </div>
                </template>
                <template v-else>
                  <p v-if="application.status === 0" class="cabinet__status"
                    :class="getStatusClass(application.status)">
                    Черновик
                  </p>
                  <p v-if="application.status === 1" class="cabinet__status"
                    :class="getStatusClass(application.status)">
                    Экспертиза заявки
                  </p>
                  <p v-if="application.status === 2" class="cabinet__status"
                    :class="getStatusClass(application.status)">
                    Отправлена на доработку экспертом
                  </p>
                  <p v-if="application.status === 3" class="cabinet__status"
                    :class="getStatusClass(application.status)">
                    Отклонена экспертом
                  </p>
                  <p v-if="application.status === 4" class="cabinet__status"
                    :class="getStatusClass(application.status)">
                    Одобрена
                  </p>
                  <p v-if="application.status === 5" class="cabinet__status"
                    :class="getStatusClass(application.status)">
                    На рассмотрении клинического комитета
                  </p>
                  <p v-if="application.status === 6" class="cabinet__status"
                    :class="getStatusClass(application.status)">
                    Отправлена на доработку клиническим комитетом
                  </p>
                  <p v-if="application.status === 7" class="cabinet__status"
                    :class="getStatusClass(application.status)">
                    Отклонена клиническим комитетом
                  </p>
                  <p v-if="application.status === 8" class="cabinet__status"
                    :class="getStatusClass(application.status)">
                    Одобрена клиническим комитетом
                  </p>
                </template>
              </td>
              <td v-if="false" class="ctx-menu__cell">
                <a v-if="application.status === 0" href="#" @click.prevent.stop="
                  shownCtxMenu =
                  shownCtxMenu !== application.id ? application.id : false
                  " class="cabinet__dots"></a>
                <div class="ctx-menu__body" v-if="shownCtxMenu === application.id">
                  <button @click.prevent="
                    $store.dispatch('editApplication', {
                      id: application.id,
                      userId: $store.getters.getUserId,
                      draft: false,
                    });
                  shownCtxMenu = false;
                  " class="ctx_menu__btn">
                    Отправить на рассмотрение
                  </button>
                  <button @click.prevent.stop="
                    applicationSelected = application.id;
                  deleteConformationShown = true;
                  shownCtxMenu = false;
                  " class="ctx_menu__btn">
                    Удалить заявку
                  </button>
                </div>
              </td>
            </tr>
          </table>
          <nav class="pages" v-if="totalPages > 1">
            <a @click.prevent="CURRENT_PAGE > 1 ? CURRENT_PAGE-- : CURRENT_PAGE" href="#"
              class="pages__link pages__link--arrow" title="Назад">
              <svg width="6" height="7" viewBox="0 0 6 7" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.797118 2.65526L4.58963 0.15007C4.96065 -0.0950122 5.46193 0.00432693 5.70928 0.37195C5.7977 0.503364 5.84488 0.65777 5.84488 0.81571V5.82608C5.84488 6.26791 5.4834 6.62608 5.03749 6.62608C4.87809 6.62608 4.72226 6.57933 4.58963 6.49172L0.797118 3.98654C0.426098 3.74145 0.32584 3.24476 0.573187 2.87714C0.632317 2.78925 0.708424 2.71384 0.797118 2.65526Z">
                </path>
              </svg>
            </a>

            <a href="#" class="pages__link" v-for="(page, idx) in totalPages" :key="idx"
              :class="{ 'pages__link--active': CURRENT_PAGE === page }" @click.prevent="CURRENT_PAGE = page">
              {{ page }}
            </a>

            <a @click.prevent="
              CURRENT_PAGE < totalPages ? CURRENT_PAGE++ : CURRENT_PAGE
              " href="#" class="pages__link pages__link--arrow" title="Вперед">
              <svg width="6" height="7" viewBox="0 0 6 7" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.2255 2.65526C5.31419 2.71384 5.3903 2.78925 5.44943 2.87714C5.69678 3.24476 5.59652 3.74145 5.2255 3.98654L1.43299 6.49172C1.30036 6.57933 1.14453 6.62608 0.985128 6.62608C0.539217 6.62608 0.177734 6.26791 0.177734 5.82608V0.81571C0.177734 0.65777 0.224917 0.503364 0.313336 0.37195C0.560683 0.00432693 1.06197 -0.0950122 1.43299 0.15007L5.2255 2.65526Z">
                </path>
              </svg>
            </a>
            <a v-if="ITEMS_PER_PAGE !== 999999" href="#" class="pages__more" @click="
              CURRENT_PAGE = 1;
            ITEMS_PER_PAGE = 999999;
            ">Показать все</a>
            <a href="#" class="pages__mobile" @click="ITEMS_PER_PAGE += 10">Показать ещё +</a>
          </nav>
        </template>
        <template v-else> У вас нет заявок и/или черновиков </template>
      </div>
      <div v-show="deleteConformationShown">
        <div class="overlay"></div>
        <div class="message">
          <p class="message__title">Вы действительно хотите удалить заявку?</p>
          <div class="message__btns">
            <a href="#" @click.prevent="
              deleteConformationShown = false;
            applicationIdSelected = '';
            " class="message__link">Отменить</a>
            <button type="submit" @click.prevent="archive" class="message__btn message__btn--red">
              Удалить
            </button>
          </div>
          <button type="button" @click.prevent="
            deleteConformationShown = false;
          applicationIdSelected = '';
          " class="message__close"></button>
        </div>
      </div>
    </div>
  </section>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
.filter__options--select {
  .multiselect {
    font-size: 14px;
    line-height: 18px;

    &__tags {
      min-height: 30px;
    }

    &__select {
      //height: 30px;
    }

    &__input,
    &__single {
      font-size: 12px;
    }

    &__placeholder {}

    &__option {
      min-height: fit-content;
      padding: 8px;
    }

    &__tag {
      background-color: #094df1;
    }

    &__tag-icon:after {
      color: #fff;
    }

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
      scrollbar-width: auto;
      scrollbar-color: #b0b0b0 #ffffff;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      width: 16px;
    }

    *::-webkit-scrollbar-track {
      background: #ffffff;
    }

    *::-webkit-scrollbar-thumb {
      background-color: #b0b0b0;
      border-radius: 10px;
      border: 3px solid #ffffff;
    }
  }
}
</style>
<script>
import * as dictionaries from '@/misc/dictionaries.js';
import SkeletonPreloader from '@/components/ui/skeletonPreloader';
import VueMultiselect from 'vue-multiselect';

export default {
  components: { SkeletonPreloader, VueMultiselect },
  props: {
    applications: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    isAdmPage() {
      return !(this.$route.name === 'applications');
    },
    pageStartIndex() {
      const CURRENT_PAGE = this.CURRENT_PAGE;
      return (CURRENT_PAGE - 1) * this.ITEMS_PER_PAGE;
    },
    pageEndIndex() {
      const CURRENT_PAGE = this.CURRENT_PAGE;
      return CURRENT_PAGE * this.ITEMS_PER_PAGE;
    },
    paginatedApplicationsList() {
      return this.applicationsList.slice(
        this.pageStartIndex,
        this.pageEndIndex
      );
    },
    totalPages() {
      if (!this.applicationsList.length) return 0;
      return Math.ceil(this.applicationsList.length / this.ITEMS_PER_PAGE);
    },
    dictionaries() {
      return dictionaries;
    },
    applicationsCountActive() {
      return this.applications.filter((a) => !a.archive).length;
    },
    applicationsCountArchive() {
      return this.applications.filter((a) => !!a.archive).length;
    },
    applicationsList() {
      let result = this.applications;
      const filterName = this.filterName.toLowerCase();

      if (this.filterName.length) {
        result = result.filter((e) => {
          if (e.name && e.name.toLowerCase().includes(filterName)) return true;
          if (
            e.numberOfPatients &&
            e.numberOfPatients.toLowerCase().includes(filterName)
          )
            return true;
          if (
            e.researchPhase &&
            e.researchPhase.toLowerCase().includes(filterName)
          )
            return true;
          if (
            e.therapeuticArea &&
            e.therapeuticArea.toLowerCase().includes(filterName)
          )
            return true;
          if (e.number && e.number.toString().includes(filterName)) return true;
        });
      }

      /* DEFAULT SORT */
      result = result.sort((a, b) => {
        let n1 = +a?.number || 0;
        let n2 = +b?.number || 0;
        if (n1 < n2) return 1;
        if (n1 > n2) return -1;
        return 0;
      });

      /* ADMIN SORT */
      if (this.isAdmPage) {
        result = result.sort((a, b) => {
          if (a.status === 2 || a.status === 6) return -1;
          b;
          return 1;
        });
      }

      if (this.sortByPatients) {
        result = result.sort((a, b) => {
          let n1 = +a?.numberOfPatients || 0;
          let n2 = +b?.numberOfPatients || 0;
          if (n1 < n2) return 1;
          if (n1 > n2) return -1;
          return 0;
        });
      }

      /* ARCHIVE FILTER */
      result = result.filter((a) => {
        return this.showArchive ? !!a.archive : !a.archive;
      });

      /* STATUS FILTER */
      const filterStatuses = [];
      this.filterStatus.forEach((e, statusId) => {
        if (e) filterStatuses.push(statusId);
      });
      if (filterStatuses.length) {
        result = result.filter((e) => {
          //0 Черновик
          if (filterStatuses.length === 1 && filterStatuses.includes(0)) {
            if (
              e.financialSupportMeasuresStatus === 0 &&
              e.organizationalSupportMeasuresStatus === 0
            )
              return true;
            return false;
          }
          //1 Экспертиза заявки
          if (filterStatuses.includes(1)) {
            if (
              e.financialSupportMeasuresStatus === 1 ||
              e.organizationalSupportMeasuresStatus === 1
            )
              return true;
          }
          //2 Отправлена на доработку
          if (filterStatuses.includes(2)) {
            if (
              e.financialSupportMeasuresStatus === 2 ||
              e.organizationalSupportMeasuresStatus === 2
            )
              return true;
          }
          //3 Одобрено возмещение части затрат
          if (filterStatuses.includes(3)) {
            if (e.financialSupportMeasuresStatus === 3) return true;
          }
          //4 Одобрены организационные меры поддержки
          if (filterStatuses.includes(4)) {
            if (e.organizationalSupportMeasuresStatus === 3) return true;
          }
          //5 Отклонено возмещение части затрат
          if (filterStatuses.includes(5)) {
            if (e.financialSupportMeasuresStatus === 4) return true;
          }
          //6 Отклонены организационные меры поддержки
          if (filterStatuses.includes(6)) {
            if (e.organizationalSupportMeasuresStatus === 4) return true;
          }
          return false;
        });
      }

      /* PHASE FILTER */
      const filterPhases = [];
      this.phases.forEach(phase => {
        if (phase.active) filterPhases.push(phase.value);
      });
      if (filterPhases.length) {
        result = result.filter((e) => filterPhases.includes(e.researchPhase));
      }

      /* Area filter */
      if (this.filterArea.length) {
        result = result.filter((e) =>
          this.filterArea.includes(e.therapeuticArea)
        );
      }

      return result;
    },
    filtersCount() {
      let count = 0;
      this.filterStatus.forEach((e) => {
        if (e) count++;
      });
      Object.values(this.phases).forEach((e) => {
        if (e.active) count++;
      });
      count += this.filterArea.length;
      return count;
    },
  },
  created() {
    const { filterName, filterStatus, filterPhase, filterArea } =
      this.$store.state.filters;

    if (filterName) this.filterName = filterName;
    if (filterStatus) this.filterStatus = filterStatus;
    if (filterPhase) this.filterPhases = filterPhase;
    if (filterArea) this.filterArea = filterArea;
  },
  data() {
    return {
      applicationSelected: '',
      deleteConformationShown: false,
      showPreloader: true,
      ITEMS_PER_PAGE: 10,
      CURRENT_PAGE: 1,
      showArchive: false,
      filterName: '',
      sortByPatients: false,
      filterStatus: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      phases: [
        { value: 'I', id: '4', active: false },
        { value: 'I-II', id: '5', active: false },
        { value: 'I-II, III', id: '13', active: false },
        { value: 'Ia', id: '15', active: false },
        { value: 'Ib', id: '9', active: false },
        { value: 'II', id: '7', active: false },
        { value: 'II-III', id: '12', active: false },
        { value: 'IIa', id: '6', active: false },
        { value: 'IIb', id: '10', active: false },
        { value: 'III', id: '2', active: false },
        { value: 'III-IV', id: '14', active: false },
        { value: 'IIIa', id: '11', active: false },
        { value: 'IIIb', id: '8', active: false },
        { value: 'IV', id: '3', active: false },
        { value: 'Биоэквивалентность', id: '1', active: false },
      ],
      filterArea: [],
      shownCtxMenu: false,
    };
  },
  methods: {
    getStatusHtmlElement(statusCode) {
      if (typeof statusCode !== 'number') return '';
      let txt = '';
      let className = '';
      switch (statusCode) {
        case 0:
          txt = 'Черновик';
          className = 'cabinet__status--draft';
          break;
        case 1:
          txt = 'Экспертиза заявки';
          className = 'cabinet__status--pending';
          break;
        case 2:
          txt = 'Отправлена на доработку';
          break;
        case 3:
          txt = 'Одобрена';
          className = 'cabinet__status--ok';
          break;
        case 4:
          txt = 'Отклонена';
          className = 'cabinet__status--cancel';
          break;
      }

      return `<p class="cabinet__status ${className}">${txt}</p>`;
    },
    async getApplicationsCSV() {
      const resultUrl = await this.$store.dispatch('getApplicationsCSV');
      if (resultUrl) {
        window.open(resultUrl, '_blank').focus();
      }
    },
    getStatusClass(status) {
      const classes = {
        0: 'cabinet__status--draft',
        1: 'cabinet__status--pending',
        2: 'cabinet__status--decline',
        3: 'cabinet__status--cancel',
        4: 'cabinet__status--done',
        5: 'cabinet__status--pending',
        6: 'cabinet__status--decline',
        7: 'cabinet__status--cancel',
        8: 'cabinet__status--done',
      };
      return classes[status];
    },
    async archive() {
      const application = this.$store.getters.getApplications.find(
        (e) => e.id === this.applicationSelected
      );
      const result = await this.$store.dispatch(
        'deleteApplication',
        application.id
      );
      if (result) {
        this.deleteConformationShown = false;
        this.applicationSelected = false;
        application.archive = true;
      }
    },
    dropFilter() {
      this.filterName = '';
      this.filterStatus = [false, false, false, false, false];
      this.phases.forEach(el => el.active = false);
      this.filterArea = [];
    },
  },
  watch: {
    filterName: {
      handler(value) {
        this.CURRENT_PAGE = 1;
        this.$store.commit('setFilterName', value);
      },
      deep: true,
    },
    filterStatus: {
      handler(value) {
        this.CURRENT_PAGE = 1;
        this.$store.commit('setFilterStatus', value);
      },
      deep: true,
    },
    phases: {
      handler(value) {
        this.CURRENT_PAGE = 1;
        this.$store.commit('setFilterPhase', value);
      },
      deep: true,
    },
    filterArea: {
      handler(value) {
        this.CURRENT_PAGE = 1;
        this.$store.commit('setFilterArea', value);
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect__tags {
  min-height: 28px;
}

@media screen and (max-width: 1920px) {
  .filter__item {
    white-space: nowrap;
  }

  .filter__dropdown {
    left: auto;
    right: 0;
  }

  .filter__sublist {
    white-space: normal;
  }

  .filter__options {
    left: auto;
    right: 100%;
  }
}

@media screen and (max-width: 480px) {
  .filter__menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  .filter__item {
    background: #fff !important;
  }

  .filter__item:hover {
    &::after {
      transform: rotate(90deg);
    }
  }

  .filter__item:hover .filter__options {
    display: block;
    position: static;
    padding: 0;
  }

  .filter__sublist {
    margin: 0;
    padding: 0;
    width: 100%;
    background: transparent;
    box-shadow: none;
  }
}

.ctx-menu__cell {
  position: relative;
}

.ctx-menu__body {
  position: absolute;
  right: 0;
  top: 90%;
  background: #ffffff;
  border-radius: 4px;
  padding: 5px;
  z-index: 10;
  box-shadow: 2px 2px 4px -3px #000;
}

.ctx_menu__btn {
  white-space: nowrap;
  background: #fff;
  border-radius: 4px;
  padding: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 8px;
  width: 100%;
  text-align: left;

  &:hover {
    background: rgba(242, 242, 242, 0.55);
  }
}
</style>
