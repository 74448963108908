<template>
  <section class="cabinet">
    <div class="wrapper">
      <h1>Профиль пользователя</h1>
      <div class="cabinet__field">
        <div class="cabinet__title">
          <span>{{$store.getters.getName}}</span>
        </div>
        <div v-if="$store.getters.getUserOrganisations.length" class="form form--cabinet">
          <div class="form__columns">
            <div class="form__column">
              <div class="form__fields">
                <p class="form__name">Cписок организаций, в которых вы выступаете в качестве доверенного лица</p>
                <ul class="form__list">
                  <li
                    v-for="org in $store.getters.getUserOrganisations"
                    :key="org.id"
                    class="form__item"
                  >
                    <div class="form__field">
                      <label for="company" class="form__label">Наименование юридического лица</label>
                      <input type="text" id="company" class="form__input" :value="org.name" disabled>
                    </div>
                    <div class="form__line form__line--twins">
                      <div class="form__field">
                        <label for="name" class="form__label">ОГРН</label>
                        <input type="text" id="name" class="form__input" :value="org.inn" disabled>
                      </div>
                      <div class="form__field">
                        <label for="surname" class="form__label">ИНН</label>
                        <input type="text" id="surname" class="form__input" :value="org.ogrn" disabled>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="cabinet__gosuslugi">
          <p>Для использования платформы вам необходимо стать доверенным лицом компании на mos.ru</p>
          <p>
            <a href="https://www.mos.ru/otvet-tehnologii/kak-voyti-v-kabinet-organizacii-na-mos-ru-doverennym-licam/" target="_blank" class="cabinet__mosru-link">Как войти в кабинет организации на mos.ru доверенным лицам</a>
          </p>
          <p>
            <a href="https://www.mos.ru/pgu/common/legal_short.pdf" target="_blank" class="cabinet__mosru-link">Инструкция по авторизации в кабинете юридического лица</a>
          </p>

<!--          <a href="https://www.mos.ru/otvet-tehnologii/kak-voyti-v-kabinet-organizacii-na-mos-ru-doverennym-licam/" class="btn cabinet__gosuslugi-link" target="_blank">Читать</a>-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
}
</script>

<style scoped>
  .cabinet__mosru-link {
    color: #16171E;
    text-decoration: none;
    border-bottom: 1px solid;
    font-weight: normal;

  }
  .cabinet__mosru-link:hover {
    border-color: transparent;
  }
</style>