<template>
  <application-edit/>
</template>
<script>
import ApplicationEdit from "@/components/applicationEdit";
export default {
  components: {ApplicationEdit},
  created() {
    if (!this.$store.getters.isAdmin && !this.$store.getters.isViewAdmin) {
      this.$router.push('/applications');
      return;
    }
  },
}
</script>