import axios from "axios";

const APP_ID      = '912e2e3a-e4e3-45a6-a23f-12a2f63a2989';
// eslint-disable-next-line
const URL_PARAMS  = new URLSearchParams({
  appID: APP_ID,
});

export default {
  state: {
    authorized: false,
    userId: false,
    username: false,
    token: false,
    trusted: false,
    isAdmin: false,
    isViewAdmin: false,
    userOrganisations: [],
    user: {},
  },
  getters: {
    getSessionId(state){
      return state.token
    },
    isAuthorized(state){
      return state.authorized;
    },
    isAdmin(state){
      return state.isAdmin;
    },
    isViewAdmin(state){
      return state.isViewAdmin;
    },
    isTrusted(state){
      return state.isAdmin;
    },
    getName(state){
      if (state.isAdmin) return 'Админ';
      if (state.isViewAdmin) return state.user.userId;
      if (state.user?.firstName && state.user?.lastName) {
        return `${state.user?.firstName} ${state.user?.lastName}`;
      }
      return '';
    },
    getUserOrganisations(state){
      return state.userOrganisations
    },
    getUserId(state){
      return state.userId;
    },
    getAuthData(state){
      return {
        userId: state.userId,
        username: state.username,
        token: state.token,
        isAdmin: state.isAdmin,
        isViewAdmin: state.isViewAdmin
      }
    }
  },
  mutations: {
    setToken(state, token){
      state.token = token;
    },
    setUserId(state, userId){
      state.userId = userId;
    },
    setUserOrganisations(state, data){
      state.userOrganisations = data;
    },
    setUser(state, data){
      state.trusted   = (data?.trusted === 'true' || data?.trusted === true) ? true: false;
      state.user      = data;
    },
    logout(state){
      window.localStorage.setItem('trialsAuthData', 'false');
        state.authorized          = false;
        state.userId              = false;
        state.username            = false;
        state.token               = false;
        state.trusted             = false;
        state.isAdmin             = false;
        state.isViewAdmin         = false;
        state.userOrganisations   = [];
        state.user            = {};
    },
    async authorize(state, data){
      let {username, token, userId, role = false, user} = data;
      if (role === 'trials-admin') {
        userId = 'trials-admin';
      }
      if (!userId || !token) throw new Error('Cant set state manager fields username or token');
      state.authorized = true;
      state.userId    = userId;
      state.username  = username;
      state.token     = token;
      state.user      = user || {};
      state.isAdmin   = (role && role === 'trials-admin');
      state.isViewAdmin   = (role && role === 'trials-view');
      const localStorage = window.localStorage;
      localStorage.setItem('trialsAuthData', JSON.stringify({userId, username, token, isAdmin: state.isAdmin, isViewAdmin: state.isViewAdmin }));
      return true;
    },
    async initAuthCache(state) {
      const localStorage  = window.localStorage;
      const jsonData      = localStorage.getItem('trialsAuthData') || 'false';
      const authData      = JSON.parse(jsonData);
      if (!authData) return;
      const {userId, username, token, isAdmin, isViewAdmin = false} = authData;
      if (!userId || !token ) return;
      state.authorized = true;
      state.userId      = userId;
      state.username    = username;
      state.token       = token;
      state.isAdmin     = isAdmin;
      state.isViewAdmin     = isViewAdmin;
      return true;
    },
  },
  actions: {
    async subscribe({state}, email) {
      state;
      const formData = new FormData();
      formData.append('email', email);
      try {
        const URL_PARAMS  = new URLSearchParams({appID: APP_ID});
        const searchParams = URL_PARAMS.toString();
        const options = {headers: {'Content-Type': 'multipart/form-data'},};
        const response = await axios.post(`/trialsMail/trialsMailCreate?${searchParams}`, formData, options);
        if (response && response.data) {
          if (response.data.status === "OK") return true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async login({commit}, data) {
      const {username, password} = data
      if (!username || !password) return false;
      const payload = {
        "provider": "rest",
        username,
        password,
      }
      try {
        const response = await axios.post(`https://api.directual.com/good/api/v5/auth?appID=14411970-78ad-47b2-a55d-af4851f9ada2`, payload);
        if (response && response.data) {
          if (response.data.result) {
            const {nid :userId, username, token, role} = response.data.result
            commit('authorize', {userId, username, token, role});
            return true;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async authenticate({commit}, code) {
      try {
        const searchParams = URL_PARAMS.toString();
        const payload = {code};
        const response = await axios.post(`/trialGosuslugiRegistartion/trailsGosuslugi?${searchParams}`, payload);
        if (response?.data?.result) {
          const {responseCode, token, user, trialUserId: userId} = response?.data?.result[0];
          //const {responseCode, token, user, id: userId} = response?.data?.result[0];
          if (!responseCode || !token || !userId) return false;
          await commit('authorize',  {token, userId, user});
          return true;
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },
  },
}

