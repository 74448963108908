<template>
  <applications-list :applications="$store.getters.getApplications" />
</template>
<script>
import ApplicationsList from "@/components/applicationsList";
export default {
  components: {ApplicationsList},
  created() {
    this.$store.dispatch('fetchAllApplicationsForUser');
  },
  unmounted() {
    this.$store.commit('clearApplications');
  },
}
</script>